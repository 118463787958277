import React, { useEffect, useRef, useState } from "react";
import Autocomplete from 'react-autocomplete';
import { connect } from "react-redux";
import Select from 'react-select';
import { addSelectedContact, updateInboxUnreadContactInfo, updateMultipleData, updateSelectedTabLeftSide } from "../../actions/inboxAction";
import { confirmAlert } from 'react-confirm-alert';
import { sendRequest } from "../../api/rootApi";
import Api from "../../constants/Api";
import CoreConstants from "../../constants/CoreConstants.js";
import Utils from "../../helpers/Utils";
import { useIsMounted } from "../../hooks/IsMounted";
import Empty from "../common/Empty";
import Loading from "../common/Loading";
import InboxThreadItem from './InboxThreadItem';
import GlobalSearchBox from '../globals/GlobalSearchBox';
import Skeleton from '@material-ui/lab/Skeleton';
import GlobalUserListDropdown from "../UserListDropdown/Index";
import { markAsUnread, searchElasticInboxThread } from "../../api/inboxApi";
export const TAB_ALL = 'all';
export const TAB_UNREAD = 'unread';
export const TAB_RECENT = 'recent';
export const TAB_IMPORTANT = 'favourite';
export const TAB_ARCHIVE = 'archived';


const paginate = 200;

let page = 1; //stateless page count

const inboxThreadColumns = [
    'id',
    'contact_id',
    'content_type',
    'message',
    'message_subject',
    'is_read',
    'created_at',
    'updated_at',
    'last_communication',
    'in_out'
]

const contactColumns = [
    'id',
    'first_name',
    'last_name',
    'number',
    'email',
    'is_archived',
    'is_favourite',
    'address'
]

const getContentTypeIcon = (contentType, message = "") => {
    if (contentType === CoreConstants.inboxThread.CONTENT_TYPE_SMS) {
        return 'sms';
    } else if (contentType === CoreConstants.inboxThread.CONTENT_TYPE_EMAIL) {
        return 'mail';
    } else if (contentType === CoreConstants.inboxThread.CONTENT_TYPE_MMS) {
        return 'sms';
    } else if (contentType === CoreConstants.inboxThread.CONTENT_TYPE_CALL) {
        if(message === "Missed Call"){
            return 'phone_missed';
        }
        return 'call';
    } else if (contentType === CoreConstants.inboxThread.CONTENT_TYPE_VOICE_MAIL) {
        return 'voicemail';
    } else if (contentType === CoreConstants.inboxThread.CONTENT_TYPE_FORM_RESPONSE) {
        return 'format_list_bulleted';
    }
    else if (contentType === CoreConstants.inboxThread.CONTENT_TYPE_SMART_FORM_RESPONSE) {
        return 'format_list_bulleted';
    }else if(contentType === CoreConstants.inboxThread.CONTENT_TYPE_APPOINTMENT){
        return 12;
    }else if(contentType === CoreConstants.inboxThread.CONTENT_TYPE_VIDEO_MAIL){
        return 'videocam';
    }
    else if(contentType === CoreConstants.inboxThread.CONTENT_TYPE_INCOMING_VOICE_MAIL){
        // return 'record_voice_over';
        return 'voicemail';
    }
}
const LeftSide = (props) => {
    const isMounted = useIsMounted();

    const [loading, setLoading] = useState(true);
    const [componentLoading, setComponentLoading] = useState(false);
    const [threads, setThreads] = useState([]);
    const [activeTab, setActiveTab] = useState(TAB_ALL);
    const [hasMore, setHasMore] = useState(true);
    const [scrolling, setScrolling] = useState(false);
    const scrollingDiv = useRef();
    const [threadQuery, setThreadQuery] = useState('');
    const [debouncedThreadQuery, setDebouncedThreadQuery] = useState('');
    const [contactQuery, setContactQuery] = useState('');
    const [debouncedContactQuery, setDebouncedContactQuery] = useState('');
    const [displayFilter, setDisplayFilter] = useState(true);
    const [displayCreateNew, setDisplayCreateNew] = useState(false);
    const [subUsers, setSubUsers] = useState([]);
    const [selectedSubUser, setSelectedSubUser] = useState(null);
    const [autoCompleteContacts, setAutoCompleteContacts] = useState([]);
    const [isReadAll, setIsReadAll] = useState(false)
    const [selectedUsers, setSelectedUsers] = useState([])
    const [selectedIds, setSelectedIds] = useState([])
    const [updateThread, setUpdateThread] = useState(false)

    useEffect(() => {
        loadSubUser();
        loadThreads(page);
        if(Utils.getAccountData('userIsAgent')){
            setDisplayFilter(false);
            setDisplayCreateNew(true);
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        if (isMounted) {
            loadThreads(page, true);
        }
    }, [debouncedThreadQuery]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isMounted) {
            searchContacts();
        }
    }, [debouncedContactQuery]) // eslint-disable-line react-hooks/exhaustive-deps

    // debouncing
    useEffect(() => {
        if (isMounted) {
            const timer = setTimeout(() => {
                refreshThreadsDependency();
                setDebouncedThreadQuery(threadQuery);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [threadQuery]); // eslint-disable-line react-hooks/exhaustive-deps

    // debouncing
    useEffect(() => {
        if (isMounted) {
            const timer = setTimeout(() => {
                setDebouncedContactQuery(contactQuery);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [contactQuery]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isMounted) {
            refreshThreadsDependency();
            loadThreads(page, true);
        }
    }, [activeTab]) // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     if (isMounted) {
    //         refreshThreadsDependency();
    //         loadThreads(page, true);
    //     }
    // }, [selectedSubUser])
    
    useEffect(() => {
        if (isMounted) {
            refreshThreadsDependency();
            loadThreads(page, true);
        }
    }, [selectedIds]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(props.removeableContactIndex !== ''){
            let previousData = [...threads];
            previousData.splice(props.removeableContactIndex, 1)
            setThreads(previousData);
            props.handleRemoveableContact('');
        }
    }, [props.removeableContactIndex]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        if(isReadAll){
            let threadItemOld = [...threads]
            threadItemOld.forEach((threadItem,index)=>{
               
                if(threadItem.is_read === 0){
                    threadItemOld[index]['is_read'] = 1;
                }
            })
            setThreads(threadItemOld)
        }
    },[isReadAll]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        if(props.updateUnreadContact){

            props.setUpdateUnreadContact(false)

            updateInboxThreadForUnread()
        }
    },[props.updateUnreadContact])

    const updateInboxThreadForUnread=()=>{

        let tmpThreads = [...threads]

            if(tmpThreads[props.selectedContactIndex].is_read == CoreConstants.inboxThread.READ){
                tmpThreads[props.selectedContactIndex].is_read = CoreConstants.inboxThread.UNREAD;

                setThreads(tmpThreads)
                setUpdateThread((prev)=>{return !prev})
                window.showNotification('SUCCESS','message marked as unread')
                // props.updateInboxUnreadContact(0)
                let formData = new FormData();
                formData.append('contact_id', tmpThreads[props.selectedContactIndex].contact.id);
                markAsUnread(formData)
                .then(response => {
                    if(window.updateNotificationCount){
                        window.updateNotificationCount(false, true)
                    }
                });
            } 
    }

    const searchContacts = () => {
        // make request
        sendRequest('post', Api.contactsSearch, {
            'search_key': debouncedContactQuery
        })
        .then(response => {
            let result = response?.data?.data?.data;

            setAutoCompleteContacts(result);
        }).catch(error => {
            Utils.handleException(error);
        });
    }

    const refreshThreadsDependency = () => {
        if (scrollingDiv.current) {
            scrollingDiv.current.scrollTo(0, 0);
        }

        setLoading(true);
        page = 1;
        setHasMore(true);
    }

    const subUserHandleChange = (_selectedOption) => {
        setSelectedSubUser(_selectedOption);
    }

    const loadSubUser = () => {
        // make request
        sendRequest('GET', Api.subUsers, {
        })
        .then(response => {
            let result = response.data.data

            if (result.length) {
                let subUsersArray = [];
                result.forEach(subUser => {
                    subUsersArray.push({
                        value: subUser.id,
                        label: subUser.full_name,
                    });
                });
                setSubUsers(subUsersArray);
            }
        }).catch(error => {
            Utils.handleException(error);
        });
    }

    window.loadInboxThreads = () => {
        loadThreads(page = 1, true);
    }

    // window.updateSelectedContactData = (isRead = 0) => {
    //     let list = [...threads]
    //     list[props.selectedContactIndex].is_read = isRead
    //     setThreads(list)
    // }

    const loadThreads = (page, _clearPrev = false) => {
        //make request
        let sub_user_ids = null;
        if(process.env.REACT_APP_INBOX_THREAD_API_SOURCE === 'elastic'){
            if(selectedIds.length > 0 && selectedIds[0] !== '__all__'){
                sub_user_ids = selectedIds;
            }
            else if(selectedIds[0] === '__all__'){ 
                sub_user_ids = []
            }else{
                sub_user_ids=[Utils.getAccountData('userId')]
            }
            let params = {
                "page":page,
                "size":100,
                "search":debouncedThreadQuery,
                "tab": activeTab,
                "sub-user-id":sub_user_ids
            }

            searchElasticInboxThread(params).then(response =>{
                let tempThreads = _clearPrev ? [] : [...threads];
    
                response.data.inboxInfos.forEach(element => {
                    tempThreads.push(element);
                });
    
                setThreads(tempThreads);
                props.setLoadThreads(true)
                if (response.data.totalPages > response.data.currentPage) {
                    setHasMore(true);
                } else {
                    setHasMore(false);
                }
            }).catch(error => {
                Utils.handleException(error);
            }).finally(() => {
                setLoading(false);
                setScrolling(false);
            })
            
        }else{
            if(selectedIds.length > 0 && selectedIds[0] !== '__all__'){
                sub_user_ids = selectedIds;
            }
            else if(selectedIds[0] === '__all__'){
                sub_user_ids = '_all_'
            }
            sendRequest('POST', Api.contacts, {
                inboxThreadColumns,
                contactColumns,
                page,
                paginate,
                tab: activeTab,
                searchKey: debouncedThreadQuery,
                // subUserId: (selectedSubUser ? selectedSubUser.value : null)
                subUserId: sub_user_ids
            })
            .then(response => {
                let tempThreads = _clearPrev ? [] : [...threads];
    
                response.data.data.data.forEach(element => {
                    tempThreads.push(element);
                });
    
                setThreads(tempThreads);
                props.setLoadThreads(true)
                if (response.data.data.next_page_url) {
                    setHasMore(true);
                } else {
                    setHasMore(false);
                }
            }).catch(error => {
                Utils.handleException(error);
            }).finally(() => {
                setLoading(false);
                setScrolling(false);
            })
        }
        
    }

    const changeContact = (contact, index) => {
        // await props.setChangeContact(true, contact.id)
        // props.addSelectedContact(contact);

        // let tmpThreads = [...threads];
        // tmpThreads[index].is_read = 1;
        // console.log(tmpThreads)
        // setThreads(tmpThreads)

        props.addSelectedContact({contact: contact, index: index});
        props.setChangeContact(true, contact.id,false)
    }

    const handleItemSelect = (contact, index) => {

        props.addSelectedContact({contact: { ...contact, lnd: true }, index: index});
        props.setChangeContact(true, contact.id,false)
        // props.updateThreadList(props.index, 1)
        let tmpThreads = [...threads];
        tmpThreads[index].is_read =  CoreConstants.inboxThread.READ
        setThreads(threads)
        setUpdateThread((prev)=>{ return !prev})
        // if(threads[index].is_read == CoreConstants.inboxThread.UNREAD){
            let formData = new FormData();
            formData.append('contact_id', contact.id);
            formData.append('read_status', 1);
            
            markAsUnread(formData).then(response => {

                if(window.updateNotificationCount !== undefined){
                    window.updateNotificationCount(false, true)
                }
            })
            .finally(()=>{
                setUpdateThread(!updateThread)

            })

    }

    window.updateThreadItemImportantStatus = (index, status) => {
        let threadData = [...threads];

        if(activeTab === TAB_IMPORTANT){
            threadData.splice(props.selectedContactIndex,1)
        }else{
            threadData[props.selectedContactIndex].contact['is_favourite'] = status;
        }
        setThreads(threadData)
    }

    window.updateThreadItemArchiveStatus = (index, status) => {
        let threadData = [...threads];
        threadData[index].contact['is_archived'] = status;
        setThreads(threadData);
    }

    // const updateThreadList = (flag, index) => {
    //     threads[index].first_click = true
    // }

    const readAll = () => {
        let formData = new FormData();
        formData.append('read_type', 'read_all');

        confirmAlert({
            title: 'Are You Sure?',
            message: 'Do you want to mark all as read?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        markAsUnread(formData).then(response => {
                            setIsReadAll(true)
                            if(window.updateNotificationCount !== undefined){
                                window.updateNotificationCount(false, true)
                            }
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        })
       
    }

    // const updateThreadList=(index, isRead = 1)=>{

    //     let tmpThreads = [...threads];
    //     tmpThreads[index].is_read = isRead;
    //     console.log(tmpThreads)
    //     setThreads(tmpThreads)
    // }

    const populateThreads = () => {
        let inboxThreads = [];
        inboxThreads = threads.map((thread, index) => {
                if (thread.contact && thread.contact.id) {
                    if (activeTab !== TAB_ARCHIVE) {
                        if (thread.contact.is_archived !== 1) {
                            // inboxThreads.push(
                            return    <InboxThreadItem
                                    contentType = {getContentTypeIcon(thread.content_type, thread.message)}
                                    thread={thread}
                                    selectedContact={props.selectedContact}
                                    changeContact={() => changeContact(thread.contact, index)}
                                    index={index}
                                    threadList={threads}
                                    handleItemSelect={handleItemSelect}
                                    // updateThreadList={updateThreadList}
                                    key={`${index}-${thread.is_read}`}
                                />
                            // )
                        }
                    } else {
                        if (thread.contact.is_archived === 1) {
                            // inboxThreads.push(
                            return    <InboxThreadItem
                                    contentType = {getContentTypeIcon(thread.content_type)}
                                    thread={thread}
                                    selectedContact={props.selectedContact}
                                    changeContact={() => changeContact(thread.contact, index)}
                                    index={index}
                                    threadList={threads}
                                    handleItemSelect={handleItemSelect}

                                    // updateThreadList={updateThreadList}
                                    key={`${thread.id}-${thread.is_read}`}
                                />
                            // )
                        }
                    }
                }
                
            })

        if (inboxThreads.length) {
            return inboxThreads
        } else {
            return (
                <div>
                    <Empty/>
                </div>
            )
        }
    };

    const loadMoreThreads = (e) => {
        if(hasMore && !scrolling && Math.round(e.target.scrollTop + e.target.clientHeight, 10) >= Math.round(e.target.scrollHeight, 10)) {
            setScrolling(true);

            setTimeout(() => {
                page = page + 1;
                loadThreads(page);
            }, 1000);
        }
    }

    const handleTabChange = (e, _tab) => {
        e.preventDefault();
        if(_tab !== activeTab){
            setLoading(true);
            props.updateMultipleData({selectedContact: null, selectedContactIndex: null})

            setActiveTab(_tab);
            props.updateSelectedTabLeftSide(_tab)
        }

    }

    const handleSearchThreads = (e) => {
        setThreadQuery(e.target.value);
    }

    const loadingSkeleton = () => {
        let skeletons = [];
        for(let i=0; i<5; i++ ){
            skeletons.push(
                <div className={`card-panel message-discussion box-shadow-none`} key={i} >
                    <div className="mb-0 valign-wrapper">
                        <div className="col s2 m2 l1 xl2 user-short-name">
                            <Skeleton className="user-area" variant="circle" width={50} height={50} />
                        </div>
                        <div className="col s10 m10 l11 xl10 user-message pt-3">
                            <div className=" d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center fullNameWrapper">
                                    <Skeleton variant="text" className="full-name truncate" height={30} width={120} />
                                    <Skeleton variant="text" className="new" width={40} height={20} />
                                </div> 
                                <div className="hours-wrapper" style={{top: -5}}>
                                    <Skeleton variant="text" className="hours" width={180} />
                                </div>
                            </div>
                            <div className="d-flex">
                                <Skeleton variant="text" className="thread-message-time" width={50} />
                                <span className="inbox-vertical-separator">|</span>
                                <Skeleton variant="text" className="thread-message-time" width={100} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return skeletons;
    }

    // return(
    //     <div className="col s12 m12 l12 xl3 pr-xxl-0 inbox__left_side">
    //         <div className="mt-0 card message-info b-radius-10 box-shadow-none">
    //             <div>
    //                 {loadingSkeleton()}
    //             </div>
    //         </div>
    //     </div>
    // )

    const handleSelectUser = (item, remove) => {
        if(item.value === '__clear_all__'){
            setSelectedUsers([])
            setSelectedIds([])
            return
        }
        if(item.value === '__all__'){
            if(remove){
                setSelectedUsers([])
                setSelectedIds([])
            }
            else{
                setSelectedUsers([item])
                setSelectedIds(['__all__'])
            }
        }else{
            if(selectedIds.length > 0 && selectedIds[0] === '__all__'){
                setSelectedUsers([item])
                setSelectedIds([item.value])
            }
            else{
                let old_data_ids = [...selectedIds]
                let old_data_full = [...selectedUsers]
                if(remove){
                    let filter_old_data_ids = old_data_ids.filter(list => item.value !== list)
                    setSelectedIds(filter_old_data_ids)
    
                    let filter_old_data_full = old_data_full.filter(list => item.value !== list.value)
                    setSelectedUsers(filter_old_data_full)
                }
                else{
                    old_data_ids.push(item.value)
                    old_data_full.push(item)
                    setSelectedUsers(old_data_full)
                    setSelectedIds(old_data_ids)
                }
            }
        }

        // if(arrayList.indexOf(item) !== -1){
        //     return true
        // }
    }

    return (
        <div className="col s12 m12 l12 xl3 pr-xxl-0 inbox__left_side">
            <div className="mt-0 card message-info b-radius-10 box-shadow-none">
                {
                    componentLoading ? (
                        <div style={{paddingTop: '100px'}}>
                            <Loading/>
                        </div>
                    ) : (
                        <React.Fragment>
                            <GlobalSearchBox
                                wrapperClass="search-box-for-global"
                                placeholder="Search People in Inbox" 
                                type="search" 
                                value={threadQuery} 
                                onChange={handleSearchThreads}/>
                            {/* <div className="m-0 input-field search-box d-flex align-items-center justify-content-center">
                                <input className="m-inbox-left-side-search" />
                                <i className="material-icons">search</i>
                            </div> */}

                            <div className="filtering-buttons">
                                {displayFilter &&
                                    <ul className="p-0 m-0 d-flex justify-content-between align-items-center">
                                        <li className="d-inline-block customSelectLi">
                                            {/* <Select
                                                options={subUsers}
                                                isClearable={true}
                                                value={selectedSubUser}
                                                onChange={subUserHandleChange}
                                                styles={{
                                                    menu: provided => ({ ...provided, zIndex: 9999999 }),
                                                    valueContainer: provided => ({
                                                        ...provided,
                                                        fontSize: '12px',
                                                        padding: '0 8px',
                                                        minWidth: '108px',
                                                        width: '100%'
                                                    })
                                                }}
                                                placeholder="Filter by lead owner"
                                                classNamePrefix="m-inbox-filter"
                                            /> */}
                                            {!Utils.getAccountData('userIsAgent') &&
                                            <GlobalUserListDropdown
                                                userList={subUsers}
                                                handleSelect = {(value, remove) => handleSelectUser(value, remove)}
                                                selected = {selectedUsers}
                                                selectedId = {selectedIds}
                                            />
                                            }
                                        </li>
                                        <li className="d-inline-block filtering-button-trigger">
                                            <a href="#!" onClick={(e) => {
                                                e.preventDefault();
                                                setDisplayFilter(false);
                                                setDisplayCreateNew(true);
                                            }} title="Start new conversation">
                                                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M18 8.62321C18 9.35493 17.928 9.9464 17.0985 9.9464H10.5V15.7671C10.5 16.4975 9.8295 16.5623 9 16.5623C8.1705 16.5623 7.5 16.4975 7.5 15.7671V9.9464H0.9015C0.0734997 9.9464 0 9.35493 0 8.62321C0 7.89149 0.0734997 7.30002 0.9015 7.30002H7.5V1.47932C7.5 0.747595 8.1705 0.684082 9 0.684082C9.8295 0.684082 10.5 0.747595 10.5 1.47932V7.30002H17.0985C17.928 7.30002 18 7.89149 18 8.62321Z"
                                                        fill="#133159" />
                                                </svg>
                                            </a>
                                        </li>
                                    </ul>
                                }
                                {displayCreateNew &&
                                    <ul className="p-0 m-0 d-flex justify-content-between align-items-center">
                                        <li className="d-inline-block customSelectLi">
                                            <div className="auto-complete-search">
                                                <Autocomplete
                                                    // getItemValue={(item) => item.first_name}
                                                    getItemValue={(item) => Utils.getContactDisplayNameV2(item)}
                                                    items={autoCompleteContacts}
                                                    renderItem={(item, isHighlighted) =>
                                                        <div className="auto-complete-custom-wrapper" style={{ background: isHighlighted ? '#dff6f0' : 'white' }} key={item.id}>
                                                            <span className="auto-complete-name-label-span">{Utils.getContactDisplayNameV2(item)}</span><small className="auto-complete-number-label">{Utils.getContactDisplayName('', item.number, item.email)}</small>
                                                        </div>
                                                    }
                                                    value={contactQuery}
                                                    onChange={e => setContactQuery(e.target.value)}
                                                    onSelect={(value, item) =>changeContact(item)}
                                                    inputProps={{ placeholder: 'Start New Conversation', autoFocus: true, className:"m-inbox-left-side-search" }}
                                                    wrapperStyle={{display: 'block'}}
                                                />
                                            </div>
                                        </li>
                                        {!Utils.getAccountData('userIsAgent') &&
                                        <li className="d-inline-block filtering-button-trigger">
                                            <a href="#!" onClick={(e) => {
                                                e.preventDefault();
                                                setDisplayFilter(true);
                                                setDisplayCreateNew(false);
                                            }} title='Filter by owner'>
                                                <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M17.9285 0.797574C17.8338 0.654053 17.6602 0.565918 17.4723 0.565918H0.527334C0.339358 0.565918 0.16583 0.654053 0.0710512 0.797605C-0.0231654 0.941157 -0.0236927 1.11786 0.0695395 1.26185L1.76315 3.82213H16.2364L17.9301 1.26185C18.0232 1.11783 18.0228 0.941126 17.9285 0.797574Z"
                                                        fill="#133159" />
                                                    <path d="M4.47656 8.00879L5.98319 10.3347H12.0193L13.5259 8.00879H4.47656Z" fill="#133159" />
                                                    <path d="M2.36719 4.75293L3.87388 7.0788H14.1285L15.6352 4.75293H2.36719Z" fill="#133159" />
                                                    <path
                                                        d="M6.36328 11.2651V14.9865C6.36328 15.2436 6.59914 15.4517 6.89062 15.4517H11.1093C11.4008 15.4517 11.6366 15.2436 11.6366 14.9865V11.2651H6.36328Z"
                                                        fill="#133159" />
                                                </svg>
                                            </a>
                                        </li>
                                        }
                                    </ul>
                                }
                            </div>

                            <div className="all-message-tabs">
                                <div className="row">
                                    <div className="col s12">
                                        <ul id="tabScrollbar" className="tabs message-links-tab tabs-fixed-width">
                                            <li className="col tab">
                                                <a className={activeTab === TAB_ALL ? 'active' : ''} onClick={(e) => handleTabChange(e, TAB_ALL)} href="#all">All</a>
                                            </li>
                                            <li className="col tab">
                                                <a className={activeTab === TAB_UNREAD ? 'active' : ''} onClick={(e) => handleTabChange(e, TAB_UNREAD)} href="#unread">Unread</a>
                                            </li>
                                            <li className="col tab">
                                                <a className={activeTab === TAB_RECENT ? 'active' : ''} onClick={(e) => handleTabChange(e, TAB_RECENT)} href="#recent">Recent</a>
                                            </li>
                                            <li className="col tab">
                                                <a className={activeTab === TAB_IMPORTANT ? 'active' : ''} onClick={(e) => handleTabChange(e, TAB_IMPORTANT)} href="#important">Important</a>
                                            </li>


                                            <li className="col tab">
                                                <a className={activeTab === TAB_ARCHIVE ? 'active' : ''} onClick={(e) => handleTabChange(e, TAB_ARCHIVE)} href="#archive">Archive</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="pr-0 col s12">
                                        {
                                            loading ? (
                                                <div className="inbox_loader" style={{padding: '0px 10px', display:'block', overflow: 'hidden'}}>
                                                    {/* <Loading/> */}
                                                    {loadingSkeleton()}
                                                </div>
                                            ) : (
                                                    <>
                                                    {
                                                            threads.length>0 && activeTab === TAB_UNREAD &&
                                                            <div className='mark__all__as__read'>
                                                                <button type="button" onClick={readAll}>Mark All As Read</button>
                                                            </div>
                                                        
                                                    }
                                                    <div className="all-messages" id="cutomScrollbar1" onScroll={(e) => loadMoreThreads(e)} ref={scrollingDiv}>
                                                    
                                                        {(updateThread || !updateThread) && populateThreads()}
                                                        {
                                                            scrolling && (
                                                                <div className="load-more-inbox-thread">Loading more...</div>
                                                            )
                                                        }
                                                    </div>
                                                </>
                                             )
                                        }
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                }
                
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        selectedContact: state.inboxReducer.selectedContact,
        selectedContactIndex: state.inboxReducer.selectedContactIndex,
        // updateUnreadContactId: state.inboxReducer.updateUnreadContactId,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addSelectedContact: (params) => dispatch(addSelectedContact(params)),
        updateSelectedTabLeftSide: (params) => dispatch(updateSelectedTabLeftSide(params)),
        updateInboxUnreadContact: (params)=> dispatch(updateInboxUnreadContactInfo(params)),
        updateMultipleData: (params)=> dispatch(updateMultipleData(params))

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftSide);
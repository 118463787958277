import React, { Component } from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';


// import components

import Inbox from "../components/inbox";
import Video from "../components/Video";
import BaseLayoutRoute from './BaseLayoutRouter';
import HealthCheck from '../components/HealthCheck';
import { ApmRoute } from '@elastic/apm-rum-react'

class Routes extends Component {
    render() {
        return (
            <Router>
                <Switch>
                    {/* <Route exact path="/"><Redirect to="/inbox" /></Route> */}
                    {/* <Route exact path="/inbox/:contactId?" component={Inbox}/> */}
                    <ApmRoute exact path="/inbox" component={Inbox}/>
                    <ApmRoute exact path="/video" component={Video}/>
                    <ApmRoute path={"/inbox/health-check"} component={HealthCheck}/>

                </Switch>
            </Router>
        );
    }
} 

export default Routes;
import { all, takeEvery, call, put } from 'redux-saga/effects';
import * as ACTION from '../constants/contactActionTypes'
import {getActivityList} from "../api/contactApi";



function* taskMiddlewareWatcher() {
    yield takeEvery(ACTION.FETCH_TASKS, fetchContactTasks)
}

function* fetchContactTasks(action) {
    try {
        const response = yield call(getActivityList, action.payload.payload);

        let responseData = response.data;

        if (responseData.success) {
            // yield put({type: ACTION.SET_ALL_TASKS, payload: responseData.data.activites});
            yield put({type: ACTION.SET_ALL_TASKS, payload: responseData.data});
        } else {
        }
    } catch (error) {
        console.log(error);
    }

}

export default function* taskMiddleware() {
    yield all([
        taskMiddlewareWatcher()
    ])
}
import Avatar from '@material-ui/core/Avatar';
import { ArrowBack } from "@material-ui/icons";
import React, { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import { addSelectedContact } from "../../../actions/inboxAction";
import { fetchContactDetails } from '../../../api/contactApi';
import Utils from "../../../helpers/Utils";
import BottomBox from "./BottomBox";
import ConversationBox from "./ConversationBox";
import EmptyInbox from "./EmptyInbox";
import TopRightActions from "./TopRightActions";
import {Link} from 'react-router-dom';
import { HistoryProvider } from '../../../App';
import CarrierLookup from "../../carrierLookup";


const MiddleSide = (props) => {
    const history = useContext(HistoryProvider);
    // const [contact, setContact] = useState(props.selectedContact);
    const [value, setValue] = useState(0);
    // const classes = useStyles();

    // useEffect(() => {
    //     setContact(props.selectedContact);
    // }, [props.selectedContact])

    const getContactLabel = (name,number,email) => {
        if(name =='' || name ==' ' || name == null || name == 'null null'){
            if(number == '' || number == ' '){
                if(email == '' || email == ' '){
                    return '[No Name]';
                }else{
                    return email.length > 30 ? email.substr(0, 38)+' ...' : email;
                }
            }else{
                return number;
            }
        }
        return name.length > 30 ? name.substr(0, 28)+' ...' : name;
    }
    
    const removeSelectedContact = e => {
        props.addSelectedContact(null);
    }

    return (
        <React.Fragment>
            {(props.selectedContact ===undefined || props.selectedContact == null || !props.loadThreads) && 
                <EmptyInbox />
            }

            {props.selectedContact && props.loadThreads &&
                <div className= "col s12 m12 l12 xl6 main-inbox_column">
                    <div className="mt-0 card message-info-details b-radius-10 box-shadow-none mb-0">
                    <div className="mx-20 top-action-bar p-relative">
                        <div className="row mb-0 d-flex align-items-center">
                            <div className="col s6">
                                <div className="user-name-area">
                                    <div variant="text" className="back_to_chats waves-effect" onClick={(e) => removeSelectedContact(e)}>
                                        <ArrowBack /> 
                                    </div>
                                    <div className="user-image">
                                        {/*<img src="./images/inbox/ryan.svg" alt="" className="circle responsive-img"/>*/}
                                        <Avatar>{Utils.getInitial((props.selectedContact.first_name ? props.selectedContact.first_name : '') + ' ' + (props.selectedContact.last_name ? props.selectedContact.last_name : '')).toUpperCase()}</Avatar>
                                    </div>
                                    <div className="content">
                                        {/*<p>{getContactLabel((contact.first_name ? contact.first_name : '') + ' ' + (contact.last_name ? contact.last_name : ''), contact.number, contact.email)}</p>*/}
                                        <div className="d-flex align-items-center" style={{ gap: "6px" }}>
                                            <a href={`/contacts/${props.selectedContact.id}`} onClick={(e) => {e.preventDefault();history.push(`/contacts/${props.selectedContact.id}`);}}>{props.selectedContact.id != '' ? getContactLabel(props.selectedContact.first_name+' '+props.selectedContact.last_name,props.selectedContact.number,props.selectedContact.email) : ''}</a>
                                            {(Utils.getAccountData("carrierLookup") && props.contact && props.contact.number &&
                                                    props.contact.number.trim() !== "" &&
                                                    props.contact.contact_additional_informations &&
                                                    props.contact.contact_additional_informations.dnc_status === "DO_NOT_CALL") &&
                                                <div className="dncStatusChip">DNC</div>
                                            }
                                        </div>
                                        {/*<span>(Active 5m ago)</span>*/}
                                    </div>
                                </div>

                            </div>
                            <div className="col s6">
                                <TopRightActions deleteContact={props.changeContactCondition}
                                    setUpdateUnreadContact={props.setUpdateUnreadContact}/>
                            </div>
                        </div>
                    </div>
                    <div className="inbox-main-section position-relative">
                        <ConversationBox />
                        <BottomBox />
                    </div>
                </div>
                </div>
            }
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        contact: state.rightsideReducer.contact,
        selectedContact: state.inboxReducer.selectedContact,
        contactIdFromDashboard: state.inboxReducer.contactIdFromDashboard,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addSelectedContact: (params) => dispatch(addSelectedContact(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MiddleSide);
import React, { Component } from 'react';
// import ModalComponent from '../../Calendar/ModalComponent.js';
import {confirmAlert} from "react-confirm-alert";
import CustomConfirmAlert from '../../../custom-alert/CustomConfirmAlert';
import TimezoneConversation from '../Helpers/TimezoneConversion';
import Utils from '../Helpers/Utils';

class Task extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            showModal: false,
            activityTime: ''
        }

    }

    refreshData = () =>{
        this.props.refreshData();
    };

    checkDisabled = (forEdit = false) => {
        let disable = true,
            assigned_to = this.props.data.assigned_to != null ? parseInt(this.props.data.assigned_to) : null,
            task_user_id = parseInt(this.props.data.user_id),
            contact_owner = parseInt(this.props.people.user_id),
            user_id = parseInt(Utils.getAccountData('userId'));

        if(this.props.makeDone!= null){
            if(task_user_id === user_id){
                disable = false
            }
            else if(assigned_to === user_id && !forEdit){
                disable = false
            }
            else if(contact_owner === user_id && !forEdit){
                disable = false
            }
        }
        return disable;
    }

    // modalToggle = ()=> {
    //     // if(this.props.handleUpdate != undefined){
    //     //     this.props.handleUpdate(this.props.data.id, this.props.data)
    //     // }
    //     window.openGlobalActivityModal({
    //         contactDetails:this.props.people,
    //         editData:this.props.data,
    //         callBack: this.props.refreshData,
    //         from: 'inbox'
    //     })
    // };
    modalToggle = disable => {
        if(disable){
            return;
        }
        window.openGlobalActivityModal({
            contactDetails:this.props.people,
            editData:this.props.data,
            callBack: this.props.refreshData,
            from: 'inbox'
        })
    };

    // switchType = (e)=>{
    //     if (this.props.makeDone!= null && this.props.people != null && this.props.loggedOnUser != null && this.props.people.user_id != this.props.loggedOnUser.id ) {
    //         confirmAlert({
    //             title: 'Alert!',
    //             message: 'You can not add or update activity to member user lead.',
    //             buttons: [
    //                 {
    //                     label: 'Ok',
    //                     onClick: () => {
    //                         return;
    //                     }
    //                 }
    //             ]
    //         });
    //         return false;
    //     }

    //     this.setState({
    //         checked : !this.state.checked
    //     });
    //     if(this.props.makeDone!= null){
    //         this.props.makeDone(e);
    //     }else{
            
    //     }
    // };

    switchType = (e, disable = false)=>{
        if(this.props.makeDone == null){
            return
        }
        if(disable){
            // setChecked(false)
            this.setState({
                checked: false
            })
            e.target.checked = false
            /* show alert message */
            CustomConfirmAlert({
                handleConfirm : () => {},
                handleOnClose: () => {},
                title: 'Oops !!!',
                description: 'You can not add or update activity to member user lead.',
                icon: 'cancel',
                closeButtonText: "Got It !",
                minHeight: '260px',
                showConfirmButton: false
            })
            return
        }
        // setChecked(prevState=> !prevState)
        this.setState({
            checked: false
        })
        if(this.props.makeDone != null){
            this.props.makeDone(e, this.props.index)
        }
    };

    renderCheck = () => {
        const disable = this.checkDisabled()
                
        if(this.props.makeDone == null){
            return (
                <div className="m-widget2__checkbox">
                    <label className="m-checkbox m-checkbox--solid m-checkbox--single m-checkbox--brand">
                        <input onChange={(e) => this.switchType(e,disable)} checked={"checked"} type="checkbox" defaultValue={this.props.data.id}  name="check-1"/>
                        <span style={disable ? {opacity: 0.5, cursor: 'not-allowed'} : {}} />
                    </label>
                </div>
            );
        }
        return (
            <div className="m-widget2__checkbox">
                <label className="m-checkbox m-checkbox--solid m-checkbox--single m-checkbox--brand">
                    <input onChange={(e) => this.switchType(e,disable)} checked={this.state.checked ? "checked" : null} type="checkbox" defaultValue={this.props.data.id}  name="check-1"/>
                    <span style={disable ? {opacity: 0.5, cursor: 'not-allowed'} : {}}/>
                </label>
            </div>
        );
    }
    renderTitle = () => {
        let disable = this.checkDisabled(true);
        return (
            <span className="m-widget2__text task cursor-pointer" style={{color: '#039be5'}} onClick={(e) => this.modalToggle(disable)}>
                <i className="fa fa-share"/>
                {this.props.data.task_title}
            </span>
        );
    }
    renderCreatedBy = () => {
        let title = ""
        if((this.props.data.user !== undefined && this.props.data.user != null && this.props.data.user.full_name !== undefined)){
            title = this.props.data.user.full_name
        }
        else{
            title = Utils.getAccountData('fullName')
        }

        return (
            <span className="m-widget2__user-name">
                <a href="#!" className="m-widget2__link m--font-success" onClick={e=>e.preventDefault()}>
                    <i className="fa fa-user m--font-success"/>By {title}
                </a>
            </span>
        );
    }

    render() {

        let isDone = (this.props.data.status === 2) ? "m-widget2__item m-widget2__item--primary task-list d-flex done" :
            "m-widget2__item m-widget2__item--primary task-list d-flex";

        return (
            <div className={isDone}>
                {/* {this.props.makeDone != null &&
                <div className="m-widget2__checkbox">
                    <label className="m-checkbox m-checkbox--solid m-checkbox--single m-checkbox--brand">
                        <input onChange={this.switchType} checked={(this.state.checked) ? "checked" : null} type="checkbox" defaultValue={this.props.data.id}  name="check-1"/>
                        <span/>
                    </label>
                </div>
                }

                {this.props.makeDone == null &&
                <div className="m-widget2__checkbox">
                    <label className="m-checkbox m-checkbox--solid m-checkbox--single m-checkbox--brand">
                        <input onChange={this.switchType} checked={"checked"} type="checkbox" defaultValue={this.props.data.id}  name="check-1"/>
                        <span/>
                    </label>
                </div>
                } */}

                {this.renderCheck()}

                <div onClick={this.modalToggle} className="m-widget2__desc info">
                    {/* <span className="m-widget2__text task cursor-pointer" style={{color: '#039be5'}} onClick={this.modalToggle}>
                    <i className="fa fa-share"/>
                        {this.props.data.task_title}
                    </span> */}
                    {this.renderTitle()}
                    <span className="m-widget2__text m--font-info">
                        <i className="fa fa-clock-o m--font-info"/>
                        {TimezoneConversation(this.props.data.date + " " + this.props.data.time,'UTC',Utils.getAccountData('userTimezoneRaw') , "MM/DD/YYYY hh:mm A")}
                    </span>
                    {/* <span className="m-widget2__user-name">
                        <a href="#" className="m-widget2__link m--font-success">
                            <i className="fa fa-user m--font-success"/>By {this.props.user.full_name}
                        </a>
                    </span> */}
                    {this.renderCreatedBy()}
                </div>
            </div>
        );
    }
}

export default Task;

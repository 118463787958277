import React, { useState } from "react";
import { Box, IconButton, Typography, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { BasicInput, RightSideV2Styles } from "../RightSideV2Styles";
import CustomTitleV2 from "../common/customTitleV2/CustomTitleV2";
import DraggableIcon from "../common/icons/DraggableIcon";
import EditIcon from "../common/icons/EditIcon";
import EditImportantLinksModal from "./EditImportantLinksModal";
import CustomTitle from "../common/customTitle/CustomTitle";
import BootstrapTooltip from "../../../globals/BootstrapTooltip";

const useStyles = makeStyles({
  hoverDiv: {
    "&:hover": {
      "& $icon": {
        opacity: 1,
        transition: "width .35s",
        width: "42px",
        display: "flex",
      },
    },
  },
  icon: {
    opacity: 0,
    width: 0,
    display: "none",
  },
});

const ImportantLinks = (props) => {
  const [open, setOpen] = useState(false);

  const { contact } = props || {};

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

    const renderAdditionalLinks = () => {
        if (contact.contact_additional_informations && contact.contact_additional_informations.important_links){
            let importantDates = JSON.parse(contact.contact_additional_informations.important_links);
            return importantDates.map(item => (
                <Box className={icons.datewidth} sx={{ flex: 1, flexDirection: "row", display: "flex", gap: "10px" }}>
                    <Typography variant='body2'>{item.title}:</Typography>
                    <Typography variant='body2' style={{ width: "260px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                        <BootstrapTooltip arrow positon={'top'} title={item.value}>
                            <a href={item.value} target='_blank' rel="noreferrer">
                                <b style={{color: "#316aff"}}> {item.value}</b>
                            </a>
                        </BootstrapTooltip>
                    </Typography>
                </Box>
            ));
        }
    }


    const { titleIcon, flexCenter } = RightSideV2Styles();
  const icons = useStyles();

  return (
    <div className={icons.hoverDiv}>
      <div sx={{ gap: 10, marginBottom: 16 }}>
        <CustomTitle
          handleOpen={handleOpen}
          title={"IMPORTANT LINKS"}
          iconHide={icons.icon}
        />
      </div>

      <div
        className={flexCenter}
        style={{
          gap: 10,
          flexWrap: "wrap",
          width: "100%",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textWrap: "wrap",
          marginTop: "16px",
        }}
      >
        {contact?.url && (
          <div
            style={{
              flex: 1,
              //whiteSpace: "pre-wrap",
            }}
          >
              {contact?.url && (
                  <Box sx={{ flex: 1, flexDirection: "row", display: "flex", gap: "10px" }}>
                      <Typography variant='body2'>Contact Url:</Typography>
                      <Typography variant='body2' style={{ width: "260px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                          <BootstrapTooltip arrow positon={'top'} title={contact.url}>
                              <a href={contact.url} target='_blank' rel="noreferrer">
                                  <b style={{ color: "#316aff" }}> {contact.url}</b>
                              </a>
                          </BootstrapTooltip>
                      </Typography>
                  </Box>
              )}
          </div>
        )}
          {renderAdditionalLinks()}
      </div>
      <EditImportantLinksModal open={open} onClose={handleClose} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.rightsideReducer.contact,
  };
};
export default connect(mapStateToProps)(ImportantLinks);

import React, {useState, useEffect, useRef} from "react";
import {connect} from "react-redux";
import VideoLibraryModal from "./VideoLibraryModal";
import CoreConstants from "./CoreConstants";
import Webcam from "./Webcam.react";
import If, {Else} from 'if-else-react'
import RecordRTC from "recordrtc";
// import './video.css';
import RecordedVideo from "./RecordedVideo";
import LikeVideo from "./LikeVideo";
import axios from "axios";
import Api from "./redux/Api";
import {getCookie} from "../../helpers/Cookie";
import VideoLoader from "./VideoLoader";
import SavedReply from "./SavedReply";

let videoStream;
let recordVideo;
let recordGIf;
let gif;

let enableSubmit = true;

const VideoEmail = props => {
    const FIRST_WORLD = {
        videoStatus: CoreConstants.VIDEO_STATUS_WELCOME,
        loader: false,
        loaderMessage: 'Open Camera',
        videoUrl: '',
        gifUrl: '',
        isVideoSelected: false,
        videoObj: null,
        activeVideoId: props.emailVideoId,
        fileUpload: false,
        videoPreviewLoader: false,
    }

    const [state, setState] = useState(FIRST_WORLD);
    const [videoLibraryModal, setVideoLibraryModal] = useState(false);
    const [savedReplyModal, setSavedReplyModal] = useState(false);

    const openCamera = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let hasGetUserMedia = '';
        try {
            hasGetUserMedia = !!(navigator.mediaDevices || navigator.mediaDevices.getUserMedia || navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia || navigator.msGetUserMedia);
        } catch (e) {
            setState({...state, videoStatus: CoreConstants.VIDEO_STATUS_WARNING});
            return 0;
        }


        if(!hasGetUserMedia) {
            setState({...state, videoStatus: CoreConstants.VIDEO_STATUS_WARNING});
            return 0;
        }

        requestUserMedia();
    }

    const requestUserMedia = () => {
        navigator.mediaDevices.getUserMedia({ audio: true, video: true })
            .then(function(mediaStream) {
                setState({...state, videoStatus: CoreConstants.VIDEO_STATUS_WEBCAM});
                let video = document.getElementById('video-email-vid');
                video.srcObject = mediaStream;
                videoStream = mediaStream;
                video.onloadedmetadata = function(e) {
                    video.play();
                };
            })
            .catch(function(err) {
                setState({...state, videoStatus: CoreConstants.VIDEO_STATUS_WARNING});
                return 0;
            });
    }

    const startRecord = () => {
        try {
            recordVideo = RecordRTC(videoStream, { type: 'video' });
            recordVideo.startRecording();

            recordGIf = RecordRTC(videoStream, { type: 'gif' });
            recordGIf.startRecording();
        }
        catch(err) {
            setState({...state, videoStatus: CoreConstants.VIDEO_STATUS_WARNING});
            return 0;
        }
    }

    const stopRecord = () => {
        setState({...state, loader: true});
        recordVideo.stopRecording(() => {
            let urlCreator = window.URL || window.webkitURL;
            let videoUrl = urlCreator.createObjectURL(recordVideo.blob);
            videoStream.getTracks().forEach(track => track.stop());

            setState({
                ...state,
                videoUrl: videoUrl,
                gifUrl: gif,
                videoStatus: CoreConstants.VIDEO_STATUS_PREVIEW,
                isVideoSelected: false,
                loader: false
            });
            return true;
        });
    }

    const stopGifRecord = () => {
        if (state.gifUrl == '') {
            recordGIf.stopRecording(() => {
                let urlCreator = window.URL || window.webkitURL;
                gif = urlCreator.createObjectURL(recordGIf.blob);
                return false;
            });
        }
    }

    const selectVideo = (data) => {
        if (data) {
            if (!state.activeVideoId) {

                if(data.title == '' || data.title == null){
                  window.showNotification("ERROR", "Video title is required")
                    return;
                }

                setState({...state, videoPreviewLoader: true});
                // create FormData
                let formData = new FormData();
                formData.append('api-key', getCookie(process.env.REACT_APP_ACCESS_TOKEN));

                if (state.fileUpload) {
                    formData.append('video-file', document.getElementById('video-file').files[0]);
                    formData.append('gif-convert-required', 1);
                } else {
                    let blob = recordVideo instanceof Blob ? recordVideo : recordVideo.getBlob();
                    blob = new File([blob], 'RecordRTC-video.webm', {
                        type: 'video/webm'
                    });


                    let gifBlob = recordGIf instanceof Blob ? recordGIf : recordGIf.getBlob();
                    gifBlob = new File([gifBlob], 'RecordRTC-gif.gif', {
                        type: 'image/gif'
                    });

                    formData.append('video-file', blob);
                    formData.append('gif-file', gifBlob);
                }

                formData.append('title', data.title);
                formData.append('description', data.description);
                formData.append('folderId', data.folderId);

                if (enableSubmit) {
                    enableSubmit = false;
                    axios.post( Api.uploadVideo, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        onUploadProgress: function( progressEvent ) {
                            console.log((Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ))+'%');
                            // document.getElementById('progress-bar').style.width = (Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ))+'%';
                        }.bind(this)
                    }).then(function(response){
                        let data = response.data;
                        if (data.status == 'success') {
                            setState({...state, loader: false, isVideoSelected: true, videoPreviewLoader: false});
                            window.showNotification("SUCCESS", data.msg)
                            props.setEmailVideoId(data.id);
                        } else {
                            document.getElementById('progress-bar').style.background = '#f12143';
                            setState({...state, loader: false, isVideoSelected: false, videoPreviewLoader: false});
                            window.showNotification("ERROR", data.msg)
                            
                        }
                    }).catch(function(response){
                        setState({...state, loader: false, videoSelected: false, videoPreviewLoader: false});
                        window.showNotification("ERROR", response)
                    });
                }
            } else {

                if(data.title != state.videoObj.title || data.description != state.videoObj.description || data.folderId != state.videoObj.campaign_video_folder_id){

                    let formData = new FormData();
                    formData.append('id', state.activeVideoId);
                    formData.append('title', data.title);
                    formData.append('description', data.description);
                    formData.append('folderId', data.folderId);

                    axios.post( '/user/campaign/update-video-email', formData).then(function(response){
                        let data = response.data;
                    }).catch(function(response){
                        console.log(response);
                    });

                }

                setState({...state, loader: false, videoSelected: true});
                window.showNotification("SUCCESS", 'You are good to go!')
                props.setEmailVideoId(state.activeVideoId);
            }
        } else {
            setState(FIRST_WORLD);
        }
    }

    const loadFile = e => {
        let file = e.target.files[0];
        if (file.size < 100*1024*1024) {
            let videoUrl = window.URL.createObjectURL(file);
            setState({...state, videoUrl: videoUrl, isVideoSelected: false, videoStatus:CoreConstants.VIDEO_STATUS_PREVIEW, previewVideo: true, fileUpload: true, videoPreviewLoader: false});
        } else {
          window.showNotification("ERROR", 'Max file size limit 100MB')
        }
    }

    const loadSelectedVideoFile = fileInfo => {
        setState({...state, activeVideoId:fileInfo.id, videoUrl: fileInfo.video_link, isVideoSelected: true, videoStatus:CoreConstants.VIDEO_STATUS_PREVIEW, previewVideo: true, fileUpload: false, videoPreviewLoader: false});
        props.setEmailVideoId(fileInfo.id);
        setVideoLibraryModal(false)
    }

    const useMessageTemplate = data => {
        setSavedReplyModal(false);
        setState({
            ...state,
            videoUrl: data.video_file_url ?? data.template_files.file,
            gifUrl: data.gif_file_url,
            videoStatus: CoreConstants.VIDEO_STATUS_PREVIEW,
            isVideoSelected: true,
            videoPreviewLoader: false,
            loader: false
        });

        props.useMessageTemplate(data);
    }

    return (
        <div className="video_contents bg-light-blue-10 mt-3 mb-2 p-3 radius-10 update_video_contents">
            <h6 className="text-dark-blue m-0 text-left">Record/Upload a new audio file</h6>
            <div className="row mt-3">
                <If condition={state.loader}>
                    <VideoLoader message={state.loaderMessage}/>
                <Else />
                    <div className="col s12 m12 l6 xl6">
						<div className="flexItemCenter">
							<div className="d-flex justify-content-center align-items-center">
								<div className="voice_mail_icon" onClick={(e) => openCamera(e)}>
									<span className="bg-light-blue-10 circle d-flex justify-content-center align-items-center cursor-pointer">
										<i className="material-icons text-light-blue">
											videocam
										</i>
									</span>
									<p className="text-gray">Record</p>
								</div>
								<div className="voice_mail_icon ml-5 cursor-pointer">
									<label className="bg-light-blue-10 circle d-flex justify-content-center align-items-center">
										<input type="file" className="d-none" onChange={(e) => loadFile(e)} id="video-file"/>
										<svg width="18" height="18" viewBox="0 0 18 18"
											fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M6.20815 5.18801L8.08721 3.30895V13.2491C8.08721 13.4906 8.18312 13.7221 8.35385 13.8928C8.52457 14.0635 8.75612 14.1595 8.99756 14.1595C9.239 14.1595 9.47055 14.0635 9.64127 13.8928C9.81199 13.7221 9.9079 13.4906 9.9079 13.2491V3.30895L11.787 5.18801C11.8714 5.27292 11.9718 5.34034 12.0823 5.38639C12.1929 5.43244 12.3114 5.45623 12.4312 5.4564C12.5509 5.45656 12.6696 5.43309 12.7802 5.38734C12.8909 5.34159 12.9915 5.27445 13.0761 5.18976C13.1608 5.10508 13.228 5.00453 13.2737 4.89386C13.3195 4.78318 13.3429 4.66457 13.3428 4.54482C13.3426 4.42506 13.3188 4.30651 13.2728 4.19596C13.2267 4.08542 13.1593 3.98504 13.0744 3.90059L9.64129 0.467456C9.55675 0.382918 9.45639 0.315858 9.34594 0.270106C9.23549 0.224354 9.11711 0.200806 8.99756 0.200806C8.87801 0.200806 8.75962 0.224354 8.64917 0.270106C8.53872 0.315858 8.43836 0.382918 8.35383 0.467456L4.92073 3.90059C4.83582 3.98504 4.76841 4.08542 4.72235 4.19596C4.6763 4.30651 4.65251 4.42506 4.65234 4.54482C4.65218 4.66457 4.67565 4.78318 4.7214 4.89386C4.76716 5.00453 4.8343 5.10508 4.91898 5.18976C5.00366 5.27445 5.10422 5.34159 5.21489 5.38734C5.32556 5.43309 5.44417 5.45656 5.56393 5.4564C5.68368 5.45623 5.80223 5.43244 5.91278 5.38639C6.02332 5.34034 6.1237 5.27292 6.20815 5.18801Z"
												fill="#3C7EF3"/>
											<path
												d="M16.8889 8.08984C16.6474 8.08984 16.4159 8.18575 16.2452 8.35648C16.0744 8.5272 15.9785 8.75875 15.9785 9.00019V15.9795H2.01991V9.00019C2.01991 8.75875 1.924 8.5272 1.75327 8.35648C1.58255 8.18575 1.351 8.08984 1.10956 8.08984C0.868125 8.08984 0.636575 8.18575 0.465853 8.35648C0.29513 8.5272 0.199219 8.75875 0.199219 9.00019V16.2829C0.199219 16.6853 0.359071 17.0713 0.643608 17.3558C0.928146 17.6403 1.31406 17.8002 1.71646 17.8002H16.282C16.6844 17.8002 17.0703 17.6403 17.3548 17.3558C17.6394 17.0713 17.7992 16.6853 17.7992 16.2829V9.00019C17.7992 8.75875 17.7033 8.5272 17.5326 8.35648C17.3619 8.18575 17.1303 8.08984 16.8889 8.08984Z"
												fill="#3C7EF3"/>
										</svg>
									</label>
									<p className="text-gray">Upload</p>
								</div>
								{props.from !== 'saved_reply' &&
									<React.Fragment>
										<a className="voice_mail_icon ml-5 modal-trigger" href="#" onClick={(e) => { e.preventDefault(); setVideoLibraryModal(true) }}>
											<span
												className="bg-light-blue-10 circle d-flex justify-content-center align-items-center">
												<i className="material-icons text-light-blue">
													video_library
												</i>
											</span>
											<p className="text-gray">Library</p>
										</a>
										<If condition={videoLibraryModal}>
											<VideoLibraryModal loadSelectedVideoFile={loadSelectedVideoFile} openVideoLibraryModal={videoLibraryModal} setOpenVideoLibraryModal={setVideoLibraryModal}/>
										</If>
										<a className="voice_mail_icon ml-5 modal-trigger" href="#" onClick={(e) => {e.preventDefault(); setSavedReplyModal(true)}}>
											<span
												className="bg-light-blue-10 circle d-flex justify-content-center align-items-center">
												<i className="material-icons text-light-blue">folder</i>
											</span>
											<p className="text-gray">Quick Replies</p>
										</a>
										<If condition={savedReplyModal}>
											<SavedReply openSavedReplyModal={savedReplyModal} setOpenSavedReplyModal={setSavedReplyModal} useMessageTemplate={useMessageTemplate} templateType={CoreConstants.MESSAGE_TEMPLATE.VIDEO_EMAIL}/>
										</If>
									</React.Fragment>
								}
							</div>
							<div className="d-flex">
								<i className="material-icons text-red">error_outline</i>
								<p className="text-red m-0 mt-1 text-left ml-2">Click yes after recording to send the video. Record at least 5 seconds to get preview image</p>
							</div>
						</div>
                    </div>
                    <div className="col s12 m12 l6 xl6">
                        <If condition={state.videoStatus == CoreConstants.VIDEO_STATUS_WELCOME}>
                            <div className="align-items-center px-3 py-2 radius-10 video_email_bg_icon white" style={{background: 'url('+`${process.env.REACT_APP_APP_URL}/assets/images/video-bg.gif`+') no-repeat center center/ cover'}}>
                                <p className="text-dark-blue overlay_text">How would you like to create your video?</p>
                            </div>
                        </If>
                        <If condition={state.videoStatus == CoreConstants.VIDEO_STATUS_WARNING}>
                            <div className="align-items-center px-3 py-2 radius-10 video_email_bg_icon white">
                                <h4>😑 Oh snap! We were not able to initialize your camera!</h4>
                                <p className="text-justify">Your browser has not camera access. Please update to latest version, check camera and microphone permission, or change the browser</p>
                                <p>
                                    See how to
                                    <a href="https://help.daily.co/en/articles/2528184-unblock-camera-mic-access-on-a-computer" target="_blank"> enable </a>
                                    camera access or
                                    <a href="https://caniuse.com/#search=webrtc" target="_blank"> check </a>
                                    if your browser is supported
                                </p>
                            </div>
                        </If>
                        <If condition={state.videoStatus == CoreConstants.VIDEO_STATUS_WEBCAM}>
                            <div className="d-inline-flex align-items-center white radius-10 px-3 py-2 video_preview_side">
                                <Webcam stopGif={stopGifRecord} startClick={startRecord} stopClick={stopRecord}/>
                            </div>
                        </If>
                        <If condition={state.videoStatus == CoreConstants.VIDEO_STATUS_PREVIEW}>
                            <div className="d-inline-flex align-items-center white radius-10 px-3 py-2 video_preview_side">
                                <If condition={!state.isVideoSelected}>
                                    <LikeVideo videoObj={state.videoObj} selectVideo={selectVideo}/>
                                </If>
                                <RecordedVideo urlLink={state.videoUrl}/>
                            </div>
                        </If>
                    </div>
                </If>
            </div>

        </div>
    );
}

const mapStateToProps = state => {
    return {
        userInfo: state.videoEmailReducer.userInfo,
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoEmail);
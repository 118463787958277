import React from 'react';
import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

const SkeletonContainer = styled.div`
  padding: 16px;
`;

const SkeletonElement = styled.div`
  background: #f0f0f0;
  background-image: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200px 100%;
  animation: ${shimmer} 2.5s infinite linear;
  border-radius: 4px;
  margin-bottom: 12px;
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '20px'};
`;

const SkeletonSearchBar = styled(SkeletonElement)`
  width: 100%;
  height: 50px;
  margin-bottom: 24px;
  margin-top:45px;
`;

const SkeletonUserItem = styled(SkeletonElement)`
  height: 80px;
  width: 100%;
  margin-bottom: 25px;

`;

const SkeletonLoader = () => {
  return (
    <SkeletonContainer>
      <SkeletonSearchBar />
      {Array.from({ length: 5 }).map((_, index) => (
        <SkeletonUserItem key={index} />
      ))}
    </SkeletonContainer>
  );
};

export default SkeletonLoader;

import React from "react";
import * as ACTION from "../constants/contactActionTypes";

export const setContactDetails = payload => ({
    type: ACTION.SET_CONTACT_DETAILS,
    payload: payload
});

export const updateContactInfo = payload => ({
    type: ACTION.UPDATE_CONTACT,
    payload: payload
});

export const getContactTagList = payload =>({
    type: ACTION.GET_CONTACT_TAG,
    payload: payload
})

export const getCategoryList = (payload, callback) =>({
    type: ACTION.GET_MAIL_CATEGORY,
    payload: {payload, callback}
})

export const CreateCardTemplate = (payload,callback)=>({
    type: ACTION.CREATE_POSTCARD_TEMPLATE,
    payload: {payload, callback}
})

export const toggleSMSModal = payload =>({
    type: ACTION.SHOW_SMS_MODAL,
    payload: payload
})
export const toggleNoteModal = payload =>({
    type: ACTION.SHOW_NOTE_MODAL,
    payload: payload
})
export const toggleTaskModal = payload =>({
    type: ACTION.SHOW_TASK_MODAL,
    payload: payload
})

export const getUserVirtualNumberList = payload =>({
    type: ACTION.GET_VIRTUAL_NUMBER_LIST,
    payload: payload
})

export const getVideoFolders = () => ({
    type:ACTION.FETCH_VIDEO_FOLDERS,
    payload: {}
});
export const getVideos = payload => ({
    type:ACTION.FETCH_VIDEOS,
    payload: payload
});
export const deleteVideo = (payload, callback)=> ({
    type:ACTION.DELETE_VIDEOS,
    payload: {payload, callback}
});
export const updateFolderId = payload => ({
    type:ACTION.UPDATE_FOLDER_ID,
    payload: payload
});
export const getMessageTemplates = payload => ({
    type:ACTION.FETCH_MESSAGE_TEMPLATES,
    payload: payload
});
export const getAllContacts =  (payload, callback) => ({
    type:ACTION.GET_ALL_CONTACTS,
    payload: {payload, callback}
});
export const getUserInfo =  (payload, callback) => ({
    type:ACTION.GET_USER_INFO,
    payload: {payload, callback}
})

export const fetchTasks =  (payload, callback) => ({
    type:ACTION.FETCH_TASKS,
    payload: {payload, callback}
});

export const followupActivitySave = (payload, callback) => ({
    type: ACTION.SAVE_ACTIVITY_FOLLOWUP,
    payload: {payload, callback}
});

export const getSubUserList = payload => ({
    type: ACTION.GET_SUBUSER_LIST,
    payload: payload
});
export const getUserTagList = (payload, callback) => ({
  type: ACTION.GET_USERTAG_LIST,
  payload: { payload, callback },
})
export const setUserTagList = (payload) => ({
  type: ACTION.SET_USERTAG_LIST,
  payload: { payload },
})

import React, { Component } from 'react';

import FineUploaderTraditional from 'fine-uploader-wrappers';
import FileInput from 'react-fine-uploader/file-input';
import ProgressBar from 'react-fine-uploader/progress-bar';
import Filename from 'react-fine-uploader/filename';
import Status from 'react-fine-uploader/status';
import CancelButton from 'react-fine-uploader/cancel-button';
import DeleteButton from 'react-fine-uploader/delete-button';
import Filesize from 'react-fine-uploader/filesize';
import './uploader.css';
import axios from 'axios';
import { getCookie } from '../../../helpers/Cookie';
import Api from '../../../constants/Api';
// import Api from '../../../constants/Api';
// import {getCookie} from "../../../helpers/Cookie";

const uploader = new FineUploaderTraditional({
    options: {
        deleteFile: {
            enabled: false,
            endpoint: '',
        },
        request: {
            endpoint: Api.uploadEmailFile,
            params: {'api-key': getCookie(process.env.REACT_APP_ACCESS_TOKEN)}
        },
        validation: {
            allowedExtensions: ['csv','txt','pdf','mp3','mp4','mov','wav','webm','3gp','avi','doc','docx','odt','ppt','pptx','gif','png','jpg','jpeg','xls','xlsx', 'zip', 'tar', 'xz', '7z', 'rar', 'odp', 'odg'],
            itemLimit: 5,
            sizeLimit: 15728640,
            stopOnFirstInvalidFile: true
        },
        messages: {
            typeError: '{file} has an invalid extension. Valid extensions[{extensions}].',
            sizeError: '{file} is too large, maximum file size is {sizeLimit}.',
            tooManyItemsError: 'Too many items ({netItems}) would be uploaded. Item limit is {itemLimit}.'
        }
    }
});

export default class FileListener extends Component {
    constructor(props) {
        super(props)
        this.state = {
            submittedFiles: [],
            images : [],
            error: false,
            errorMessage: null
        }
    }

    componentDidMount() {

        uploader.on('statusChange', (id, oldStatus, newStatus) => {
            if (newStatus === 'submitted') {
                const submittedFiles = this.state.submittedFiles

                submittedFiles.push(id);
                this.setState({ submittedFiles })
                this.setState({error:false});
            }
            else if (isFileGone(newStatus)) {
                const submittedFiles = this.state.submittedFiles
                const indexToRemove = submittedFiles.indexOf(id)

                submittedFiles.splice(indexToRemove, 1)
                this.setState({ submittedFiles })
            }
        })

        uploader.on('complete', (id, fileName, response) => {
            const images = this.state.images;
                  images.push({
                      'id' : id,
                      // 'name' : response.fileName,
                      'name' : response.mediaUrl,
                      'file_original_name': fileName
                  });

            this.setState({
                images : images
            });

            this.props.imagesList(this.state.images)
        });

        uploader.on('error', (event, id, name, errorReason, xhrOrXdr) => {
            console.log("------", event, id, name, errorReason, xhrOrXdr)
            this.setState({
                error: true,
                errorMessage: name
            });
            window.showNotification(name, 'ERROR');
        });

        uploader.on('delete', (id) => {
            const images = this.state.images;
            let deleteImage = images.filter( el => el.id != id );
            let deleteFile = images.filter( el => el.id == id );
            axios.post(Api.removeFile, deleteFile)
                .then(res => {
                    this.setState({
                        images : deleteImage
                    });

                    this.props.imagesList(this.state.images);
                });
        });

        uploader.on('cancel', (id) => {
            const images = this.state.images;
            let cancelImage = images.filter( el => el.id != id );
            this.setState({
                images : cancelImage
            });

            this.props.imagesList(this.state.images)
        });
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.updateComponent.length == 0){
            this.setState({
                image : [],
                submittedFiles : [],
                error:false
            });
        }
    }

    render() {
        return (
            <div className="mt-2">
                {/* {this.state.error ?
                    <div className="text-danger">
                        {this.state.errorMessage}
                    </div>
                    :
                    ''
                } */}
                <FileInput multiple uploader={ uploader }>
                    <button className="btn btn-sm btn-secondary mr-1 accent--bg--color"><i className="la la-cloud-upload"></i> Attach Files</button>
                    <span className="text-warning"><i className={"fa fa-info rounded bg-warning p-1 text-light mt-1 mr-1"}></i>Max size 15Mb, Max 5 files.</span>
                </FileInput>
                <div className="uploaded-files">
                    {
                        this.state.submittedFiles.map(id => (
                            <div key={id } className="d-flex align-items-center file-upload-stats">
                                <Filename id={ id } uploader={ uploader } />
                                <div className="ml-auto d-flex align-items-center">
                                    <ProgressBar id={ id } uploader={ uploader } />
                                    <Filesize id={ id } uploader={ uploader } />
                                    <CancelButton id={ id } uploader={ uploader } className={'btn btn-sm btn-outline-warning'}/>
                                    <DeleteButton id={ id } uploader={ uploader } className={'btn btn-sm btn-outline-danger'} />
                                    <Status id={ id } uploader={ uploader } text={ {
                                        upload_successful: <i className={'fa fa-check-circle'}></i>,
                                        upload_failed: <i className={'fa fa-remove'}></i>,
                                        uploading: ''
                                    }}/>
                                </div>
                            </div>
                        ))
                    }

                </div>
            </div>
        )
    }
}

const isFileGone = status => {
    return [
            'canceled',
            'deleted',
        ].indexOf(status) >= 0
}

import React, { useEffect, useState } from "react";
import EachCustomField from "./EachCustomField";
import { Box, Paper } from "@material-ui/core";
import CustomStyles from "../common/Styles/Style.module.css";

const AdditionalInfoList = (props) => {
  const [customFields, setCustomFields] = useState([]);
  useEffect(() => {
    window
      .globalCustomFieldWithPersonalized({
        get_custom_data: true,
        get_personalized_data: false,
        field_name: ["*"],
        for_drop_down: false,
        drop_down_field: {
          value_field: "id",
          title_field: "title",
        },
      })
      .then((res) => {
        let customFieldDatas = res.data.custom_field;
        setCustomFields(customFieldDatas);
      });
  }, [props?.contactCustomFields]);

  const fields = () => {
    let arrayData = [];
    customFields.map((data, index) =>
      arrayData.push(
        <EachCustomField
          data={data}
          key={index}
          value={props?.contactCustomFields}
        />
      )
    );

    return arrayData;
  };

  const isValueExist = props?.contactCustomFields?.some((item) => item.value);

  return (
    <div>
      {Array.isArray(customFields) && customFields[0] && isValueExist && (
        <Box
          sx={{
            borderRadius: "4px !important",
            border: "1px solid #ced4da !important",
            // padding: "15px 6px 15px 15px",

            // overflow: "hidden",
          }}
        >
          <Box
            sx={{ maxHeight: "500px" }}
            className={CustomStyles.fantasticScrollbar}
            p={1}
          >
            {fields()}
          </Box>
        </Box>
      )}
    </div>
  );
};

export default AdditionalInfoList;

import React, { useEffect, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { getFromLocalStorage, saveToLocalStorage } from "./helper/utils";
import Address from "./address/Address";
import Email from "./email/Email";
import PhoneNumber from "./phoneNumbers/PhoneNumber";
import ImportantDates from "./importantDates/ImportantDates";
import AdditionalInfo from "./AdditionalInfo/AdditionalInfo";
import Miscellaneous from "./Miscellaneous";
import ImportantLinks from "./importantLinks/ImportantLinks";
import Tags from "./tags/Tags";

const getItemStyle = (isDragging, draggableStyle) => ({
  "& .lsv2DraggableItem": {
    borderRadius: 4,
    userSelect: "none",
    boxSizing: "border-box",
    background: isDragging ? "#fbfbfb" : "transparent",
    border: isDragging ? "1px solid rgba(0, 0, 0, 0.12)" : "",
    padding: isDragging ? 10 : "",
  },

  // styles we need to apply on draggables
  ...draggableStyle,
});

const useStyles=makeStyles({
  boxClass:{
    '&.MuiBox-root':{
      width:'100%'
    }
  }
})

const DraggableSection = ({ refreshData, contactId, refreshValue }) => {
  const initialState = [
    { id: 1, component: <Address new={true} /> },
    { id: 2, component: <Email /> },
    { id: 3, component: <PhoneNumber /> },
    { id: 4, component: <Tags /> },
    { id: 4, component: <ImportantDates /> },
    { id: 5, component: <ImportantLinks /> },
    { id: 6, component: <AdditionalInfo /> },
    {
      id: 7,
      component: (
        <Miscellaneous
          refreshData={refreshData}
          data={null}
          contactId={contactId}
          from="inbox"
          sourceList={null}
          refreshValue={refreshValue}
        />
      ),
    },
  ]

  const [items, setItems] = useState(initialState);

  const localOrder = getFromLocalStorage("inboxRightDragOrder");

  const classes = useStyles()

  useEffect(() => {
    const defaultState = initialState.map((iState)=>(iState.id));

    if (localOrder && defaultState.length === localOrder.length) {
      const rearrangedState = localOrder.map((id) => initialState.find((item) => item.id === id));
      setItems(rearrangedState);
    }else if (localOrder && defaultState.length > localOrder.length) {
      const tempLocalOrder = [...localOrder];

      for (let i = 0; i < defaultState.length; i++) {
        let element = defaultState[i];
        if (!tempLocalOrder.includes(element)) {
          tempLocalOrder.push(element);
        }
      }
      const rearrangedState = tempLocalOrder.map((id) => initialState.find((item) => item.id === id));
      setItems(rearrangedState);
      saveToLocalStorage("inboxRightDragOrder", tempLocalOrder);
    }else {
      saveToLocalStorage("inboxRightDragOrder", defaultState);
    }
  }, [contactId]);

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const newBox = Array.from(items);
    const [draggedItem] = newBox.splice(result.source.index, 1);
    newBox.splice(result.destination.index, 0, draggedItem);
    setItems(newBox);

    const reorderedArray = newBox.map((item) => item.id);
    saveToLocalStorage("inboxRightDragOrder", reorderedArray);
  }

  return (
    <div>
      <Box className={classes.boxClass}>
          <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="boxes">
        {(provided) => (
          <Box
            sx={{ display: "flex", flexDirection: "column", gap: 20 }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {items.map(({ id, component }, index) => (
              <Draggable key={id} draggableId={id.toString()} index={index}>
                {(provided, snapshot) => (
                  <Box
                    sx={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                    ref={provided.innerRef}
                    {...provided.dragHandleProps}
                    {...provided.draggableProps}
                  >
                    <div className="lsv2DraggableItem">{component}</div>
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
      </Box>
    
    </div>
    
  );
};

export default DraggableSection;

import { Box } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import React from "react"

export const RightSideSkeletonV2 = () => {
  return (
    <Box sx={{ m: 1 }}>
      <Box sx={{ border: "1px solid #DFDFDF", p: 1 }}>
        <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
          <Skeleton variant="circle" width={48} height={48} />
        </Box>
        <Box sx={{ display: "flex", gap: "5px", justifyContent: "center" }}>
          <Skeleton variant="text" width={150} height={30} />

          <Skeleton variant="circle" width={30} height={30} />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Skeleton variant="text" width={300} height={30} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            borderRadius: "5px",
          }}
        >
          <Skeleton variant="text" width={110} height={30} />
        </Box>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Skeleton variant="rect" width={100} height={20} />
          <Skeleton variant="text" width={25} height={25} />
        </Box>
        <Skeleton variant="text" width={200} height={3} />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 1,
          border: "1px solid #DFDFDF",
          mt: 1,
        }}
      >
        <Box>
          <Skeleton variant="text" width={130} height={20} />
          <Skeleton variant="text" width={150} height={20} />
        </Box>
        <Box>
          <Skeleton
            variant="rect"
            width={80}
            height={20}
            sx={{ borderRadius: "5px" }}
          />
        </Box>
      </Box>

      {/* Email starts from here */}

      <Box sx={{ mt: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
          <Skeleton variant="rect" width={100} height={20} />
          <Skeleton variant="text" width={25} height={25} />
        </Box>
        <Box sx={{ mb: 1 }}>
          <Skeleton variant="text" width={200} height={3} />
        </Box>
        <Box
          sx={{
            mb: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ border: "1px solid #DFDFDF", padding: "5px" }}>
            <Skeleton variant="text" width={350} height={30} />
          </Box>

          <Skeleton variant="text" width={25} height={25} />
        </Box>
        <Box
          sx={{
            mb: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ border: "1px solid #DFDFDF", padding: "5px" }}>
            <Skeleton variant="text" width={350} height={30} />
          </Box>
          <Skeleton variant="text" width={25} height={25} />
        </Box>
      </Box>

      {/* Phone Number starts here */}

      <Box mt={2}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Skeleton variant="rect" width={100} height={20} />
          </Box>
          <Box sx={{ display: "flex", gap: "5px", mb: 1 }}>
            <Skeleton
              variant="text"
              width={75}
              height={25}
              sx={{ borderRadius: "10px" }}
            />
            <Skeleton
              variant="text"
              width={75}
              height={25}
              sx={{ borderRadius: "10px" }}
            />
            <Skeleton variant="text" width={25} height={25} />
          </Box>
        </Box>
        <Box>
          <Skeleton variant="text" width={200} height={3} />
        </Box>
        <Box sx={{ display: "flex", gap: "10px", alignItems: "center", mt: 1 }}>
          <Box sx={{ borderRadius: "10px" }}>
            <Skeleton variant="rect" width={130} height={27} />
          </Box>
          <Box sx={{ border: "1px solid #DFDFDF", p: 1 }}>
            <Skeleton variant="rect" width={200} height={37} />
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "10px", alignItems: "center", mt: 1 }}>
          <Box sx={{ borderRadius: "10px" }}>
            <Skeleton variant="rect" width={130} height={27} />
          </Box>
          <Box sx={{ border: "1px solid #DFDFDF", p: 1 }}>
            <Skeleton variant="rect" width={200} height={37} />
          </Box>
        </Box>
      </Box>

      {/* Tags start here */}

      <Box mt={2}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
          <Skeleton variant="rect" width={100} height={20} />
          <Skeleton variant="text" width={25} height={25} />
        </Box>
        <Box>
          <Skeleton variant="text" width={200} height={3} />
        </Box>
        <Box
          mt={1}
          sx={{ display: "flex", justifyContent: "center", gap: "10px" }}
        >
          <Skeleton variant="text" width={180} height={24} />
          <Skeleton variant="text" width={180} height={24} />
        </Box>
      </Box>

      {/* Important Dates start here */}

      <Box mt={2}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
          <Skeleton variant="rect" width={100} height={20} />
          <Skeleton variant="text" width={25} height={25} />
        </Box>
        <Box>
          <Skeleton variant="text" width={200} height={3} />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
          <Box>
            <Skeleton variant="text" width={50} height={20} />
            <Box sx={{ border: "1px solid #DFDFDF", p: 1, mt: 1 }}>
              <Skeleton variant="rect" width={180} height={37} />
            </Box>
          </Box>
          <Box>
            <Skeleton width={50} height={20} />
            <Box sx={{ border: "1px solid #DFDFDF", p: 1, mt: 1 }}>
              <Skeleton variant="rect" width={180} height={37} />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Additional Info start here */}

      <Box mt={2}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
          <Skeleton variant="rect" width={100} height={20} />
          <Skeleton variant="text" width={25} height={25} />
        </Box>
        <Box>
          <Skeleton variant="text" width={200} height={3} />
        </Box>
        <Box sx={{ border: "1px solid #DFDFDF", mt: 1, p: 1 }}>
          <Skeleton variant="text" width={100} height={26} />
          <Skeleton variant="text" width={150} height={12} />
        </Box>

        <Box sx={{ border: "1px solid #DFDFDF", mt: 1, p: 1 }}>
          <Skeleton variant="text" width={100} height={26} />
          <Skeleton variant="text" width={150} height={12} />
        </Box>
      </Box>
    </Box>
  )
}

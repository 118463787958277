import React,{useState, useEffect} from 'react'
import CoreConstants from '../../constants/CoreConstants'
// import moment from 'moment';
import Utils from '../../helpers/Utils';
import {connect} from 'react-redux'
import { updateInboxUnreadContactInfo } from '../../actions/inboxAction';
import EventNoteIcon from '@material-ui/icons/EventNote';
import BootstrapTooltip from '../globals/BootstrapTooltip';
// import { markAsUnread } from '../../api/inboxApi';
import moment from 'moment';

const InboxThreadItem = (props)=>{
    // const [isRead, setIsRead] = useState(1);

    // useEffect(() => {
    //     if(props.storedUnreadContactId === props.thread.contact.id){
    //         setIsRead(0)
    //         props.updateInboxUnreadContact(0)
    //     }
    //     else if(props.selectedContact != null && props.selectedContact.id === props.thread.contact.id){
    //         setIsRead(1)
    //         props.updateInboxUnreadContact(0)
    //     }
    //     else{
    //         setIsRead(props.thread.is_read === CoreConstants.inboxThread.READ)
    //     }
    // },[]) // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     if(props.selectedContact !== null && props.selectedContact.id == props.thread.contact.id){
    //         setIsRead(1)
    //     }
    // }, [props.selectedContact])

    // useEffect(()=>{
    //     if(props.storedUnreadContactId === props.thread.contact.id){
    //         // setIsRead(0)
    //         props.updateThreadList(props.index, 0)

    //         // props.updateInboxUnreadContact(0)
    //         // let formData = new FormData();
    //         // formData.append('contact_id', props.thread.contact.id);
    //         // formData.append('read_status', 0);
    //         // markAsUnread(formData)
    //         // .then(response => {
    //         //     if(window.updateNotificationCount){
    //         //         window.updateNotificationCount()
    //         //     }
    //         // });
    //     }
    // },[props.storedUnreadContactId]) // eslint-disable-line react-hooks/exhaustive-deps



    // const formatThreadTime = (time) => {
    //     if (time) {
    //         return moment(time).fromNow(true);
    //     }

    //     return '';
    // }

    const formatThreadMessage = (thread) => {
        if (thread.content_type === CoreConstants.inboxThread.CONTENT_TYPE_EMAIL || thread.content_type === CoreConstants.inboxThread.CONTENT_TYPE_VIDEO_MAIL) {
            return thread.message_subject;
        } else {
            return thread.message;
        }

        // return message.length > 20 ? message.substr(0, 18)+' ...' : message;
    }

    const populateShortMessage = () => {
        if (props.thread.content_type === CoreConstants.inboxThread.CONTENT_TYPE_VOICE_MAIL) {
            return (<p className="thread-message-preview">You have a new voicemail</p>);
        } else if (props.thread.content_type === CoreConstants.inboxThread.CONTENT_TYPE_FORM_RESPONSE) {
            return (<p className="thread-message-preview">New Form submitted</p>)
        } else if (props.thread.content_type === CoreConstants.inboxThread.CONTENT_TYPE_SMART_FORM_RESPONSE) {
            return (<p className="thread-message-preview">New Smart Form submitted</p>)
        }else if (props.thread.content_type === CoreConstants.inboxThread.CONTENT_TYPE_INCOMING_VOICE_MAIL
            && props.thread.in_out === CoreConstants.inboxThread.INOUT_TYPE_IN) {
            // return (<p className="thread-message-preview">Incoming voicemail</p>);
            return (<p className="thread-message-preview">You have a new voicemail</p>);
        } else if (props.thread.content_type === CoreConstants.inboxThread.CONTENT_TYPE_INCOMING_VOICE_MAIL 
        && props.thread.in_out === CoreConstants.inboxThread.INOUT_TYPE_OUT) {
        return (<p className="thread-message-preview">Outgoing voicemail</p>);
    }
    else if(props.thread.content_type === CoreConstants.inboxThread.CONTENT_TYPE_CALL 
        && props.thread.in_out === CoreConstants.inboxThread.INOUT_TYPE_OUT){
            return (<p className="thread-message-preview">Outgoing call</p>);
    }
    else if(props.thread.content_type === CoreConstants.inboxThread.CONTENT_TYPE_CALL 
        && props.thread.in_out === CoreConstants.inboxThread.INOUT_TYPE_IN){
            if(props.thread.message === "Missed Call"){
                return (<p className="thread-message-preview">Missed call</p>);
            }
            return (<p className="thread-message-preview">Incoming call</p>);
    }
        else {
            return (<p className="thread-message-preview" dangerouslySetInnerHTML={{__html: formatThreadMessage(props.thread)}}/>)
        }
    }

    const formateDateTime = (minutes = 0) => {
        if(minutes === 0){
            return 'in a few seconds'
        }
        else if(minutes > 0 & minutes < 60){
            if(minutes === 1){
                return `in ${minutes} minute`
            }
            return `in ${minutes} minutes`
        }
        else if(minutes > 60){
            let hour  = Math.floor(minutes / 60);
            if(hour < 24){
                if(hour === 1){
                    return `in ${hour} hour`
                }
                return `in ${hour} hours`
            }
            else{
                let day = Math.floor(hour / 24)
                if(day < 31){
                    if(day === 1){
                        return `in ${day} day`
                    }
                    return `in ${day} days`
                }
                else{
                    let month = Math.floor(day / 30)
                    if(month === 1){
                        return `in ${month} month`
                    }
                    if(month < 12){
                        return `in ${month} months`
                    }
                    else{
                        let year  = Math.floor(month / 12)
                        if(year === 1){
                            return `in ${year} year`
                        }
                        return `in ${year} years`
                    }
                }
            }
        }
        else if(minutes === 60){
            return `in 1 hour`
        }
        return ''
    }

    const renderDateTimeHuman = () => {
        let dateTime = props.thread.updated_at
        if(props.thread.last_communication !== 'undefined'){
            dateTime = props.thread.last_communication
        } 
        if(dateTime !== 'undefined' && dateTime != null && dateTime !== ''){
            if(dateTime.length > 19){
                let threadTime = window.globalTimezoneConversionToDifferentTimezone(dateTime,'UTC','','ll h:mm A')
                let currentTime = window.globalTimezoneConversionToDifferentTimezone(new Date(),'UTC','','ll h:mm A')

                let date1 = new Date(threadTime);
                let date2 = new Date(currentTime);

                const diffTime = Math.abs(date2.getTime() - date1.getTime());

                let diffInMin = Math.ceil(diffTime / (1000 * 60));
                return formateDateTime(diffInMin)

                // const dif_date = moment(moment(threadTime), 'YYYY-MM-DD LTS').diff(moment(moment(currentTime), 'YYYY-MM-DD LTS'), 'minutes');
                // return formateDateTime(parseInt(Math.abs(dif_date)))
                // return moment.duration(dif_date).humanize()
            }else{
                return window.globalTimezoneConversionToDifferentTimezone(dateTime,'UTC','','from_now').replace('ago','')
            }
        }
        return '';
    }

    const renderDateTime = () => {
        let dateTime = props.thread.updated_at
        if(props.thread.last_communication !== 'undefined'){
            dateTime = props.thread.last_communication
        }
        if(dateTime !== 'undefined' && dateTime != null && dateTime !== ''){
            if(dateTime.length > 19){
                let formattedTime = moment(dateTime);
                return window.globalTimezoneConversionToDifferentTimezone(formattedTime,'UTC','','ll h:mm A')
            }else{
                return window.globalTimezoneConversionToDifferentTimezone(dateTime,'UTC','','ll h:mm A')
            }
        }
        return ''
    }

    let fullName = (props.thread.contact.first_name ? props.thread.contact.first_name : '') + ' ' + (props.thread.contact.last_name ? props.thread.contact.last_name : '');
    let contactDisplayName = Utils.getContactDisplayNameV3(fullName, props.thread.contact.number, props.thread.contact.email);

    return(
        <div
            className={`card-panel ${props.thread.is_read == 0 ? 'bg-active' : ''} 
            ${(props.selectedContact && props.selectedContact.id == props.thread.contact.id)? 'activeTab' :'' } 
            message-discussion box-shadow-none`} 
            key={props.index} onClick={(e)=>props.handleItemSelect(props.thread.contact, props.index)}
        >
            <div className="mb-0 valign-wrapper">
                <div className="col s2 m2 l1 xl2 user-short-name">
                    <span className="user-area">
                        {props.contentType === 12 ? <EventNoteIcon/> : <i className={`material-icons bg-icons ${props.contentType}`}>{props.contentType}</i>}
                    </span>
                </div>
                <div className="col s10 m10 l11 xl10 user-message pt-3">
                    <div className=" d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center fullNameWrapper">
                            <BootstrapTooltip title={`${contactDisplayName}`} arrow>
                            <span className="full-name truncate">{contactDisplayName}</span>
                            </BootstrapTooltip>
                            <span className={`new ${props.thread.is_read == 0 ? '' : 'd-none'}`}>new</span>
                            {/* {isRead == 0 && (
                                <span className="new">new</span>
                            )} */}
                        </div> 
                        <div className="hours-wrapper">
                            {/* <span className="hours">{window.globalTimezoneConversionToDifferentTimezone(props.thread.created_at,'UTC', '', 'MM/DD/yyyy hh:mm:ss A')}</span> */}
                            <span className="hours">{renderDateTime()}</span>
                        </div>
                    </div>
                    <div className="d-flex">
                    <span className="thread-message-time">
                        {/* {((props.thread.last_communication !== undefined && 
                            props.thread.last_communication !== null) ? 
                            props.thread.local_last_communication.for_human : 
                            props.thread.local_updated_at.for_human).replace('ago', '')} <span className="thread-message-time"> */}
                        {/* {props.thread.last_communication ?

                            window.globalTimezoneConversionToDifferentTimezone(
                                props.thread.last_communication,'UTC','','from_now').replace('ago','')

                            :
                            window.globalTimezoneConversionToDifferentTimezone(
                                props.thread.updated_at,'UTC','','from_now').replace('ago','')
                        } */}
                        {renderDateTimeHuman()}
                    </span>
                    <span className="inbox-vertical-separator">|</span>
                        { populateShortMessage() }
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        storedUnreadContactId: state.inboxReducer.updateUnreadContactId,
        selectedContact: state.inboxReducer.selectedContact,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateInboxUnreadContact: (params)=> dispatch(updateInboxUnreadContactInfo(params)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(InboxThreadItem);
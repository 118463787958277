import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Divider,
  Typography,
  makeStyles, IconButton,
} from '@material-ui/core'
import { BasicInput, RightSideV2Styles } from '../RightSideV2Styles'
import CustomModal from '../common/CustomModal/CustomModal'
import DateRangeIcon from '@material-ui/icons/DateRange'
import {updateImportantDates} from '../../../../api/contactApi'
import { connect } from 'react-redux'
import { setContactDetails } from '../../../../actions/contactAction'
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import PrimaryRoundedButton from "../common/button/PrimaryRoundedButton";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles({
  dialogContentWidth: {
    width: '520px',
    padding: '8px 30px'
  },
})

const EditImportantDatesModal = ({
  open,
  onClose,
  contact,
  setContactDetails,
}) => {
  const [dates, setDates] = useState({
    birth_date: '',
    anniversary_date: '',
  });
  const [cachedDates, setCachedDates] = useState({
    birth_date: '',
    anniversary_date: '',
  });

  const [additionalDates, setAdditionalDates] = useState([]);
  const [additionalDatesErrors, setAdditionalDatesErrors] = useState([]);
  const [cachedAdditionalDates, setCachedAdditionalDates] = useState([]);
  const [submitUpdating, setSubmitUpdating] = useState(false)
  const {
    flexCenter,
    alignJustifyCenter,
    primaryButton,
    closeButton,
    flexColumn,
    titleIconHover
  } = RightSideV2Styles()

  useEffect(() => {
    if (contact) {
      setDates({
        birth_date: contact.birth_date,
        anniversary_date: contact.anniversary_date,
      })

      setCachedDates({
        birth_date: contact.birth_date,
        anniversary_date: contact.anniversary_date,
      })

      if (contact.contact_additional_informations && contact.contact_additional_informations.important_dates){
        let importantDates = JSON.parse(contact.contact_additional_informations.important_dates);

        setAdditionalDates(importantDates);
        setCachedAdditionalDates(importantDates);
      }
    }
  }, [contact])

  const addInputField = () => {
    const tempAdditionalDates = [...additionalDates];
    let newObj = {
      type: "CUSTOM",
      title: "",
      value: ""
    };
    tempAdditionalDates.push(newObj);
    setAdditionalDates(tempAdditionalDates);
  };

  const removeInputField = (index) => {
    const tempAdditionalDates = [...additionalDates];
    tempAdditionalDates.splice(index, 1);
    setAdditionalDates(tempAdditionalDates);
  };

  const handleChangeInputField = (key, value, index) => {
    const tempAdditionalDates = [...additionalDates];
    tempAdditionalDates[index][key] = value;
    setAdditionalDates(tempAdditionalDates);

    if (additionalDatesErrors[index] && additionalDatesErrors[index][key]){
      const tempAdditionalDatesErrors = [...additionalDatesErrors];
      tempAdditionalDatesErrors[index][key] = "";
      setAdditionalDatesErrors(tempAdditionalDatesErrors);
    }
  };

  const handleDates = (name, value) => {
    setDates({
      ...dates,
      [name]: value,
    })
  }

  const validateFields = () => {
    let isValid = true;

    let additionalErrors = [];

    additionalDates.forEach((additionalDate)=>{
      let err = {
        title: "",
        value: ""
      };
      if (additionalDate.title !== null && additionalDate.title !== undefined && additionalDate.title.trim() === ""){
        err.title = "Date title is required";
        isValid = false;
      }

      if (additionalDate.value !== null && additionalDate.value !== undefined && additionalDate.value.trim() === ""){
        err.value = "Date is required";
        isValid = false;
      }

      additionalErrors.push(err);
    });
    setAdditionalDatesErrors(additionalErrors);
    return isValid;
  };

  const handleSubmit = async () => {
    if (submitUpdating || !validateFields()) return;

    setSubmitUpdating(true)

    let data = [];

    if ((cachedDates.birth_date !== dates.birth_date) || (cachedDates.birth_date && cachedDates.birth_date === '')){
      data.push({
        type: 'DEFAULT',
        title: 'birth_date',
        value: dates.birth_date
      });
    }

    if ((cachedDates.anniversary_date !== dates.anniversary_date) || (cachedDates.anniversary_date && cachedDates.anniversary_date === '')){
      data.push({
        type: 'DEFAULT',
        title: 'anniversary_date',
        value: dates.anniversary_date
      });
    }

    if (additionalDates[0]){
      data = [...data, ...additionalDates.map((additionalDate)=>({ ...additionalDate, type: "CUSTOM" }))];
    }

    if (!data[0] && !cachedAdditionalDates[0]){
      window.showNotification("SUCCESS", "Nothing to update");
      onClose();
      setSubmitUpdating(false);
      return;
    }

    const formData = {
      contactId: contact?.id,
      data,
    };

    try {
      const res = await updateImportantDates(formData)

      if (res.success) {
        window.showNotification(
          'SUCCESS',
          'Important dates updated successfully'
        )
        let oldValue = { ...contact };

        if ((cachedDates.birth_date !== dates.birth_date) || (cachedDates.birth_date && cachedDates.birth_date === '')){
          setCachedDates((prevState)=>({ ...prevState, birth_date: dates.birth_date }));
          oldValue.birth_date = dates.birth_date;
        }

        if ((cachedDates.anniversary_date !== dates.anniversary_date) || (cachedDates.anniversary_date && cachedDates.anniversary_date === '')){
          setCachedDates((prevState)=>({ ...prevState, anniversary_date: dates.anniversary_date }));
          oldValue.anniversary_date = dates.anniversary_date;
        }

        if (!oldValue.contact_additional_informations){
          oldValue.contact_additional_informations = {};
        }

        if (oldValue.contact_additional_informations){
          oldValue.contact_additional_informations.important_dates = JSON.stringify(additionalDates);
        }

        setContactDetails(oldValue)

        onClose()
      }
    } catch (error) {
      console.error('API Error:', error)
      if (window.showNotification !== undefined)
        window.showNotification('ERROR', 'Something went wrong!')
    } finally {
      setSubmitUpdating(false)
    }
  }
  const classes = useStyles()
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth={1200}
    >
      <CustomModal
        icon={<DateRangeIcon />}
        title={'Important Dates'}
        open={open}
        onClose={onClose}
      />
      <DialogContent className={classes.dialogContentWidth}>
        <Box minHeight={170}>
          <DialogContentText id='alert-dialog-description'>
            <Box className={flexCenter} sx={{ justifyContent: "flex-end", mt: 2 }}>
              <PrimaryRoundedButton startIcon={<AddIcon />} onClick={addInputField}>
                Add New
              </PrimaryRoundedButton>
            </Box>
            <Box
              className={flexCenter}
              sx={{ gap: 10, flexWrap: 'wrap', marginTop: '6px' }}
            >
              <Box className={flexColumn} sx={{ gap: 5, flex: 1 }}>
                <Typography variant='body1' color='textPrimary'>
                  Birth Date
                </Typography>
                <BasicInput
                  fullWidth
                  type='date'
                  value={dates.birth_date}
                  name='birth_date'
                  onChange={(e) => {
                    handleDates(e.target.name, e.target.value)
                  }}
                />
              </Box>

              <Box className={flexColumn} sx={{ gap: 5, flex: 1 }}>
                <Typography variant='body1' color='textPrimary'>
                  Anniversary Date
                </Typography>
                <BasicInput
                  fullWidth
                  type='date'
                  value={dates.anniversary_date}
                  name='anniversary_date'
                  onChange={(e) => {
                    handleDates(e.target.name, e.target.value)
                  }}
                />
              </Box>
            </Box>
            {
              additionalDates.map((additionalDate, index) => (
                  <Box className={flexCenter} sx={{ gap: 10, flexWrap: "wrap", marginTop: "10px", position: "relative" }} key={index}>
                    <Box className={flexColumn} sx={{ gap: 5, flex: 1 }}>
                      <BasicInput
                          fullWidth
                          type='text'
                          value={additionalDate.title}
                          placeholder={'Add a title here'}
                          onChange={(event)=>{ handleChangeInputField('title', event.target.value, index) }}
                      />
                      {
                          (additionalDatesErrors[index] && additionalDatesErrors[index].title) &&
                          <span style={{ fontSize: "11px", color: "#dc4a4a" }}>{additionalDatesErrors[index].title}</span>
                      }
                    </Box>

                    <Box className={flexColumn} sx={{ gap: 5, flex: 1 }}>
                      <BasicInput
                          fullWidth
                          type='date'
                          value={additionalDate.value}
                          onChange={(event)=>{ handleChangeInputField('value', event.target.value, index) }}
                      />
                      {
                          (additionalDatesErrors[index] && additionalDatesErrors[index].value) &&
                          <span style={{ fontSize: "11px", color: "#dc4a4a" }}>{additionalDatesErrors[index].value}</span>
                      }
                    </Box>
                    <IconButton
                        size='small'
                        className={titleIconHover}
                        onClick={() => {
                          removeInputField(index);
                        }}
                    >
                      <DeleteForeverIcon color='error' />
                    </IconButton>
                  </Box>
              ))
            }
          </DialogContentText>

          <Box mt={7}>
            <Divider light />

            <Box
              className={alignJustifyCenter}
              sx={{ gap: '10px' }}
              mt={2}
              pb={1}
            >
              <Button
                variant='contained'
                color='primary'
                disableElevation
                className={primaryButton}
                onClick={handleSubmit}
                disabled={submitUpdating}
              >
                Save
              </Button>
              <Button
                className={closeButton}
                variant='contained'
                color='secondary'
                disableElevation
                onClick={() => onClose(dates)}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
    
  )
}

const mapStateToProps = (state) => {
  return {
    contact: state.rightsideReducer.contact,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setContactDetails: (params) => dispatch(setContactDetails(params)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditImportantDatesModal)

import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import { CheckCircle as CheckCircleIcon } from "@material-ui/icons";
import React, { useState } from "react";
import { connect } from "react-redux";
import { updateMultipleData } from "../../../../actions/inboxAction";
import BootstrapTooltip from "../../../globals/BootstrapTooltip";
import { RightSideV2Styles } from "../RightSideV2Styles";
import SkipTrace from "../common/SkipTrace";
import CustomTitle from "../common/customTitle/CustomTitle";
import { getState } from "../helper/utils";
import EditAddressModal from "./EditAddressModal";
import Styles from "./address.module.css";
import Utils from  "../../../EmailSendModule/assets/Helper";

const useStyles = makeStyles({
  boxClass: {
    "&.MuiBox-root": {
      width: "100%",
    },
  },

  buttonClass: {
    "&.MuiButton-root	": {
      background: "#00ff91 !important",
      "&:hover, &:focus": {
        background: "#00ff91 !important",
      },
      color: "#0e0f3f",
    },
  },

  hoverDiv: {
    "&:hover": {
      "& $icon": {
        opacity: 1,
        transition: "width .35s",
        width: "42px",
        display: "flex",
      },
    },
  },
  icon: {
    opacity: 0,
    width: 0,
    display: "none",
  },
});

const Address = (props) => {
  const { contact } = props;

  const [open, setOpen] = useState(false);
  const [openSkipTrace, setOpenSkipTrace] = useState(false);

  const {
    flexCenterBetween,
    textDarkBlue,
    skiptraceButton,
    heighFit,
  } = RightSideV2Styles();

  const classes = useStyles();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const lastSkipTraceDate = (fDate) => {
    try {
      if (window.globalTimezoneConversionToDifferentTimezone && fDate) {
        fDate = window.globalTimezoneConversionToDifferentTimezone(
          fDate,
          "UTC",
          "",
          "MM-DD-YY"
        );

        return fDate;
      }

      return "Traced";
    } catch (err) {
      return "Traced";
    }
  };


  const addressContent = [
    contact?.city,
    getState(contact?.state) || contact?.state,
    contact?.zip,
    /* getState(contact?.state) || */
    // contact?.country,
  ]
    .filter(Boolean)
    .join(", ");

  let content = null;

  if (addressContent || contact?.address)
    content = (
      <Box
        sx={{
          borderRadius: "4px !important",
          border: "1px solid #ced4da !important",
          padding: "15px 6px 15px 15px",

          // overflow: "hidden",
        }}
      >
        <Box
          className={`${flexCenterBetween} ${classes.boxClass}`}
          sx={{
            alignItems: "normal !important",
            display: "flex",
            // flexWrap: 'wrap',
          }}
        >
          <Box
            sx={{
              height: "auto !important",
              maxWidth: "70%",
            }}
          >
            {contact?.address && (
              <BootstrapTooltip
                title={
                  contact?.address.length > 36 ? `${contact?.address}` : ""
                }
              >
                <Typography
                  variant="body1"
                  className={`${textDarkBlue} ${heighFit} ${Styles.addressContentWidth}`}
                >
                  {contact?.address}
                </Typography>
              </BootstrapTooltip>
            )}

            <BootstrapTooltip
              title={addressContent.length > 36 ? `${addressContent}` : ""}
            >
              <Typography
                variant="body1"
                className={`${textDarkBlue} ${heighFit} ${Styles.addressContentWidth}`}
              >
                {addressContent}
              </Typography>
            </BootstrapTooltip>
          </Box>
           {
            Utils.getAccountData('skipTrace') &&
             <Box sx={{ height: "auto !important" }}>
             {props.contact.contact_additional_informations &&
              props.contact.contact_additional_informations.skip_trace_status ===
              "TRACED" ? (
              <Button
                className={`${Styles.buttonStyle} ${Styles.tracedButton}`}
                variant="contained"
                color="primary"
                size="small"
                disableElevation
                onClick={() => {}}
                startIcon={<CheckCircleIcon />}
              >
                {lastSkipTraceDate(
                  props.contact.contact_additional_informations.skip_trace_date
                )}
              </Button>
            ) : (
              <Button
                className={`${skiptraceButton} ${classes.buttonClass}`}
                variant="contained"
                color="primary"
                size="small"
                disableElevation
                onClick={() => {
                  setOpenSkipTrace(true);
                }}
              >
                SkipTrace
              </Button>

            )}
          </Box>
          }
         
        </Box>
      </Box>
    );

  return (
    <div className={`${classes.hoverDiv} `}>
      <div style={{ gap: 10, marginBottom: 16 }}>
        <CustomTitle
          handleOpen={handleOpen}
          title={"ADDRESS"}
          iconHide={classes.icon}
        />
      </div>

      <div>{content}</div>
      {open && <EditAddressModal open={open} onClose={handleClose} />}
      {openSkipTrace && (
        <SkipTrace
          isOpen={openSkipTrace}
          onClose={() => {
            setOpenSkipTrace(false);
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.rightsideReducer.contact,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateMultipleData: (params) => dispatch(updateMultipleData(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Address);

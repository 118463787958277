import CustomPopupMenu from './CustomPopupMenu';
import './style.css'

const GlobalUserListDropdown = ({
    className,
    handleSelect,
    userList = [],
    selected = [],
    selectedId = []
}) => {
    const handleItemChange = (value, remove) => {
        handleSelect(value, remove)
    }

    return(
        <div className={`pypepro_global__custom__field__grouped__wraper ${className}`}>
            <CustomPopupMenu 
                list={userList}
                handleClick={(value, remove) => handleItemChange(value, remove)}
                isLoading={false}
                value={selected}
                selectedId={selectedId}
                placeholder={'Filter by lead owner'}
            />
        </div>
    );
}
export default GlobalUserListDropdown;
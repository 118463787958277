import React, { useEffect ,useState} from 'react';
import {connect} from 'react-redux'
import { changeContactSource, getSourceListDropdown } from '../../../api/contactApi';
import Utils from '../../../helpers/Utils';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import { getUserSourceList, setUserSourceList } from './redux/contactAction';
import { DropdownHelper } from './task/DropdownHelper';

// const hideStyle = {
//     visibility: 'hidden'
// }

const Source=(props)=>{

    const [sourceList, setSourceList]= useState(null)
    const [sourceName, setSourceName] = useState("")
    const [updateSource, setUpdateSource]= useState(false)
    const [sourceId, setSourceId]= useState('')
    useEffect(()=>{

        if(props.contact != null && props.contact.source !== undefined && props.contact.source != null){
            setSourceId(props.contact.source)
        }
        if(props.sourceList !== undefined && props.sourceList != null){
            setSourceList(props.sourceList)
            props.storeUserSourceList(props.sourceList)
            getSourceName(props.sourceList)
        }else if(props.storedSourceList != null){
            setSourceList(props.storedSourceList)
            getSourceName(props.storedSourceList)

        }
        // else if(props.contact != null){

        // }
        else{
            getSourceListDropdown()
        }
        
    },[])

    const getSourceListDropdown=async()=>{
        await props.fetchUserSourceList({}, (callback) => {
            setSourceList(callback)
            getSourceName(callback)
        })
    }

    const getSourceName=(list)=>{
        if( props.contact != null && props.contact.source !== undefined && props.contact.source != null){
            for(let i=0;i< list.length;i++){
                if(list[i].id === props.contact.source){
                    setSourceName(list[i].source_title)
                    break;
                }
            }
        }
            
        
    }

    // constructor() {
    //     super()

    //     this.state = {
    //         show: false,
    //         count: 0,
    //         isOpen: false,
    //         sources : [],
    //         source_id : 0,
    //     }
    // }

    // callBack = (params) => {
    //     this.setState({
    //         isOpen : false
    //     })

    //     this.props.reloadTopSection()
    // }

    // modalToggle = ()=> {
    //     this.setState({
    //         isOpen: !this.state.isOpen
    //     })
    // }

    // showInputBox = ()=> {
    //     this.setState({
    //         show: !this.state.show
    //     })
    // }

    // componentDidMount(){
    //     this.loadSources();
    //     this.setState({
    //         source_id : this.props.source_id
    //     });
    // }

    // handleChange = (e) => {

    //     var source_id = e.target.value;
    //     this.handleSubmit(source_id);
    //     this.setState({source_id:source_id});
    // };

    const updateSourceOfContact=(id)=>{
        setSourceId(id);
        changeContactSource({
            contact_id : props.contact.id,
            source_id : id
        })
        .then(res => {
            if(res.status === 200){
                window.showNotification("SUCCESS","Source Updated");
            }else{
                window.showNotification("ERROR",'Something was wrong, please try to reload the page.');
            }

        })
    }

    // handleSubmit = (source_id)  => {
    //     changeContactSource({
    //         contact_id : this.props.contact_id,
    //         source_id : source_id
    //     }).then(res => {
    //         if(res.status == 200){
    //             this.setState({
    //                 show:!this.state.show
    //             });
    //             Utils.showNotification('Contact Source Successfully Changed', 'success');
    //         }else{
    //             Utils.showNotification('Something was wrong, please try to reload the page.', 'error');
    //         }

    //     });
    // };

    // loadSources = (event)  => {
    //     /* console.log();
    //     event.preventDefault(); */

    //     getSourceListDropdown({}).then(res => {
    //         this.setState({sources:res.data});
    //     });
    // };


    // render() {

    //     let show = (this.state.show) ? "accordion-wrapper show" : "accordion-wrapper";

        return (
            // <div id="tab-head" className={show}>
            //     <div className="accordion-header d-flex align-items-center source-top-div">
            //         <div className="accordion-icon">
            //             <i className="flaticon-profile-1"></i>
            //         </div>
            //         <React.Fragment>
            //             {
            //                 !this.state.show ? (
            //                     <React.Fragment>
            //                         <div class="col sm6">
            //                             <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            //                                 <path d="M7.03125 0.114258C4.70503 0.114258 2.8125 2.01481 2.8125 4.35089C2.8125 6.68697 4.70503 8.58752 7.03125 8.58752C9.35747 8.58752 11.25 6.68697 11.25 4.35089C11.25 2.01481 9.35747 0.114258 7.03125 0.114258Z" fill="white"></path>
            //                                 <path d="M12.2802 11.3551C11.1252 10.1774 9.59403 9.52881 7.96875 9.52881H6.09375C4.4685 9.52881 2.93731 10.1774 1.78231 11.3551C0.632969 12.5271 0 14.074 0 15.7112C0 15.9711 0.209875 16.1819 0.46875 16.1819H13.5938C13.8526 16.1819 14.0625 15.9711 14.0625 15.7112C14.0625 14.074 13.4295 12.5271 12.2802 11.3551Z" fill="white"></path>
            //                             </svg>
    
            //                             <span>Source</span>
            //                         </div>
            //                         <div class="col sm6">
            //                             <input type="text" onClick={this.showInputBox} value={this.state.sources[this.state.source_id]}/>
            //                         </div>
            //                     </React.Fragment>
            //                 ) : (
            //                     <div class="col sm12">
            //                         <React.Fragment>
            //                             <Select
            //                                 labelId="demo-simple-select-label"
            //                                 id="demo-simple-select"
            //                                 value={this.state.source_id}
            //                                 className="change-owner-select"
            //                                 onChange={this.handleChange}
            //                             >
            //                                 {Object.keys(this.state.sources).map((x,y) => <MenuItem key={x} value={x}>{this.state.sources[x]}</MenuItem>)}
            //                             </Select>
            //                             <CloseIcon onClick={this.showInputBox} style={{ color: 'white' }}/>
            //                         </React.Fragment>
            //                     </div>
            //                 )
            //             }
            //         </React.Fragment>
            //     </div>
            // </div>
            <div class="d-flex align-items-center p-2 bg-light-blue-10 mb-3 collapsible_last_item">
            <div class="ml-2 d-flex align-items-center collaborat__footer__single__item">
               <span>
                <svg width="25" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.4529 10.5947L15.1719 16.8042C15.0114 17.113 14.5564 17.4395 14.205 17.4395L1.04762 17.4419C0.769658 17.4419 0.503156 17.3318 0.306748 17.1352C0.11034 16.9386 0 16.6724 0 16.3943L0.00212976 6.17746C0.00212976 5.59967 0.47052 5.13084 1.04837 5.13028L2.01037 5.12937V6.02186H1.36677C1.2408 6.02186 1.11982 6.07185 1.03068 6.16099C0.941636 6.25012 0.891555 6.37102 0.891555 6.49708L0.892495 16.0746C0.892495 16.3373 1.10538 16.5498 1.36781 16.5498H2.02694L5.13638 10.2596C5.31206 9.90827 5.64402 9.62429 5.9949 9.62429H13.61L13.6126 7.09291C14.1099 7.13902 14.5023 7.55147 14.5023 8.06048V9.62432H17.9949C18.4074 9.62855 18.7547 10.0364 18.4529 10.5947ZM2.61704 13.3834C2.58863 10.2524 2.61704 0.859076 2.61704 0.859076C2.61704 0.385142 3.00246 0 3.47592 0H10.3542C10.4644 0 10.5695 0.0461031 10.644 0.127191L12.8677 2.54325C12.9343 2.616 12.9717 2.71125 12.9717 2.80994V9.06889H12.2503V3.35039C12.2503 3.25145 12.1699 3.17109 12.0709 3.17109H10.3689C10.1697 3.17109 10.0077 3.00938 10.0077 2.81056V0.900826C10.0077 0.801855 9.92734 0.721519 9.82828 0.721519H3.47636C3.40047 0.721519 3.33871 0.78325 3.33871 0.859076V12.5959L2.78667 13.7128C2.7867 13.7126 2.61989 13.7004 2.61704 13.3834ZM10.7299 2.44994H11.8014L10.7299 1.28522V2.44994ZM11.1206 3.90585H4.4729C4.22798 3.90585 4.02916 4.10486 4.02916 4.34978C4.02916 4.59452 4.22817 4.79362 4.4729 4.79362H11.1208C11.3657 4.79362 11.5652 4.59436 11.5652 4.34978C11.5648 4.10505 11.3654 3.90585 11.1206 3.90585ZM11.5648 6.93478C11.5648 6.69004 11.3656 6.49123 11.1206 6.49123H4.4729C4.22798 6.49123 4.02916 6.69042 4.02916 6.93478C4.02916 7.17951 4.22817 7.37833 4.4729 7.37833H11.1208C11.3654 7.37836 11.5648 7.17951 11.5648 6.93478ZM4.02888 9.53728C4.02888 9.78199 4.22798 9.98084 4.47271 9.98084H4.6321C4.91267 9.19887 5.60816 9.09373 5.60816 9.09373H4.47271C4.22836 9.09373 4.02888 9.29255 4.02888 9.53728Z" fill="#546376"></path>
                    </svg>
               </span>
                <h5 class="text-dark-blue m-0">Lead Source</h5>
            </div>
          
            {/* <span class="text-light-blue ml-4">{sourceName !== "" ? sourceName : ""}</span> */}
            {/* {!updateSource &&
            <div class="col sm6">
                <input type="text" onClick={()=>setUpdateSource(true)} value={sourceName !== "" ? sourceName : ""}/>
            </div>
            } */}
            {/* {updateSource && */}
            {sourceList != null &&
            <DropdownHelper
                datalist={sourceList}
                noneFieldRequired={true}
                noneFieldTitle="Update Source"
                className="second__step__area__select"
                mapping={{ title: "source_title" }}
                menuItemClassName="m-inbox-lead-source-menu-item"
                updateSelectedData={updateSourceOfContact}
                selectedValue={sourceId}
                updateDatatable={()=>console.log('update')}
                dropdownHeader={'ownerChange'}
                parentDivClassName="lead-source-rightside"
            />
            }
        </div>
        );

    // }
}

const mapStateToProps = state => {
    return {
        contact: state.rightsideReducer.contact,
        storedSourceList: state.rightsideReducer.sourceList,
        
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchUserSourceList: (params, callback) => dispatch(getUserSourceList(params, callback)),
        storeUserSourceList: (params, callback) => dispatch(setUserSourceList(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Source);


import {getCookie, getCookieLogin} from "./Cookie";
import CoreConstants from '../constants/CoreConstants';

/**
 * Handle error exception
 * 
 * @param Error error 
 * @param Function/null callback 
 */
const handleException = (error, callback = null) => {
    alert(error);

    // to do
}

/**
 * Get contact's display name
 * 
 * @param string name 
 * @param string number 
 * @param string email 
 * @returns string
 */
const getContactDisplayName = (name, number, email) => {
    if (name === '' || name === ' ' || name === null || name === 'null null') {
        if (number === '' || number === ' ') {
            if (email === '' || email === ' ') {
                return '[No Name]';
            } else {
                // return email.length > 15 ? email.substr(0, 13)+' ...' : email;
                return email;
            }
        } else {
            return number;
        }
    }

    // return name.length > 13 ? name.substr(0, 10)+' ...' : name;
    return name;
}

const getContactDisplayNameV2 = (item) => {
    if(!isEmpty(item.first_name) && !isEmpty(item.last_name)){
        return item.first_name + ' ' + item.last_name;
    }else if(!isEmpty(item.first_name)){
        return item.first_name
    }
    else if(!isEmpty(item.last_name)){
        return item.last_name
    }
    else if(!isEmpty(item.email)){
        return item.email
    }else if(!isEmpty(item.number)){
        return item.number
    }
    return "";
}

const getContactDisplayNameV3 = ( fullName, number, email ) => {
    if(!isEmpty(fullName)){
        return fullName
    } else if(!isEmpty(number)){
        return number
    } else if(!isEmpty(email)){
        return email
    }
    return "";
}

const isEmpty = (data) => {
    if(data !== undefined && data != null && data.trim() !== ""){
        return false
    }
    return true;
}

/**
 * Display notification
 * 
 * @param string message 
 * @param string type 
 * @param string/null title 
 * @param boolean sticky 
 */
const showNotification = (message = 'Something went wrong', type = 'error') => {
    window.showNotification(type.toUpperCase(), message);
};

/**
 * Get initial of name
 * 
 * @param string name 
 * @returns string
 */
const getInitial = (name) => {
    try {
        if (typeof name != 'undefined') {
            let fullNameInitial = 'X'
            if(name == 'null null' || name == '' || name == ' '){
                fullNameInitial = 'UN';
            }else if(name !== 'undefined' || name !== null){
                fullNameInitial = name.split(" ").map((n,i,a)=> i === 0 || i+1 === a.length ? n[0] : null).join("");
            }
            return fullNameInitial;
        } else {
            return 'UN';
        }
    } catch (error) {
        return 'UN';
    }
}

/**
 * Get type of message
 *
 * @param string name
 * @returns string
 */
const getMessageType = (type) => {
    switch (type) {
        case CoreConstants.Timeline.CONTENT_TYPE_SMS:
            return "SMS"
        case CoreConstants.Timeline.CONTENT_TYPE_MMS:
            return "MMS"
        case CoreConstants.Timeline.CONTENT_TYPE_VOICE:
            return "VOICE"
        case CoreConstants.Timeline.CONTENT_TYPE_EMAIL:
            return "EMAIL"
        case CoreConstants.Timeline.CONTENT_TYPE_CALL:
            return "CALL"
        case CoreConstants.Timeline.CONTENT_TYPE_ACTIVITY:
            return "ACTIVITY"
        case CoreConstants.Timeline.CONTENT_TYPE_OUTGOING_CALL:
            return "OUTGOING CALL"
        case CoreConstants.Timeline.CONTENT_TYPE_WEBHOOK:
            return "WEBHOOK"
        case CoreConstants.Timeline.CONTENT_TYPE_STAGE:
            return "STAGE"
        case CoreConstants.Timeline.CONTENT_TYPE_APPOINTMENT:
            return "APPOINTMENT"
        case CoreConstants.Timeline.CONTENT_TYPE_CALL_BRIGDE:
            return "CALL BRIGDE"
        case CoreConstants.Timeline.CONTENT_TYPE_VIDEO_EMAIL:
            return "VIDEO EMAIL"
        case CoreConstants.Timeline.CONTENT_TYPE_CHAT:
            return "CHAT"
        case CoreConstants.Timeline.CONTENT_TYPE_NOTE:
            return "NOTE"
        case CoreConstants.Timeline.CONTENT_TYPE_GENERAL:
            return "GENERAL"
        case CoreConstants.Timeline.CONTENT_TYPE_POST_CARD:
            return "POST_CARD"
        case CoreConstants.Timeline.CONTENT_TYPE_GREETINGS_CARD:
            return "GREETINGS CARD"
        case CoreConstants.Timeline.CONTENT_TYPE_GIFT:
            return "GIFT"
        case CoreConstants.Timeline.CONTENT_TYPE_LETTER:
            return "LETTER"
        case CoreConstants.Timeline.CONTENT_TYPE_CALL_RECORD:
            return "CALL RECORD"
        default:
            return ""
    }
}

/**
 * Function name: getAccountData
 * This function will provide
 * AuthUserAgencyName
 * packageType
 * packageType
 * starterPack
 * packageType
 * starterProPack
 * packageType
 * standardPack
 * packageType
 * starterPack
 * starterProPack
 * standardPack
 * baseUrl
 * userIsAgent
 * userIsOwner
 * userIsAdmin
 * button_text_transform_class
 * userTimezone
 * userTimezoneRaw
 * userId
 * agencyId
 * canvaApiKey
 * fullName
 * email
 * agencyLogo
 * profileImage
 * layoutSettings
 * note_edit_access
 * carrierLookup
 */
export const getAccountData = (needle) => {
    // let accountData = getCookie(process.env.REACT_APP_ACCOUNT_DATA);
    let accountData = null;
    let check_new = getCookie("encode__process__new")
    if(check_new !== undefined){
        accountData = getCookieLogin(process.env.REACT_APP_ACCOUNT_DATA);
    }else{
        accountData = getCookie(process.env.REACT_APP_ACCOUNT_DATA);
    }
    if (accountData) {
        accountData = JSON.parse(accountData);
        return accountData[needle];
    }
    return false;
}

export const formatNumber = (number) => {
    if (number) {
        var cleaned = ('' + number).replace(/\D/g, '')
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            var intlCode = (match[1] ? '+1 ' : '')
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
        }
        return number.replace(/[. \-+()]/g, '');
    } else {
        return number;
    }
}

const isJsonString = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

const countSms = (message) => {
    var charset7bit = ['£', '@', 'Â£', '$', 'Â¥', 'Ã¨', 'Ã©', 'Ã¹', 'Ã¬', 'Ã²', 'Ã‡', "\n", 'Ã˜', 'Ã¸', "\r", 'Ã…', 'Ã¥', 'Î”', '_', 'Î¦', 'Î“', 'Î›', 'Î©', 'Î ', 'Î¨', 'Î£', 'Î˜', 'Îž', 'Ã†', 'Ã¦', 'ÃŸ', 'Ã‰', ' ', '!', '"', '#', 'Â¤', '%', '&', "'", '(', ')', '*', '+', ',', '-', '.', '/', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':', ';', '<', '=', '>', '?', 'Â¡', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'Ã„', 'Ã–', 'Ã‘', 'Ãœ', 'Â§', 'Â¿', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'Ã¤', 'Ã¶', 'Ã±', 'Ã¼', 'Ã '];
    var charset7bitext = ["\f", '^', '{', '}', '\\', '[', '~', ']', '|', 'â‚¬'];
    var chars_arr = message.split("");
    var coding = '7bit';
    var parts = 1;
    var part = 1;
    var chars_used = 0;
    var chars_sms = 160;
    var charsLength = chars_arr.length;
    for (let i = 0; i < charsLength; i++) {
        if (charset7bit.indexOf(chars_arr[i]) >= 0) {
            chars_used = chars_used + 1;
        } else if (charset7bitext.indexOf(chars_arr[i]) >= 0) {
            chars_used = chars_used + 2;
        } else {
            coding = '16bit';
            chars_used = charsLength;
            break;
        }
    }    
    if (coding == '7bit') {
        if (chars_used > 160) {
            var parts = Math.ceil(chars_used / 153);
            var part_chars_used = 7;
            chars_sms = 153;
            for (let i = 0; i < charsLength; i++) {
                if (part_chars_used + 1 > 160) {
                    part = part + 1;
                    part_chars_used = 7;
                }
                if (charset7bitext.indexOf(chars_arr[i]) >= 0 && part_chars_used + 2 > 160) {
                    part = part + 1;
                    part_chars_used = 7;
                }
                if (charset7bitext.indexOf(chars_arr[i]) == -1) {
                    part_chars_used = part_chars_used + 1;
                } else {
                    part_chars_used = part_chars_used + 2;
                }
            }
        }
        else {
            chars_sms = 160;      
        }
    }
    else {
        if (chars_used > 70) {
            var parts = Math.ceil(chars_used / 67);
            var part_chars_used = 3;
            chars_sms = 67;
            for (let i = 0; i < charsLength; i++) {
                if (part_chars_used + 1 > 70) {
                    part = part + 1;
                    part_chars_used = 3;
                }
                part_chars_used = part_chars_used + 1;
            }
        }
        else {
            chars_sms = 70; 
        }
    }
    return {
        sms: parts,
        length: chars_used,
        chars_per_sms: chars_sms,
    };
}

const urlify = (text) => {
    try {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
    }catch (err){
        return text;
    }
}

const validateEmail = (email) => {
    // eslint-disable-next-line no-useless-escape
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

const validateMobileNumber = (mobileNumber) => {

    if(mobileNumber !== '' && mobileNumber.length < 10){
        return false;
    } else if (mobileNumber !== '' && mobileNumber.length > 11){
        return false
    } else if(mobileNumber.length === 11 && mobileNumber.substring(0,1) !== '1'){
        return false;
    }
    return true
}

const validURL = (string) => {
    try{
        const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        return regexp.test(string);
    }catch (err){
        return false;
    }
}

/**
 * Utility helper
 */
const Utils = {
    handleException,
    getContactDisplayName,
    getContactDisplayNameV2,
    getContactDisplayNameV3,
    getInitial,
    showNotification,
    getAccountData,
    getMessageType,
    formatNumber,
    isJsonString,
    isEmpty,
    countSms,
    urlify,
    validateEmail,
    validateMobileNumber,
    validURL
}

export default Utils;
const rootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/inbox`
const marketingUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/marketing`
const contactUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/contacts`
const backendeUrlV1 = `${process.env.REACT_APP_JAVAEND_URL}/v1`
const formApi17 = `${process.env.REACT_APP_FORM_API_17}/v1`
const carrierLookupBackendApi = `${process.env.REACT_APP_CARRIER_LOOKUP_BACKEND_SERVER}/v1`
const containerUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/container`
const tenantBackendV1 = `${process.env.REACT_APP_PYPEPRO_TENANT_BACKEND_SERVER}/api/v1`
const dealUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/deal`

const Api = {
  contacts: `${rootUrl}/contacts`,
  subUsers: `${rootUrl}/sub-users`,
  contactsSearch: `${rootUrl}/search-from-all-contact`,
  // conversations: `${rootUrl}/contact-conversation`,
  conversations: `${rootUrl}/contact-conversation-v2`,
  virtualNumbers: `${rootUrl}/virtual-number-list`,
  customFields: `${rootUrl}/get-custom-fields`,
  emails: `${rootUrl}/user-email-list`,
  sendMessage: `${rootUrl}/make-conversation`,
  sendBulkMessage: `${contactUrl}/send-message`,
  addNote: `${rootUrl}/add-note`,
  markAsImportant: `${rootUrl}/mark-as-important`,
  markAsUnread: `${rootUrl}/mark-as-unread`,
  deleteContact: `${rootUrl}/contact/delete`,
  archiveContact: `${rootUrl}/contact/archive`,
  moveFromArchive: `${rootUrl}/contact/move-to-inbox`,
  exportConversation: `${rootUrl}/conversation/export`,
  getMessageTemplate: `${marketingUrl}/message-templates`,
  userEmailList: `${contactUrl}/user-email-list`,

  //card Broadcast
  getMailCategory: `${rootUrl}/get-card-basic-setting`,
  getMailTemplate: `${rootUrl}/get-card-templates`,
  createMailTemplate: `${rootUrl}/card-template-create`,
  producePDF: `${rootUrl}/generate-pdf`,
  addCardBroadcastToContact: `${rootUrl}/add-card-broadcast-to-contact`,
  getThirdPartyInfo: `${rootUrl}/get-third-party-info`,
  getCustomFields: `${rootUrl}/get-custom-fields`,
  uploadEmailFile: `${contactUrl}/upload-email-file`,

  searchElasticInboxThread: `${backendeUrlV1}/inbox/search-elastic-inbox`,
  formBuilderAWSPreSignedUrl: process.env.REACT_APP_FORM_BUILDER_S3_PRESIGNED,
  getContactSmartFormEntries: `${formApi17}/forms/entries`,
  getContactVerificationStats: `${contactUrl}/carrier-lookup/get-verification-states`,
  getCarrierLookupCreditSettings: `${contactUrl}/carrier-lookup/get-credit-settings`,
  carrierLookupVerifyContact: `${carrierLookupBackendApi}/carrier/lookup/single`,
  getUserCampaignRegistrationStatus: `${containerUrl}/get-user-campaign-registration-status`,
  updateNumberDncStatus: `${tenantBackendV1}/contacts/dnc/update-number-dnc-status`,
  getContactLogs: `${tenantBackendV1}/contacts/get-contact-logs`,
  updateContactDetailsV2: `${contactUrl}/update-contact-details-v2`,
  createAdditionalContactApi: `${contactUrl}/additional-contacts/create`,
  getAllAdditionalContactApi: `${contactUrl}/additional-contacts`,
  updateAdditionalContactApi: `${contactUrl}/additional-contacts/update`,
  deleteAdditionalContactApi: `${contactUrl}/additional-contacts/delete`,
  makeDefaultContact: `${contactUrl}/additional-contacts/make-default`,
  carrierLookupCleanData: `${carrierLookupBackendApi}/carrier/lookup/single/v1`,
  updateContactCustomFieldV2: `${contactUrl}/update-contact-custom-field-v2`,

  deleteAdditionalContactNumberApi: `${contactUrl}/additional-contacts/number/delete`,
  deleteAdditionalContactEmailApi: `${contactUrl}/additional-contacts/email/delete`,

  makeDefaultAdditionalContactNumberApi: `${contactUrl}/additional-contacts/number/make-default`,
  makeDefaultAdditionalContactEmailApi: `${contactUrl}/additional-contacts/email/make-default`,
  getZipCodeByPlace: `${contactUrl}/get-google-map-resource`,
  updateContactLeadTypeApi: `${contactUrl}/update-contact-lead-type`,
  tagListNew: `${contactUrl}/tag/list-optimized-new-api`,
  addTagsV2: `${contactUrl}/add-tags-v2`,

  deleteContactNumberApi:`${contactUrl}/number/delete`,
  deleteContactEmailApi:`${contactUrl}/email/delete`,

  updateImportantDates: `${contactUrl}/update-important-dates`,
  updateImportantLinks: `${contactUrl}/update-important-links`,
  getUserProductList : `${dealUrl}/user-products/list`,
  getContactStagesProduct: `${contactUrl}/get-contact-stages-products`,
  deleteContactPolicyApi:`${tenantBackendV1}/contact/contact-policy`,
  updateContactPolicyStatusApi:`${tenantBackendV1}/contact/contact-policy/update-status`,
  getContactPolicyCategoryApi:`${tenantBackendV1}/contact/get-policy-category`,
  addContactPolicyApi:`${dealUrl}/user-policies`,
  updateContactPolicyApi:`${tenantBackendV1}/contact/contact-policy`,
  createUserPolicyApi:`${tenantBackendV1}/contact/contact-policy`,
  getPolicyData:`${dealUrl}/user-policies/list`,
  getUserProductActiveList : `${dealUrl}/user-products/active-list`
}

export const contactApi = {
  details: `${contactUrl}/details`,
  changeLeadOwner: `${contactUrl}/change/lead-owner`,
  getAllTeamUsers: `${contactUrl}/get-all-team-users`,
  getSearchContacts: `${contactUrl}/search-contacts`,
  getCollaborators: `${contactUrl}/get-collaborators`,
  deleteCollaborator: `${contactUrl}/delete-collaborators`,
  saveCollaborator: `${contactUrl}/save-collaborator`,
  getUserGroup: `${contactUrl}/get-user-group`,
  removeFile: `${contactUrl}/file/remove`,
  uploadFile: `${contactUrl}/upload/file`,
  getTimelineLogs: `${contactUrl}/get/timeline-log`,
  emailContactToFriend: `${contactUrl}/email-contact-to-friend`,
  getGoogleMapResource: `${contactUrl}/get-google-map-resource`,
  editContactData: `${contactUrl}/edit-contact-data`,

  changeDealStatus: `${contactUrl}/status-change-deals`,

  addNewDeal: `${contactUrl}/add-new-deal`,
  updateDeal: `${contactUrl}/update-contact-deal`,
  stageList: `${contactUrl}/stage-list`,
  campaignList: `${contactUrl}/campaign/list/contact`,
  runCampaign: `${contactUrl}/campaign/single-contact/run`,
  pauseCampaign: `${contactUrl}/campaign/single-contact/pause`,
  unsubscribeCampaign: `${contactUrl}/campaign/single-contact/unsubscribe`,
  moveToCampaign: `${contactUrl}/campaign/contact/move`,
  updateSingleCustomField: `${contactUrl}/single/custom-field/update`,
  addTags: `${contactUrl}/add-tags`,
  tagList: `${contactUrl}/tag/list`,
  smsTemplate: `${contactUrl}/get/sms/template`,
  emailTemplate: `${contactUrl}/get/email/template`,
  getTimeline: `${contactUrl}/get/timeline`,
  updateTimelineNote: `${contactUrl}/update-timeline-note`,
  voiceTemplate: `${contactUrl}/get/voice/template`,
  userProfile: `${contactUrl}/get/user-profile`,
  sendMail: `${contactUrl}/send-mail`,
  userEmailList: `${contactUrl}/user-email-list`,
  sendMessage: `${contactUrl}/send-message`,
  getCustomFields: `${contactUrl}/get-custom-fields`,
  getRunningCampaignListNotInCurrentCampaign: `${contactUrl}/campaign/not-in-current-contact/list`,
  changeContactSource: `${contactUrl}/contact-source-type/change/source`,
  sourceListDropdown: `${contactUrl}/contact-source-type/list/dropdown`,
  updateContactInfo: `${contactUrl}/update-contact-info`,
  updateAditionalEmailPhone: `${contactUrl}/update-aditional-email-phone`,
  getContactTagList: `${contactUrl}/get-contact-tag-list`,
  deleteContactTag: `${contactUrl}/delete-contact-tag`,
  getMailCategory: `${contactUrl}/get-card-basic-setting`,
  getMailTemplate: `${contactUrl}/get-card-templates`,
  createMailTemplate: `${contactUrl}/card-template-create`,
  getUserVirtualNumberList: `${contactUrl}/virtual-number-list`,
  addNote: `${contactUrl}/add-note-submit`,
  producePDF: `${contactUrl}/generate-pdf`,
  addCardBroadcastToContact: `${contactUrl}/add-card-broadcast-to-contact`,
  getThirdPartyInfo: `${contactUrl}/get-third-party-info`,
  getContactTimeline: `${contactUrl}/contact-conversation`,

  getActivityList: `${contactUrl}/activity-list`,
  getActivityListV2: `${contactUrl}/activity-list-contact-details`,
  contactMarkAsDone: `${contactUrl}/activity-mark-as-done`,
  saveActivity: `${contactUrl}/save-activity`,
  //Get All Contacts
  getAllContacts: `${contactUrl}/contact-datatable`,
  getCampaignListForDropdown: `${contactUrl}/campaign-list-dropdown`,
  sourceListDropdownForContactTable: `${contactUrl}/source-list-dropdown`,
  subUserListForContactDropdown: `${contactUrl}/sub-users`,
  fileListForDropdown: `${contactUrl}/file-list-dropdown`,
 

  //remove contact
  removeBulkContact: `${contactUrl}/bulk-contact-delete`,

  //List
  getAllList: `${contactUrl}/contact-list-dropdown`,

  //single contact
  addContactInCampaign: `${contactUrl}/contact/move/campaign`,

  //multiple contacts
  addContactsInCampaign: `${contactUrl}/add-contacts-to-campaign`,

  //GetUserInfo
  getUserInfo: `${contactUrl}/get-user-data`,

  /* note delete */
  deleteNote: `${rootUrl}/delete-note`,
  getContactSmartFormEntries: `${formApi17}/forms/entries`,
  formBuilderAWSPreSignedUrl: process.env.REACT_APP_FORM_BUILDER_S3_PRESIGNED,
}

export default Api

import React, { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { formatDateWithoutZone } from '../../../globals/TimezoneConversion'
import { Link } from 'react-router-dom'
import { RightSideV2Styles } from '../RightSideV2Styles'

const EachCustomField = (props) => {
  const [value, setValue] = useState('')
  const { darkBlue } = RightSideV2Styles()

  useEffect(() => {
    try {
      if (
        props.value !== undefined &&
        props.value != null &&
        Array.isArray(props.value) &&
        props.value[0]
      ) {
        for (let j = 0; j < props.value.length; j++) {
          if (props.data.id === props.value[j].user_custom_field_id) {
            if (
              props.value[j].value !== null &&
              props.value[j].value !== undefined
            ) {
              let values = props.value[j].value
                .replace(/null,/g, '')
                .replace(/,null/g, '')
              setValue(values)
            } else {
              setValue('')
            }
          }
        }
      } else {
        setValue('')
      }
    } catch (e) {
      console.log(e)
    }
  }, [props.value])

  return (
    <Box sx={{ display: 'flex', gap: 5 }}>
      {value !== '' && (
        <>
          <Typography variant='subtitle2' className={darkBlue}>
            <b>{props.data.title}:</b>{' '}
          </Typography>
          <Typography variant='subtitle2' color='textSecondary'>
            {props.formType === 'date' ? (
              formatDateWithoutZone(value, 'MM/DD/YYYY')
            ) : props.formType !== 'website' ? (
              value
            ) : (
              <Link
                to={{ pathname: `//${value.replace(/^https?:\/\//, '')}` }}
                target='_blank'
              >
                {value}
              </Link>
            )}
          </Typography>
        </>
      )}
      {/* {
                value === '' ?
                <Typography variant="subtitle2" color="textSecondary">No {props.data.title} added</Typography> :
                <Typography variant="subtitle2" color="textSecondary">{props.formType === 'date' ? formatDateWithoutZone(value,'MM/DD/YYYY') : props.formType !== 'website' ? value : <Link to={{ pathname: `//${value.replace(/^https?:\/\//, '')}` }} target="_blank">{value}</Link>}</Typography>
            } */}
    </Box>
    
  )
}

export default EachCustomField

import {styled, Accordion} from "@material-ui/core";

const StyledAccordion = styled(Accordion)({
    "& .MuiAccordionSummary-root.Mui-expanded": {
        background: "#181F48 !important",
        minHeight: "48px !important"
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
        margin: "0 !important"
    }
});

export default StyledAccordion;
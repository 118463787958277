import { all, takeEvery, takeLatest, call, put } from 'redux-saga/effects';
import * as ACTION from './cardBroadcastActionTypes'
import { createMailTemplate, getMailCategory, saveFollowUp, updateEmailStepSettingApi } from './cardBroadcastApi';

const POSTCARD = 6;
const GREETING_CARD = 7;
const GIFT = 8;
const LETTER = 9;

function* getCardCategoryWatcher() {
    yield takeEvery(ACTION.GET_MAIL_CATEGORY_IN_CARD_BROADCAST, attemptGetMailCategory)
    yield takeEvery(ACTION.SAVE_FOLLOWUP_IN_CARD_BROADCAST, saveFollowUpFlow);
    yield takeEvery(ACTION.UPDATE_CAMPAIGN_EMAIL_STEP_SETTING_IN_CARD_BROADCAST, updateEmailStepSettingMiddleware);
}

function* updateEmailStepSettingMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(updateEmailStepSettingApi, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        if (payload.callback) payload.callback({'status': false, 'message' : 'Something went wrong! Try again.'});
        // Utils.handleException(error);
    }
}

function* saveFollowUpFlow(action){
    const { payload } = action;
    try {
        const response = yield call(saveFollowUp, payload.payload);
        if (payload.callback) payload.callback(response?.data);
    } catch (error) {
        if (payload.callback) payload.callback({'status': false, 'message' : 'Something went wrong! Try again.'});
    }
}


function* attemptGetMailCategory(action) {
    const { payload } = action;

    try {
        const response = yield call(getMailCategory, payload.payload);
        let responseData = response.data;
        if (responseData.success) {
            if(payload.payload.message_type === POSTCARD){
                yield put({type: ACTION.SET_POSTCARD_CATEGORY_IN_CARD_BROADCAST, payload: responseData.data});
            }
            else if(payload.payload.message_type === GREETING_CARD){
                yield put({type: ACTION.SET_GREETINGCARD_CATEGORY_IN_CARD_BROADCAST, payload: responseData.data});
            }
            else if(payload.payload.message_type === LETTER){
                yield put({type: ACTION.SET_LETTER_CATEGORY_IN_CARD_BROADCAST, payload: responseData.data});
            }
            else if(payload.payload.message_type === GIFT){
                yield put({type: ACTION.SET_GIFT_CATEGORY_IN_CARD_BROADCAST, payload: responseData.data});
                yield put({type: ACTION.SET_GREETINGCARD_CATEGORY_IN_CARD_BROADCAST, payload: responseData.data});
            }
        } else {
            console.log('not ok');
        }
        if (payload.callback) payload.callback(response?.data);

    } catch (error) {
        console.log(error);
    }
}

function* createCardTemplateWatcher() {
    yield takeEvery(ACTION.CREATE_POSTCARD_TEMPLATE_IN_CARD_BROADCAST, attemptCreateCardTemplate)

}


function* attemptCreateCardTemplate(action) {
    const { payload } = action;

    try {
        const response = yield call(createMailTemplate, payload.payload);
        // let responseData = response.data;
        // if (responseData.success) {
        //     yield put({type: ACTION.SET_MAIL_CATEGORY, payload: responseData.data});
        // } else {
        //     console.log('not ok');
        // }
        if (payload.callback) payload.callback(response?.data);

    } catch (error) {
        console.log(error);
    }

}

export default function* cardBroadcastMiddleware() {
    yield all([
        getCardCategoryWatcher(),
        createCardTemplateWatcher()
    ])
}

const rootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/contacts`
const marketingModuleUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/marketing`
const dealModuleUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/deal`
const profileModuleUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/profile`
const tenantBackendV1 = `${process.env.REACT_APP_PYPEPRO_TENANT_BACKEND_SERVER}/api/v1`
const calenderUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/container`;


const Api = {
  details: `${rootUrl}/details`,
  changeLeadOwner: `${rootUrl}/change/lead-owner`,
  getAllTeamUsers: `${rootUrl}/get-all-team-users`,
  getSearchContacts: `${rootUrl}/search-contacts`,
  getCollaborators: `${rootUrl}/get-collaborators`,
  deleteCollaborator: `${rootUrl}/delete-collaborators`,
  saveCollaborator: `${rootUrl}/save-collaborator`,
  getUserGroup: `${rootUrl}/get-user-group`,
  removeFile: `${rootUrl}/file/remove`,
  uploadFile: `${rootUrl}/upload/file`,
  getTimelineLogs: `${rootUrl}/get/timeline-log`,
  emailContactToFriend: `${rootUrl}/email-contact-to-friend`,
  getGoogleMapResource: `${rootUrl}/get-google-map-resource`,
  editContactData: `${rootUrl}/edit-contact-data`,

  changeDealStatus: `${rootUrl}/status-change-deals`,

  addNewDeal: `${rootUrl}/add-new-deal`,
  updateDeal: `${rootUrl}/update-contact-deal`,
  stageList: `${rootUrl}/stage-list`,
  campaignList: `${rootUrl}/campaign/list/contact`,
  runCampaign: `${rootUrl}/campaign/single-contact/run`,
  pauseCampaign: `${rootUrl}/campaign/single-contact/pause`,
  unsubscribeCampaign: `${rootUrl}/campaign/single-contact/unsubscribe`,
  moveToCampaign: `${rootUrl}/campaign/contact/move`,
  updateSingleCustomField: `${rootUrl}/single/custom-field/update`,
  addTags: `${rootUrl}/add-tags`,
  addTagToContact: `${dealModuleUrl}/contact/add-tags`,
  tagList: `${rootUrl}/tag/list`,
  detachTag: `${rootUrl}/delete-contact-tag`,
  smsTemplate: `${rootUrl}/get/sms/template`,
  emailTemplate: `${rootUrl}/get/email/template`,
  getTimeline: `${rootUrl}/get/timeline`,
  updateTimelineNote: `${rootUrl}/update-timeline-note`,
  voiceTemplate: `${rootUrl}/get/voice/template`,
  userProfile: `${rootUrl}/get/user-profile`,
  sendMail: `${rootUrl}/send-mail`,
  userEmailList: `${rootUrl}/user-email-list`,
  sendMessage: `${rootUrl}/send-message`,
  getCustomFields: `${rootUrl}/get-custom-fields`,
  getRunningCampaignListNotInCurrentCampaign: `${rootUrl}/campaign/not-in-current-contact/list`,
  changeContactSource: `${rootUrl}/contact-source-type/change/source`,
  sourceListDropdown: `${rootUrl}/contact-source-type/list/dropdown`,
  updateContactInfo: `${rootUrl}/update-contact-info`,
  updateAditionalEmailPhone: `${rootUrl}/update-aditional-email-phone`,
  getContactTagList: `${rootUrl}/get-contact-tag-list`,
  deleteContactTag: `${rootUrl}/delete-contact-tag`,
  getMailCategory: `${rootUrl}/get-card-basic-setting`,
  getMailTemplate: `${rootUrl}/get-card-templates`,
  createMailTemplate: `${rootUrl}/card-template-create`,
  getUserVirtualNumberList: `${rootUrl}/virtual-number-list`,
  addNote: `${rootUrl}/add-note-submit`,
  producePDF: `${rootUrl}/generate-pdf`,
  addCardBroadcastToContact: `${rootUrl}/add-card-broadcast-to-contact`,
  getThirdPartyInfo: `${rootUrl}/get-third-party-info`,
  getContactTimeline: `${rootUrl}/contact-conversation`,

  getActivityList: `${rootUrl}/activity-list`,
  contactMarkAsDone: `${rootUrl}/activity-mark-as-done`,
  saveActivity: `${rootUrl}/save-activity`,

  fetchVideoFolders: `${marketingModuleUrl}/video/folders`,
  fetchVideos: `${marketingModuleUrl}/videos`,
  deleteVideo: `${marketingModuleUrl}/video/delete`,
  uploadVideo: `${marketingModuleUrl}/video/upload`,
  getMessageTemplate: `${marketingModuleUrl}/message-templates`,

  //Get All Contacts
  getAllContacts: `${rootUrl}/contact-datatable`,
  getCampaignListForDropdown: `${rootUrl}/campaign-list-dropdown`,
  sourceListDropdownForContactTable: `${rootUrl}/source-list-dropdown`,
  subUserListForContactDropdown: `${rootUrl}/sub-users`,
  fileListForDropdown: `${rootUrl}/file-list-dropdown`,

  //remove contact
  removeBulkContact: `${rootUrl}/bulk-contact-delete`,

  //List
  getAllList: `${rootUrl}/contact-list-dropdown`,

  //single contact
  addContactInCampaign: `${rootUrl}/contact/move/campaign`,

  //multiple contacts
  addContactsInCampaign: `${rootUrl}/add-contacts-to-campaign`,

  //GetUserInfo
  getUserInfo: `${rootUrl}/get-user-data`,

  //Get Custom Fields
  // getCustomFields: `${marketingModuleUrl}/get-custom-fields`,

  //submit single contact
  addSingleContact: `${marketingModuleUrl}/campaigns/add/single-contact`,

  //get pipeline list
  getPipelineList: `${dealModuleUrl}/select-all-pipeline-except`,

  //get stages by pipeline
  getAllStageByPipeline: `${dealModuleUrl}/select-all-stage-by-pipeline`,

  //Import contact routes
  getContactLimit: `${rootUrl}/get-limit`,
  getFileLists: `${rootUrl}/get-file-lists`,
  deleteImportFiles: `${rootUrl}/delete-import-files`,
  contactImport: `${rootUrl}/contact-import`,
  fileAssignToCampaign: `${rootUrl}/campaign-file-assign`,
  allCampaignWithVn: `${rootUrl}/campaign-all-with-vn`,
  getInvalidContacts: `${rootUrl}/get-invalid-contacts`,

  //contact (List) Feature
  getContactList: `${profileModuleUrl}/contact-list/list`,
  saveContactList: `${profileModuleUrl}/contact-list/save`,

  //Assign Bulk Stage
  assignBulkStageForContacts: `${rootUrl}/assign-bulk-stage`,

  //Export csv from contact list
  exportCSVFromContactlist: `${rootUrl}/export-csv-from-contactlist`,

  campaignStart: `${marketingModuleUrl}/campaign/start`,
  campaignPause: `${marketingModuleUrl}/campaign/pause`,

  /* contact notes */

  getCollaboratorListDropdown: `${profileModuleUrl}/get-team-users-without-template`,
  receiveBulkContactPermissionRequest: `${tenantBackendV1}/contact-permission/bulk-permission-request`,
  fetchUserConnectedCalendarList: `${calenderUrl}/user-connected-calendar-list`,
}

export default Api;
export const SET_CONTACT_DETAILS = 'SET_CONTACT_DETAILS';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const UPDATE_CONTACT_COLUMN = 'UPDATE_CONTACT_COLUMN';
export const GET_CONTACT_TAG = 'GET_CONTACT_TAG';
export const SET_CONTACT_TAG = 'SET_CONTACT_TAG';
export const GET_MAIL_CATEGORY = 'GET_MAIL_CATEGORY';
export const SET_MAIL_CATEGORY = 'SET_MAIL_CATEGORY';
export const GET_MAIL_TEMPLATES = 'GET_MAIL_TEMPLATES';
export const CREATE_POSTCARD_TEMPLATE = 'CREATE_POSTCARD_TEMPLATE';


export const SHOW_SMS_MODAL = 'SHOW_SMS_MODAL';
export const SET_SHOW_SMS_MODAL = 'SET_SHOW_SMS_MODAL';
export const CLOSE_SMS_MODAL = 'CLOSE_SMS_MODAL';
export const SET_CLOSE_SMS_MODAL = 'SET_CLOSE_SMS_MODAL';

export const SHOW_NOTE_MODAL = 'SHOW_NOTE_MODAL';
export const SET_SHOW_NOTE_MODAL = 'SET_SHOW_NOTE_MODAL';
export const SHOW_TASK_MODAL = 'SHOW_TASK_MODAL';
export const SET_SHOW_TASK_MODAL = 'SET_SHOW_TASK_MODAL';


export const GET_VIRTUAL_NUMBER_LIST = 'GET_VIRTUAL_NUMBER_LIST';
export const SET_VIRTUAL_NUMBER_LIST = 'GET_VIRTUAL_NUMBER_LIST';

/* VIDEO EMAIL START */
export const FETCH_VIDEO_FOLDERS = 'FETCH_VIDEO_FOLDERS';
export const STORE_VIDEO_FOLDERS = 'STORE_VIDEO_FOLDERS';
export const FETCH_VIDEOS = 'FETCH_VIDEOS';
export const STORE_VIDEOS = 'STORE_VIDEOS';
export const DELETE_VIDEOS = 'DELETE_VIDEOS';
export const UPDATE_FOLDER_ID = 'UPDATE_FOLDER_ID';
/* VIDEO EMAIL END */

/* MESSAGE TEMPLATE START */
export const FETCH_MESSAGE_TEMPLATES = 'FETCH_MESSAGE_TEMPLATES';
export const STORE_MESSAGE_TEMPLATE = 'STORE_MESSAGE_TEMPLATE';
/* MESSAGE TEMPLATE END */

//ContactList

export const GET_ALL_CONTACTS = 'GET_ALL_CONTACTS'
export const SET_ALL_CONTACTS = 'SET_ALL_CONTACTS'

//Get User Info
export const GET_USER_INFO = 'GET_USER_INFO'
export const SET_USER_INFO = 'SET_USER_INFO'
//Tasks
export const FETCH_TASKS = 'FETCH_TASKS'
export const SET_ALL_TASKS = 'SET_ALL_TASKS';
export const SAVE_ACTIVITY_FOLLOWUP = 'SAVE_ACTIVITY_FOLLOWUP';
//Get User Info
export const GET_SUBUSER_LIST = 'GET_SUBUSER_LIST'
export const SET_SUBUSER_LIST = 'SET_SUBUSER_LIST'

//get User tag
export const GET_USERTAG_LIST = 'GET_USERTAG_LIST'
export const SET_USERTAG_LIST = 'SET_USERTAG_LIST'

//get Source List
export const GET_USERSOURCE_LIST_RIGHTSIDE = 'GET_USERSOURCE_LIST_RIGHTSIDE'
export const SET_USERSOURCE_LIST_RIGHTSIDE = 'SET_USERSOURCE_LIST_RIGHTSIDE'

//get User Custom Field List
export const GET_USERCUSTOMFIELD_LIST = 'GET_USERCUSTOMFIELD_LIST'
export const SET_USERCUSTOMFIELD_LIST = 'SET_USERCUSTOMFIELD_LIST'
//pipeline and stage list
export const GET_PIPELINE_LIST = 'GET_PIPELINE_LIST'
export const SET_PIPELINE_LIST = 'SET_PIPELINE_LIST'
//Campaign list
export const GET_CAMPAIGN_LIST = 'GET_CAMPAIGN_LIST'
export const SET_CAMPAIGN_LIST = 'SET_CAMPAIGN_LIST'

//Campaign
export const CAMPAIGN_PAUSE = 'CAMPAIGN_PAUSE'
export const CAMPAIGN_START = 'CAMPAIGN_START'



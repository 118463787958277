/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { Box, makeStyles } from "@material-ui/core"
import { connect } from "react-redux"
import { getUserTagList } from "../redux/contactAction"
import { getContactTagList } from "../../../../api/contactApi"
import CustomTitle from "../common/customTitle/CustomTitle"
import ModalAddTagFormNew from "./ModalAddTagFormNew"
import TagsList from "./TagsList"

const useStyles = makeStyles({
  hoverDiv: {
    "&:hover": {
      "& $icon": {
        opacity: 1,
        transition: "width .35s",
        width: "42px",
        display: "flex",
      },
    },
  },
  icon: {
    opacity: 0,
    width: 0,
    display: 'none'
  },
})

const Tags = (props) => {
  const [openModal, setOpenModal] = useState(false)
  const [tags, setTags] = useState(null)
  useEffect(() => {
    getTagList()
  }, [props.contactId])

  const handleOpen = () => {
    setOpenModal(true)
  }
  const handleClose = () => {
    setOpenModal(false)
  }

  const getTagList = () => {
    getContactTagList({
      contactId: props.contactId,
    })
      .then((response) => {
        if (response) {
           setTags(response.data.data)
        } else {
          console.error("Invalid data structure in API response:", response)
        }
      })
      .catch((error) => {
        console.error("Error fetching tags:", error)
      })
  }

  const successCallBack = (update = false, newTag = false) => {
    setOpenModal(false)
    getTagList()
    if (newTag) {
      props.fetchUserTagList({})
    }
  }

  const icons = useStyles()
  return (
    <Box className={icons.hoverDiv}>
      <Box sx={{ gap: 10 }} mb={2}>
        <CustomTitle
          handleOpen={handleOpen}
          title={"TAGS"}
          iconHide={icons.icon}
        />
      </Box>
      
        <TagsList
          tags={tags}
          getTagList={getTagList}
          contact_id={[props.contactId]}
        />
      
      {openModal && (
        <ModalAddTagFormNew
          open={openModal}
          contact_id={[props.contactId]}
          title={"Add Tag"}
          callBack={successCallBack}
          onClose={handleClose}
        />
      )}
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    contact: state?.rightsideReducer?.contact,
    contactTags: state?.rightsideReducer?.tags,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // getContactTagList: (params) => dispatch(getContactTagList(params)),
    fetchUserTagList: (params, callback) =>
      dispatch(getUserTagList(params, callback)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Tags)
import React, { useState, useEffect } from 'react';
import { getCampaignList } from '../redux/contactApi.js';
import Campaign from "./Campaign.js"
import ModalAddCampaignForm from './ModalAddCampaignForm.js';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Avatar from '@material-ui/core/Avatar';
import { FormControlLabel } from '@material-ui/core';
import StyledAccordion from "../../../common/StyledAccordion";

const CampaignList= (props)=> {
    // constructor(props) {
    //     super(props)

    //     this.state = {
    //         show: false,
    //         campaigns: [],
    //         count: 0,
    //         showModal: false
    //     }
    // }
    const [show,setShow] = useState(false);
    const [campaigns, setCampaigns] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [count, setCount] = useState(0)

    useEffect(() => {
        loadCampainList();
    }, [])

    const loadCampainList = () =>{
        getCampaignList({
            contact_id: props.contact_id
        }).then(res => {
            setCount(res.data.length);

            setCampaigns(res.data)
        });
    }

    const reLoadCampainList = () =>{
        loadCampainList();
        setShowModal(false)
        setShow(true)
    }


    const renderCampaigns=()=>{

        let dataArray=[];
        if(count> 0){
            for(let i =0;i<count ; i++){
                if(campaigns[i] !== undefined && campaigns[i] != null && campaigns[i].campaign !== undefined && campaigns[i].campaign != null){
                    dataArray.push(
                    <Campaign reLoadCampainList={reLoadCampainList} contact_id={props.contact_id} user={props.user} 
                    key={i} data={campaigns[i]}/>);
                }
            }
            if(dataArray.length > 0){
                return dataArray;

            }else{
                return (<div class="d-flex justify-content-between align-items-center custom__compain_content">
            <h6 class="text-gray">no campaign assigned</h6></div>)
            }

        }else{

            return (<div class="d-flex justify-content-between align-items-center custom__compain_content">
            <h6 class="text-gray">no campaign assigned</h6></div>)
    
        }
    }
        // let renderCampaigns = this.state.campaigns.length >0 ? this.state.campaigns.map((data, index) => {
        //     if(data !== undefined && data != null && data.campaign !== undefined && data.campaign != null){

        //      count++;
        //     return 
        //     }
        // }) : <div class="d-flex justify-content-between align-items-center custom__compain_content">
        // <h6 class="text-gray">no campaign assigned</h6></div>

    return (
        <React.Fragment>
            <StyledAccordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <FormControlLabel
                            aria-label="Acknowledge"
                            // onClick={(event) => event.stopPropagation()}
                            // onFocus={(event) => event.stopPropagation()}
                            control={
                                <React.Fragment>
                                    <div className="collaborator__list__parant">
                                    <div className="collaborator__list__left"> <span className="collaborator__left__icon"><svg width="23" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M14.9001 1.86233L8.1445 8.32683L10.6235 14.4588L19.9806 14.4292L14.9001 1.86233Z" fill="white"/>
                                                                    <path d="M7.43035 8.75108L6.78125 9.0127L9.16114 14.8994L9.81024 14.6378L7.43035 8.75108Z" fill="white"/>
                                                                    <path d="M8.34865 15.0674L5.96877 9.1807L3.35081 10.2358C2.98323 10.384 2.68965 10.6719 2.53465 11.0361C2.37965 11.4004 2.37593 11.8113 2.52431 12.1783L3.78775 15.3034C3.93613 15.6705 4.22446 15.9636 4.5893 16.1184C4.95414 16.2731 5.3656 16.2768 5.73319 16.1287L8.34865 15.0674Z" fill="white"/>
                                                                    <path d="M20.1725 6.91277C19.9625 6.3953 19.5556 5.98209 19.041 5.76378C18.5264 5.54547 17.946 5.53988 17.4272 5.74825L17.3672 5.77246L18.9461 9.67799L19.0062 9.65379C19.5244 9.44419 19.9383 9.03791 20.1569 8.52406C20.3756 8.0102 20.3811 7.43073 20.1725 6.91277Z" fill="white"/>
                                                                    <path d="M10.3249 20.9361C10.531 20.853 10.7152 20.7235 10.8631 20.5577C11.0109 20.392 11.1185 20.1944 11.1774 19.9803C11.2363 19.7663 11.245 19.5416 11.2028 19.3236C11.1606 19.1057 11.0686 18.9004 10.9339 18.7237L8.80067 15.9366L6.09263 17.028L6.01562 17.0591L8.63444 20.4802C8.8279 20.7298 9.09778 20.9095 9.40296 20.9918C9.70813 21.0741 10.0319 21.0546 10.3249 20.9361Z" fill="white"/>
                                                                    </svg>
                                    </span> <span className="collaborator__list--title text-dark-blue">Campaigns</span>   <Avatar>{count}</Avatar> </div>
                                    <div className="collaborator__list__right" onClick={(event) => event.stopPropagation()}> <Avatar onClick={()=> setShowModal(!showModal)} style={{ marginLeft: 10 }} ><i class="material-icons rightsidebar__list__addicon">add</i></Avatar></div>
                                    </div>
                                     
                                </React.Fragment>
                            }
                        />
                    </AccordionSummary>
                    <AccordionDetails className="accordion__parent">
                        <React.Fragment>
                            {renderCampaigns()}
                            {/* {campaigns.map((data, index) => {
                                if(data !== undefined && data != null && data.campaign !== undefined && data.campaign != null){
                                    return <Campaign reLoadCampainList={reLoadCampainList} contact_id={props.contact_id} user={props.user} key={index} data={data}/>;
                                }
                            })} */}
                        </React.Fragment>
                    </AccordionDetails>
                </StyledAccordion>
                {
                    showModal && (
                        <ModalAddCampaignForm
                            contact_id={props.contact_id}
                            selectedCampaign={campaigns}
                            title={"Add Campaigns"}
                            people={props.people}
                            isOpen={showModal}
                            // onClose={()=>this.setState({showModal:false})}
                            callBack={()=>reLoadCampainList()}
                            modalToggle={()=> setShowModal(!showModal)}
                        />
                    )
                }
        </React.Fragment>
    );
    
}

export default CampaignList;

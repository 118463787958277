import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { detachTag } from '../redux/contactApi';

class Tag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false
        }
    }

    delete = (e) => {
        e.preventDefault();
        
        confirmAlert({
            title: 'Are you sure ?',
            message: 'Do you want to remove this tag?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        detachTag({
                            contact_id: this.props.contact_id,
                            tag_id: this.props.data.tag_id
                        }).then(res => {
                            this.props.reloadTags();
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        })
    };

    render() {
        return (
            <div className="d-flex align-items-center justify-content-between listing mb-1">
                <h6 className="text-dark-blue">{this.props.data.tag.name}</h6>
                <a href="#" onClick={this.delete}>
                    <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.26442 1.57692H7.09615V1.18269C7.09615 0.529501 6.56665 0 5.91346 0H4.33654C3.68335 0 3.15385 0.529501 3.15385 1.18269V1.57692H0.985577C0.441267 1.57692 0 2.01819 0 2.5625V3.35096C0 3.5687 0.176492 3.74519 0.394231 3.74519H9.85577C10.0735 3.74519 10.25 3.5687 10.25 3.35096V2.5625C10.25 2.01819 9.80873 1.57692 9.26442 1.57692ZM3.94231 1.18269C3.94231 0.965372 4.11922 0.788461 4.33654 0.788461H5.91346C6.13078 0.788461 6.30769 0.965372 6.30769 1.18269V1.57692H3.94231V1.18269Z" fill="#FF5858"></path>
                        <path d="M0.748192 4.53418C0.677846 4.53418 0.621792 4.59297 0.625143 4.66324L0.950383 11.4894C0.980443 12.1211 1.49935 12.6159 2.1316 12.6159H8.11848C8.75073 12.6159 9.26964 12.1211 9.2997 11.4894L9.62494 4.66324C9.62829 4.59297 9.57223 4.53418 9.50189 4.53418H0.748192ZM6.70196 5.51976C6.70196 5.30194 6.87838 5.12553 7.09619 5.12553C7.31401 5.12553 7.49042 5.30194 7.49042 5.51976V10.6448C7.49042 10.8626 7.31401 11.039 7.09619 11.039C6.87838 11.039 6.70196 10.8626 6.70196 10.6448V5.51976ZM4.73081 5.51976C4.73081 5.30194 4.90723 5.12553 5.12504 5.12553C5.34285 5.12553 5.51927 5.30194 5.51927 5.51976V10.6448C5.51927 10.8626 5.34285 11.039 5.12504 11.039C4.90723 11.039 4.73081 10.8626 4.73081 10.6448V5.51976ZM2.75966 5.51976C2.75966 5.30194 2.93607 5.12553 3.15389 5.12553C3.3717 5.12553 3.54812 5.30194 3.54812 5.51976V10.6448C3.54812 10.8626 3.3717 11.039 3.15389 11.039C2.93607 11.039 2.75966 10.8626 2.75966 10.6448V5.51976Z" fill="#FF5858"></path>
                    </svg>
                </a>
            </div>
        );
    }
}

export default Tag;
import React, { Component } from 'react';

const RecordedVideo = props => {

    const playPause = () => {
        var myVideo = document.getElementById("video-static"); 
        if (myVideo.paused) 
        myVideo.play(); 
        else 
        myVideo.pause(); 
    }

    return(
        <div className="video-area">
            <div className="video-inner">
                <video controls playsInline preload="auto" className="video-custom" id="video-static">
                    <source src={props.urlLink} type="video/mp4" />
                </video>
            </div>
        </div>
    );
}
export default RecordedVideo;
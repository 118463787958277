import { sendRequest, sendRequestBackEnd } from './rootApi'
import Api, { contactApi } from '../constants/Api'
import HttpRequest from './HttpRequest'

export const fetchContactInfo = async (payload) => {
  return sendRequest('post', contactApi.details, payload)
}

export const fetchContactDetails = async (payload) => {
  return sendRequest('post', contactApi.details, payload)
}

export const changeLeadOwner = async (payload) => {
  return sendRequest('post', contactApi.changeLeadOwner, payload)
}

export const getAllTeamUsers = async (payload) => { 
    return sendRequest("post", contactApi.getAllTeamUsers, payload);
}

export const getContacts = async (payload) => {
  return sendRequest('post', contactApi.getSearchContacts, payload)
}

export const getCollaborators = async (payload) => {
  return sendRequest('post', contactApi.getCollaborators, payload)
}

export const deleteCollaborator = async (payload) => {
  return sendRequest('post', contactApi.deleteCollaborator, payload)
}

export const saveCollaborator = async (payload) => {
  return sendRequest('post', contactApi.saveCollaborator, payload)
}

export const getUserGroup = async (payload) => {
  return sendRequest('post', contactApi.getUserGroup, payload)
}

export const changeDealStatus = async (payload) => {
  return sendRequest('post', contactApi.changeDealStatus, payload)
}

export const addNewDeal = async (payload) => {
  return sendRequest('post', contactApi.addNewDeal, payload)
}

export const updateDeal = async (payload) => {
  return sendRequest('post', contactApi.updateDeal, payload)
}

export const getStageList = async (payload) => {
  return sendRequest('post', contactApi.stageList, payload)
}

export const getCampaignList = async (payload) => {
  return sendRequest('post', contactApi.campaignList, payload)
}

export const runCampaign = async (payload) => {
  return sendRequest('post', contactApi.runCampaign, payload)
}

export const pauseCampaign = async (payload) => {
  return sendRequest('post', contactApi.pauseCampaign, payload)
}

export const unsubscribeCampaign = async (payload) => {
  return sendRequest('post', contactApi.unsubscribeCampaign, payload)
}

export const moveToCampaign = async (payload) => {
  return sendRequest('post', contactApi.moveToCampaign, payload)
}

export const getRunningCampaignListNotInCurrentCampaign = async (payload) => {
  return sendRequest(
    'post',
    contactApi.getRunningCampaignListNotInCurrentCampaign,
    payload
  )
}

export const changeContactSource = async (payload) => {
  return sendRequest('post', contactApi.changeContactSource, payload)
}

export const getSourceListDropdown = async (payload) => {
  return sendRequest('post', contactApi.sourceListDropdown, payload)
}

export const updateSingleCustomField = async (payload) => {
  return sendRequest('post', contactApi.updateSingleCustomField, payload)
}

export const addTags = async (payload) => {
  return sendRequest('post', contactApi.addTags, payload)
}

export const getTagList = async (payload) => {
  return sendRequest('post', contactApi.tagList, payload)
}

export const getSmsTemplate = async (payload) => {
  return sendRequest('post', contactApi.smsTemplate, payload)
}

export const getVoiceTemplate = async (payload) => {
  return sendRequest('post', contactApi.voiceTemplate, payload)
}

export const getActivityList = async (payload) => {
  // return sendRequest("post", contactApi.getActivityList, payload);
  return sendRequest('post', contactApi.getActivityListV2, payload)
}

export const taskMarkAsDone = async (payload) => {
  return sendRequest('post', contactApi.contactMarkAsDone, payload)
}

export const saveActivity = async (payload) => {
  return sendRequest('post', contactApi.saveActivity, payload)
}

export const getUserProfile = async (payload) => {
  return sendRequest('post', contactApi.userProfile, payload)
}

export const sendMail = async (payload) => {
  return sendRequest('post', contactApi.sendMail, payload)
}

export const getEmailTemplate = async (payload) => {
  return sendRequest('post', contactApi.emailTemplate, payload)
}

export const getTimeline = async (payload) => {
  return sendRequest('post', contactApi.getTimeline, payload)
}

export const updateTimelineNote = async (payload) => {
  return sendRequest('post', contactApi.updateTimelineNote, payload)
}

export const removeFile = async (payload) => {
  return sendRequest('post', contactApi.removeFile, payload)
}

export const getTimelineLogs = async (payload) => {
  return sendRequest('post', contactApi.getTimelineLogs, payload)
}

export const getUserEmailList = async (payload) => {
  return sendRequest('post', contactApi.userEmailList, payload)
}

export const sendMessage = async (payload) => {
  return sendRequest('post', contactApi.sendMessage, payload)
}

export const getCustomFields = async (payload) => {
  return sendRequest('post', contactApi.getCustomFields, payload)
}

export const updateContactInfo = async (payload) => {
  return sendRequest('post', contactApi.updateContactInfo, payload)
}
export const updateAditionalEmailPhone = async (payload) => {
  return sendRequest('post', contactApi.updateAditionalEmailPhone, payload)
}
export const getContactTagList = async (payload) => {
  return sendRequest('post', contactApi.getContactTagList, payload)
}
export const deleteContactTag = async (payload) => {
  return sendRequest('post', contactApi.deleteContactTag, payload)
}

export const getMailCategory = async (payload) => {
  return sendRequest('post', contactApi.getMailCategory, payload)
}

export const getMailTemplate = async (payload) => {
  return sendRequest('post', contactApi.getMailTemplate, payload)
}

export const createMailTemplate = async (payload) => {
  return sendRequest('post', contactApi.createMailTemplate, payload)
}

export const getUserVirtualNumberList = async (payload) => {
  return sendRequest('post', contactApi.getUserVirtualNumberList, payload)
}

export const emailContactToFriend = async (payload) => {
  return sendRequest('post', contactApi.emailContactToFriend, payload)
}

export const getGoogleMapResource = async (payload) => {
  return sendRequest('post', contactApi.getGoogleMapResource, payload)
}

export const editContactData = async (payload) => {
  return sendRequest('post', contactApi.editContactData, payload)
}

export const addNote = async (payload) => {
  return sendRequest('post', contactApi.addNote, payload)
}

export const producePDF = async (payload) => {
  return sendRequest('post', contactApi.producePDF, payload)
}

export const createCardBroadcast = async (payload) => {
  return sendRequest('post', contactApi.addCardBroadcastToContact, payload)
}

export const getThirdPartyService = async (payload) => {
  return sendRequest('post', contactApi.getThirdPartyInfo, payload)
}

export const getContactTimeline = async (payload) => {
  return sendRequest('post', contactApi.getContactTimeline, payload)
}

export const fetchVideoFolders = async (payload) => {
  return sendRequest('post', contactApi.fetchVideoFolders, payload)
}

export const fetchVideos = async (payload) => {
  return sendRequest('post', contactApi.fetchVideos, payload)
}

export const deleteVideo = async (payload) => {
  return sendRequest('post', contactApi.deleteVideo, payload)
}

export const getMessageTemplate = async (payload) => {
  return sendRequest('post', contactApi.getMessageTemplate, payload)
}

export const getAllContacts = async (payload) => {
  return sendRequest('post', contactApi.getAllContacts, payload)
}

export const getAllCampaignForDropdown = async (payload) => {
  return sendRequest('post', contactApi.getCampaignListForDropdown, payload)
}

export const getSourceListDropdownForContactTable = async (payload) => {
  return sendRequest(
    'post',
    contactApi.sourceListDropdownForContactTable,
    payload
  )
}

export const getSubUserListForContactlist = async (payload) => {
  return sendRequest('post', contactApi.subUserListForContactDropdown, payload)
}

export const getFileListForDropdown = async (payload) => {
  return sendRequest('post', contactApi.fileListForDropdown, payload)
}

export const removeBulkContact = async (payload) => {
  return sendRequest('post', contactApi.removeBulkContact, payload)
}

export const getContactListForDropdown = async (payload) => {
  return sendRequest('post', contactApi.getAllList, payload)
}

//Single contact
export const addContactInCampaign = async (payload) => {
  return sendRequest('post', contactApi.addContactInCampaign, payload)
}

//Multiple contacts
export const addContactsInCampaign = async (payload) => {
  return sendRequest('post', contactApi.addContactsInCampaign, payload)
}

//getUserInfo
export const getUserInfo = async (payload) => {
  return sendRequest('post', contactApi.getUserInfo, payload)
}

/* note api */
export const addNoteRightSide = async (payload) => {
  return sendRequest('post', Api.addNote, payload)
}

export const deleteNoteRightSide = async (payload) => {
  return sendRequest('post', contactApi.deleteNote, payload)
}

export const getContactSmartFormEntries = async (contactId) => {
  return sendRequestBackEnd(
    'get',
    Api.getContactSmartFormEntries + `?contactId=${contactId}`
  )
}

export const formBuilderAWSPreSignedUrl = async (payload) => {
  return sendRequest('post', Api.formBuilderAWSPreSignedUrl, payload)
}

export const getContactVerificationStatsApi = async (payload) => {
  return sendRequest('post', Api.getContactVerificationStats, payload)
}

export const getCarrierLookupCreditSettingsApi = async (payload) => {
  return sendRequest('post', Api.getCarrierLookupCreditSettings, payload)
}
export const carrierLookupVerifyContactApi = async (payload) => {
  return sendRequestBackEnd('post', Api.carrierLookupVerifyContact, payload)
}

export const getUserCampaignRegistrationStatusApi = async (payload) => {
  return sendRequest('POST', Api.getUserCampaignRegistrationStatus, payload)
}

export const updateNumberDncStatusApi = async (payload) => {
  return HttpRequest.post(Api.updateNumberDncStatus, payload)
}

export const getContactLogsApi = async (payload) => {
  return HttpRequest.get(Api.getContactLogs, payload)
}

export const updateContactDetailsV2 = async (payload) => {
  return HttpRequest.post(Api.updateContactDetailsV2, payload)
}

export const carrierLookupCleanDataApi = async (payload) => {
  return sendRequestBackEnd('post', Api.carrierLookupCleanData, payload)
}

export const createAdditionalContactApi = (payload) => {
  return sendRequest('POST', Api.createAdditionalContactApi, payload)
}
export const getAllAdditionalContactApi = (payload) => {
  return sendRequest('POST', Api.getAllAdditionalContactApi, payload)
}
export const updateAdditionalContactApi = (payload) => {
  return sendRequest('POST', Api.updateAdditionalContactApi, payload)
}
export const deleteAdditionalContactApi = (payload) => {
  return sendRequest('POST', Api.deleteAdditionalContactApi, payload)
}

export const updateContactCustomFieldV2 = (payload) => {
  return HttpRequest.post(Api.updateContactCustomFieldV2, payload)
}

export const deleteAdditionalContactNumberApi = (payload) => {
  return HttpRequest.post(Api.deleteAdditionalContactNumberApi, payload)
}

export const deleteAdditionalContactEmailApi = (payload) => {
  return HttpRequest.post(Api.deleteAdditionalContactEmailApi, payload)
}

export const makeDefaultAdditionalContactNumberApi = (payload) => {
  return HttpRequest.post(Api.makeDefaultAdditionalContactNumberApi, payload)
}

export const makeDefaultAdditionalContactEmailApi = (payload) => {
  return HttpRequest.post(Api.makeDefaultAdditionalContactEmailApi, payload)
}

export const makeDefaultContactApi = (payload) => {
  return sendRequest('POST', Api.makeDefaultContact, payload)
}

export const getZipCodeByPlace = (payload) => {
  return sendRequest('POST', Api.getZipCodeByPlace, payload)
}

export const updateContactLeadType = (payload) => {
  return HttpRequest.post(Api.updateContactLeadTypeApi, payload)
}

export const deleteContactNumberApi = (payload) => {
  return HttpRequest.post(Api.deleteContactNumberApi, payload);
}

export const deleteContactEmailApi = (payload) => {
  return HttpRequest.post(Api.deleteContactEmailApi, payload);
}

export const updateImportantDates = (payload) => {
  return HttpRequest.post(Api.updateImportantDates, payload);
}

export const updateImportantLinks = (payload) => {
  return HttpRequest.post(Api.updateImportantLinks, payload);
}

export const getCampaignStages = async (payload) => {
  return HttpRequest.get(Api.getContactStagesProduct, payload)
}

export const getUserProductList = async () => {
  return HttpRequest.get(Api.getUserProductList)
}

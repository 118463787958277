import React from 'react';
import styled, { keyframes } from 'styled-components';

// Keyframes for the shimmer effect
const shimmer = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`;

const SkeletonItem = styled.div`
  background: #f0f0f0;
  background-image: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200px 100%;
  animation: ${shimmer} 1.5s infinite linear;
  border-radius: 4px;
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '20px'};
  margin-bottom: 12px;
`;

const SkeletonHeader = styled(SkeletonItem)`
  width: 100%;
  height: 25px;
`;

const SkeletonSection = styled(SkeletonItem)`
  width: 100%;
  height: 40px;
`;

const SkeletonListItem = styled(SkeletonItem)`
  width: 100%;
  height: 15px;
  margin-bottom: 8px;
`;

const   SkeletonLoaderMiscellaneous = () => {
  return (
    <SkeletonWrapper>
      <SkeletonHeader />
      
      <SkeletonSection />
      <SkeletonSection />
      <SkeletonSection />
      <SkeletonSection />
      <SkeletonSection />
      <SkeletonSection />
      <SkeletonSection />
      
    </SkeletonWrapper>
  );
};

export default SkeletonLoaderMiscellaneous;

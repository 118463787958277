import React, {useEffect, useState} from "react";
import {addNoteSubmit} from "../../../api/inboxApi";
import {getVirtualNumbers} from "../../../actions/inboxAction";
import {connect, useSelector} from "react-redux";

import { Mention, MentionsInput } from 'react-mentions';
import defaultStyle from "../rightSide/noteNew/defaultStyle";
import { getAllTeamUsers } from "../rightSide/redux/contactApi";

const mentionStyle = {
    backgroundColor: '#cee4e5'
};

const App = (props) =>
{
    // const [contact, setContact] = useState(props.selectedContact);
    const contact = useSelector(state => state.inboxReducer.selectedContact);
    const [noteText, setNoteText] = useState('');

    const [sending, setSending] = useState(false)

    const [allTeamUser, setAllTeamUser] = useState([])

    useEffect(()=>{
        loadTeamUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadTeamUserList = () => {
        getAllTeamUsers({}).then(res=>{
            if(res.data.users){
                let allTeamUser=res.data.users
                let  users = allTeamUser.map((data) => {
                    return {
                        id: data.value,
                        display: data.label,
                    };
                });
                setAllTeamUser(users)
            }else{
            }
        }).catch(error => {
        })
    }

    // useEffect(() => {
    //     setContact(props.selectedContact);
    // }, [props.selectedContact]);


    const addNote = (event, callback = () => {}) => {
        if(event !== null){
            event.preventDefault();
        }
        if(sending){
            return
        }
        if(noteText.trim() == ''){
            if(window.showNotification !== undefined){
                window.showNotification("ERROR", "Note text is required");
            }
            return
        }
        setSending(true)
        let formData = new FormData();
        formData.append('contact_id', contact.id);
        formData.append('origin', 3);
        formData.append('note', noteText);

        addNoteSubmit(formData)
            .then(response => {
                setSending(false)
                if(response.data.success == true){
                    // window.appendConversation(response.data.data.data)
                    if(window.refreshConersation !== undefined){
                        window.refreshConersation()
                    }
                    if(props.closeTab != undefined){
                        props.closeTab()
                    }
                    callback(true);
                }
                else{
                    if(window.showNotification !== undefined){
                        window.showNotification("ERROR", "Something went wrong");
                    }
                    callback(false);
                }
            })
    }

    window.handleAddNote = (callback) => {
        addNote(null, callback)
    }

    return (
        <div id="noteTab" className="col s12 inbox__note__msg__wr">
            <div className="chat__bottom_wrapper">
            <div className="tiny-mce">
            
                {/* <MentionsInput
                    cols={30}
                    rows={5}
                    className="control-overflow mentionClass"
                    onChange={(event) => setNoteText(event.target.value)} 
                    value={noteText}
                    style={defaultStyle}
                    markup="@[__display__](__id__) "
                    placeholder={"Add notes and mentions (@username)"}
                >
                    <Mention
                        type="user"
                        trigger="@"
                        data={allTeamUser}
                        renderSuggestion={(suggestion, search, highlightedDisplay) => (
                            <div className="user">{highlightedDisplay}</div>
                        )}
                        style={mentionStyle}
                    />
                </MentionsInput> */}
                <div className="inbox__note__tiny__mce__wr">
                    <div className="inbox__note__tiny__mce__container ">
                        {/* <textarea className="inbox__note__tiny__text__area" placeholder="Type to add note" name="noteText" onChange={(event) => setNoteText(event.target.value)} value={noteText}/> */}
                        <MentionsInput
                    cols={30}
                    rows={5}
                    className="control-overflow mentionClass"
                    onChange={(event) => setNoteText(event.target.value)} 
                    value={noteText}
                    style={defaultStyle}
                    markup="@[__display__](__id__) "
                    placeholder={"Add notes and mentions (@username)"}
                >
                    <Mention
                        type="user"
                        trigger="@"
                        data={allTeamUser}
                        renderSuggestion={(suggestion, search, highlightedDisplay) => (
                            <div className="user">{highlightedDisplay}</div>
                        )}
                        style={mentionStyle}
                    />
                </MentionsInput>
                    </div>
                </div>
            </div>

            <div className="send-button-area">
                <div className="mb-0 row">
                    <div className="col s12">
                        <div className="send-message-btn right p-relative">
                            <a href="#" onClick={(event) => addNote(event)}><i className="material-icons left">near_me</i>{sending ? 'Adding ' : 'Add '}Note</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}


const mapStateToProps = state => {
    return {
        virtualNumbers: state.inboxReducer.virtualNumbers,
        personalizes: state.inboxReducer.personalizes,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getVirtualNumbers: (params) => dispatch(getVirtualNumbers(params)),
    };
}

const Note = connect(mapStateToProps, mapDispatchToProps)(App);

export default Note;
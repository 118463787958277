import React from "react";
import { Box, Divider, Typography, makeStyles } from "@material-ui/core";
import { RightSideV2Styles } from "../../RightSideV2Styles";

const customTitleStyles = makeStyles((theme) => ({
  titleStyle: {
    fontSize: '15px',
    fontWeight: 600,
    color: "var(--dark_blue)",
  },
}));

const CustomTitleV2 = ({ title = "Default Title", sx, children }) => {
  const { flexCenterBetween, flexCenter } = RightSideV2Styles();
  const { titleStyle } = customTitleStyles();
  return (
    <Box sx={sx}>
      <Box className={flexCenterBetween} sx={{ flexWrap: "wrap" }}>
        <Typography variant='body2' className={titleStyle}>
          {title}
        </Typography>

        {children && (
          <Box className={flexCenter} sx={{ gap: 10 }}>
            {children}
          </Box>
        )}
      </Box>

      <Box sx={{ mt: 1 }}>
        <Divider />
      </Box>
    </Box>
  );
};

export default CustomTitleV2;

import React, { useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import { connect } from "react-redux";
import { setContactDetails } from "../../../../actions/contactAction";
import Utils from "../../../../helpers/Utils";
import { RightSideV2Styles } from "../RightSideV2Styles";
import CarrierLookup from "../common/carrierLookup/CarrierLookup";
import EditEmailModal from "./EditEmailModal";
import { LOOKUP_TYPE_EMAIL_LOOKUP } from "../../../../constants/CoreConstants";
import CustomTitle from "../common/customTitle/CustomTitle";
import EmailIcon from "@material-ui/icons/Email";
// import Styles from '../common/customTitle/customTitle.module.css'
import BootstrapTooltip from "../../../globals/BootstrapTooltip";
import { showValidator } from "../helper/utils";
import Styles from "./email.module.css";

const useStyles = makeStyles({
  hoverDiv: {
    "&:hover": {
      "& $icon": {
        opacity: 1,
        transition: "width .35s",
        width: "42px",
        display: "flex",
      },
    },
  },
  icon: {
    opacity: 0,
    width: 0,
    display: "none",
  },
  EmailIcon: {
    cursor: "pointer",
    background: "#316aff !important",
    color: "white !important",

    "& svg": {
      fontSize: '16px !important',
    },

    "& svg path": {
      fill: 'white !important'
    },

    "&:focus, &:hover ": {
      background: "#316aff",
    },
  },
});

const Email = (props) => {
  const [open, setOpen] = useState(false);
  const { flexCenterBetween, textDarkBlue } = RightSideV2Styles();
  const classes = useStyles();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // if (!props.contact?.email && !props.contactAdditionalContacts?.email) {
  //   return null
  // }

  return (
    <div className={classes.hoverDiv}>
      <Box mb={2}>
        <Box
          sx={{ display: "flex", paddingRight: "6px", justifyContent: "end" }}
        >
          {Utils.getAccountData("carrierLookup") &&
            props.contact &&
            props.contact.email &&
              <Button
                  className={`${Styles.buttonStyle} ${Styles.validButton}`}
                  variant="contained"
                  color="primary"
                  size="small"
                  disableElevation
                  onClick={handleOpen}
              >
                Validate
              </Button>
            }
        </Box>

        <CustomTitle
          handleOpen={handleOpen}
          title={"EMAIL"}
          iconHide={classes.icon}
        />
      </Box>
      {(props.contact && props.contact?.email) ||
      (props.contactAdditionalContacts &&
        props.contactAdditionalContacts?.email) ? (
        <Box
          sx={{
            borderRadius: "4px !important",
            border: "1px solid #ced4da !important",
            padding: "8px 8px",
            // overflow: "hidden",
          }}
        >
          {props.contact && props.contact.email && (
            <Box className={flexCenterBetween} sx={{ gap: 10 }}>
              <Box
                sx={{ display: "flex", gap: 8, alignItems: "center" }}
                onClick={(e) => {
                  e.preventDefault();
                  if (window.sendCommunication !== undefined) {
                    window.sendCommunication(
                      {
                        open: true,
                        onClose: () => console.log("Email Close"),
                        id: props.contact.id,
                        from: "contact-details",
                        primaryEmail: props.contact?.email,
                        fetchContactDetails: false,
                        sendSuccessCallBack: () => {
                          // setLastCommunicationMessage("A few seconds ago");
                          // props.reloadNeedForConversation(true);
                        },
                      },
                      "email"
                    );
                  }
                }}
              >
                <IconButton size="small"   className={`${classes.EmailIcon} ${Styles.emailIconButton}`}>
                  <EmailIcon />
                </IconButton>
                <BootstrapTooltip
                  title={
                    props.contact?.email.length > 32
                      ? `${props.contact?.email}`
                      : ""
                  }
                >
                  <Typography className={Styles.emailBoxWidth}>
                    {props.contact?.email}
                  </Typography>
                </BootstrapTooltip>
              </Box>

              <CarrierLookup
                lookupType={LOOKUP_TYPE_EMAIL_LOOKUP}
                validator={false}
              />
            </Box>
          )}

          {props.contactAdditionalContacts.map(
            (contactAdditionalContact, index) =>
              contactAdditionalContact?.email ? (
                <Box
                  key={index}
                  className={flexCenterBetween}
                  sx={{ gap: 10, mt: 0.5 }}
                >
                  <Box
                    sx={{ display: "flex", gap: 8, alignItems: "center" }}
                    onClick={(e) => {
                      e.preventDefault();
                      if (window.sendCommunication !== undefined) {
                        window.sendCommunication(
                          {
                            open: true,
                            onClose: () => console.log("Email Close"),
                            id: props.contact.id,
                            from: "contact-details",
                            primaryEmail: contactAdditionalContact?.email,
                            fetchContactDetails: false,
                            sendSuccessCallBack: () => {
                              // setLastCommunicationMessage("A few seconds ago");
                              // props.reloadNeedForConversation(true);
                            },
                          },
                          "email"
                        );
                      }
                    }}
                  >
                    <IconButton size="small"  className={`${classes.EmailIcon} ${Styles.emailIconButton}`}>
                      <EmailIcon />
                    </IconButton>
                    <BootstrapTooltip
                      title={
                        contactAdditionalContact.email.length > 32
                          ? `${contactAdditionalContact.email}`
                          : ""
                      }
                    >
                      <Typography
                        className={`${Styles.emailBoxWidth} ${textDarkBlue}`}
                      >
                        {contactAdditionalContact.email}
                      </Typography>
                    </BootstrapTooltip>
                  </Box>
                  <CarrierLookup
                    lookupType={LOOKUP_TYPE_EMAIL_LOOKUP}
                    validator={false}
                    additional={{ ...contactAdditionalContact, ogIndex: index }}
                  />
                </Box>
              ) : (
                <div />
              )
          )}
        </Box>
      ) : null}

      {open && <EditEmailModal open={open} onClose={handleClose} />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.rightsideReducer.contact,
    contactAdditionalContacts: state.inboxReducer.contactAdditionalContacts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setContactDetails: (params) => dispatch(setContactDetails(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Email);

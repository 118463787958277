import React, { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import 'moment-timezone';
import SimpleReactValidator from 'simple-react-validator';
import dateFnsFormat from 'date-fns/format';
import moment from 'moment';
import styled from 'styled-components';
import { FormControl, Grid, MenuItem } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { addNewDeal, getStageList } from '../redux/contactApi';
import GlobalModal from '../Helpers/Modal/GlobalModal';
import { Save } from '@material-ui/icons';
import NewLoader from '../Helpers/NewLoader';

const Wrapper = styled.div`
    margin: 0;
`;

const ManageTagHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E5E8EF;
`;

const StyledDealValueInput = styled.input`
    -webkit-appearance: none;
    font-style: normal;
    font-weight: normal;
    font-size: 16px !important;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #8896A8;
    padding-left: 12px !important;
    border: 1px solid #E5E8EF !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    background: #FFFFFF !important;
    margin: 0px !important;
    position: unset !important;
`;

class RightSideModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            deal:props.deal_value?props.deal_value:0,
            title:props.deal_title ? props.deal_title : '',
            contact: false,
            startDate : props.startDate,
            contact_id : props.contact_id,
            stages: [],
            pipeline: '',
            pipeline_id: 0,
            selectedPipeline: props.pipeline_id?props.pipeline_id:null,
            pipelines : [],
            percentage: [],
            Allpipeline : [],
            selectedStage : props.stage?props.stage:'',
            submitForm : false,
            new_stage_select: true,
            selectedStageTitle: '',
            // estimate_closing_date: props.estimate_closing_date?props.estimate_closing_date:props.startDate,
            estimate_closing_date: props.estimate_closing_date != null ? Date.parse(props.estimate_closing_date?props.estimate_closing_date:props.startDate) : null,
        };
        this.validator = new SimpleReactValidator();
    }

        startLoader=()=>{
        
        let modalWidth  = document.querySelector('.create__deal__modal').offsetWidth;
        let modalHeight = document.querySelector('.create__deal__modal').offsetHeight
        document.querySelector('.create__deal__modal').style.width = modalWidth+'px';
        document.querySelector('.create__deal__modal').style.height = modalHeight+'px';

        this.setState({
            submitForm : true
        })
        }

    handleSubmit = (event)  => {
        event.preventDefault();
        if(this.state.selectedStage == 0){
            this.setState({
                new_stage_select : false
            })
            return false;
        }else{
            this.setState({
                new_stage_select : true
            })
        }

        if( this.validator.allValid() ) {


            this.startLoader()
            var myEstimateClosingDate = moment(this.state.estimate_closing_date);

            if (myEstimateClosingDate.isValid()) {
                myEstimateClosingDate = myEstimateClosingDate.format('MMM DD, YYYY');
            } else {
                myEstimateClosingDate = null;
            }

            addNewDeal({
                closing_date : myEstimateClosingDate,
                contact_id : this.state.contact_id,
                deal : this.state.deal,
                title : this.state.title,
                stage : this.state.selectedStage,
                createNewContact : false

            } ).then(res => {
                this.setState({
                    submitForm : false
                })
                if(res.data.status == 'error'){

                } else{
                    this.props.onModalFormSubmit({
                        'update':true,
                        'pipeline_id':this.state.selectedPipeline,
                        'stage_id':this.state.selectedStage,
                        'selectedStageTitle':this.state.selectedStageTitle,
                        'deal_value':this.state.deal,
                        'estimate_closing_date':this.state.estimate_closing_date
                    });
                    this.props.toggle(true);
                }
            });
        }else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    getSelectedContact = (param) => {
        if(param == null){
            this.setState({
                selectContact : false,
                contact: false
            })
        }else{
            this.setState({
                selectContact : true,
                contactId: param.id,
                contact: true
            })
        }
    }

    selectOption = () => {
        var lists = this.state.stages.map(function (item, index) {
            return  <option key={index} value={item['id']} >
                {item['stage']}
            </option>
        }.bind(this));
        return lists;
    }

    toggleRightSideModal = () => {
        this.props.toggle(false);
    }
    handleChangeSelectStage = (e) => {
        this.setState({ stageId: e.target.value });
    }

    handleDayChange = (date) =>  {
        this.setState({ estimate_closing_date: date });
    }

    componentWillReceiveProps(nestProps){

        this.setState({
            isOpen: nestProps.isOpen,
            contact_id: nestProps.contact_id,
            modalShow: "show",
            popOpacity: "pop-opacity"
        });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    loadContent=()=>{
        getStageList(
            {
                pipeline_id: this.state.selectedPipeline
            })
            .then(
                response  => {
                    var stage = response.data['stages'];
                    var pipeline = response.data['pipeline'];
                    var pipelines = response.data['pipelines'];
                    var Allpipeline = response.data['Allpipeline'];
                    var pipelineId = response.data['pipelineId'];
                    this.setState((st)=>({
                        stages:stage,
                        pipeline: pipeline.title,
                        pipelines: pipelines,
                        Allpipeline : Allpipeline,
                        selectedPipeline : pipelineId,
                        data: true
                    }))

                    if(stage.length > 0){
                        this.setState((st)=>({
                            dataFound: true
                        }))
                    }else{
                        this.setState((st)=>({
                            dataFound: false
                        }))
                    }
                }
            );
    }

    selectOptionsval = (event) =>{
        if(event.target.value != null){
            this.setState({
                selectedPipeline : event.target.value
            }, () => this.loadContent());
        }
    }

    selectStageval = (event) =>{
        var index = event.nativeEvent.target.selectedIndex;
        this.setState({
            selectedStage : event.target.value,
            new_stage_select : true,
            selectedStageTitle : event.nativeEvent.target.innerText
        });
    }

    componentDidMount(){
        this.loadContent();
        this.setState({
            modalShow: "show",
            popOpacity: "pop-opacity"
        });
    }

    formatDate = (date, format, locale) =>  {
        return dateFnsFormat(date, format, { locale });
    }

    render() {

        this.validator.purgeFields();

        let pipelineOptions = [];

        this.state.Allpipeline.forEach((value, index) => {
            pipelineOptions.push(<MenuItem key={index} value={value.id}>{value.title}</MenuItem>);
        });

        let stageOptions = [];

        this.state.stages.forEach((value, index) => {
            stageOptions.push( <MenuItem key={index} value={value.id} >{value.stage}</MenuItem>);
        });

        return (
            // <React.Fragment>
                // <Drawer anchor={'right'} open={this.state.modalShow} onClose={this.toggleRightSideModal}>
                    // <Wrapper className="manage-tag-modal create-collaborate-modal create__deal__modal">
                    <GlobalModal {...this.props} 
                    title={"Create Deal"}
                    buttonText={"Save"}
                    buttonIcon={<Save/>}
                    onSubmit={this.handleSubmit}
                    hideFooter={this.state.submitForm ? true :false}
                    className={'manage-tag-modal create__deal__modal create-collaborate-modal activity__modal_overflow update global-medium-modal' }
                >
                        <Grid container >
                            {/* <Grid item xs={12}>
                                <ManageTagHeader className="manage-tag-header ">
                                    <h4>Add Deal</h4>
                                    <div className="modal-close cross-icon d-flex justify-content-center align-items-center" onClick={this.toggleRightSideModal}>
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.1262 17.1368L17.1367 15.125L6.87897 4.86052L4.86845 6.87237L15.1262 17.1368Z" fill="white"></path>
                                            <path d="M6.87898 17.1395L17.1367 6.875L15.1262 4.86315L4.86845 15.1276L6.87898 17.1395Z" fill="white"></path>
                                        </svg>
                                    </div>
                                </ManageTagHeader>
                            </Grid> */}

                            <Grid item xs={12} className="deal__tag__content">
                            {!this.state.submitForm && 

                                // <DealValueMain>
                                    <form  className="m-form m-form--fit m-form--label-align-right pt-3">
                                        <div className="form-group m-form__group">
                                            <label className="text-left d-block create__deal__modal__label" htmlFor="Deal Value">
                                                Deal Title
                                            </label>
                                            <input type="text" onChange={this.handleChange} placeholder="Enter title" value={this.state.title} name='title' className="create__deal__modal__input" />

                                        </div>
                                        <div className="form-group m-form__group">
                                            <label className="text-left d-block create__deal__modal__label" htmlFor="Deal Value">
                                                Deal Value
                                            </label>
                                            <input onChange={this.handleChange} type={'number'} min={0} placeholder={"Deal value"} value={this.state.deal} name='deal' className="create__deal__modal__input" />

                                        </div>
                                        <div className="form-group m-form__group">
                                            <label className="text-left d-block create__deal__modal__label" htmlFor="Select Stage">
                                                Select Pipeline
                                            </label>
                                            <FormControl variant="outlined" style={{width: '100%'}} >
                                                <Select className="create__deal__modal__select"
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={this.state.selectedPipeline}
                                                    onChange={this.selectOptionsval}
                                                >
                                                    {pipelineOptions}
                                                </Select>
                                            </FormControl>
                                            <p className="text-danger error-message" style={{color: 'red'}}>{ this.validator.message('pipeline', this.state.selectedPipeline, 'required')}</p>
                                        </div>
                                        <div className="form-group m-form__group">
                                            <label className="text-left d-block create__deal__modal__label" htmlFor="Select Stage">
                                                Select Stage
                                            </label>
                                            <FormControl variant="outlined" style={{width: '100%'}}>
                                                <Select className="create__deal__modal__select"
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={this.state.selectedStage}
                                                    onChange={this.selectStageval}
                                                >
                                                    <MenuItem value="">
                                                        <em>Select Stage</em>
                                                    </MenuItem>
                                                    {stageOptions}
                                                </Select>
                                            </FormControl>
                                            {
                                                !this.state.new_stage_select && (
                                                    <p className="text-danger error-message" style={{color: 'red'}}>Select stage</p>
                                                )
                                            }
                                            <p className="text-danger error-message" style={{color: 'red'}}>{ this.validator.message('stage', this.state.selectedStage, 'required')}</p>
                                        </div>
                                        <div className="form-group m-form__group create__deal__modal__group add__deal_day_picker">
                                            <label className="text-left d-block create__deal__modal__label" htmlFor="deals">
                                                Estimate Close Date
                                            </label>
                                            <DayPickerInput 
                                                style={{width: '100%'}}
                                                formatDate={this.formatDate}
                                                format={'MM/dd/yyyy'}
                                                name="closeDate"
                                                value={this.state.estimate_closing_date}
                                                onDayChange={this.handleDayChange}
                                                placeholder="MM/DD/YYYY"
                                                selected={null}
                                                keepFocus={false}
                                            />
                                            {/* <p className="text-danger error-message">{ this.validator.message('closeDate', this.state.estimate_closing_date, 'required')}</p> */}
                                        </div>
                                        {/* {
                                            !this.state.submitForm ? (
                                                <div className="m-portlet__foot m-portlet__foot--fit m-auto text-center">
                                                    <div className="m-form__actions create__deal__modal__action">
                                                        <button onClick={this.handleSubmit} type="submit" value="Submit" color="primary" type="reset" className="custom__modal--btn">
                                                            <i className="la la-plus"></i> Update Deal
                                                        </button>
                                                        <button onClick={this.toggleRightSideModal} type="reset" className="btn custom__modal--btn--close">
                                                            <i className="la la-times text-light"></i> Close
                                                        </button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <Loading/>
                                            )
                                        } */}
                                    </form>
                            }
                            {this.state.submitForm &&
                                <NewLoader loading={this.state.submitForm} onlyLoader={true} size={30} message={""} />

                            }

                            </Grid>
                        </Grid>
                        </GlobalModal>
            //         </Wrapper>
            //     </Drawer>
            // </React.Fragment>
        );
    }
}

export default RightSideModal;
import React from 'react';
import {Link} from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { pauseCampaign, runCampaign, unsubscribeCampaign } from '../../../../api/contactApi';
import PopperHelperNormal from '../Helpers/PopperhelperWithoutCheckbox';
import Utils from '../Helpers/Utils';
import { campaignPause, campaignStart } from '../redux/contactAction';
import Helper from '../Helpers/Helper';

const runningCampaignOptions = [{ id: 'pause', name: 'pause' }, { id: 'unsubscribe', name: 'unsubscribe' }]
const pauseCampaignOptions = [{ id: 'start', name: 'start' }, { id: 'unsubscribe', name: 'unsubscribe' }]

const Campaign = (props) => {

    const campaignIsPaused = (campaignId) => {
        if (Utils.getAccountData('userId') === parseInt(props.data?.campaign?.user_id)) {
            confirmAlert({
                title: 'Campaign is paused',
                message: 'Run the campaign to add contact',
                buttons: [
                    {
                        label: 'ok',
                        onClick: () => {
                            let data = {
                                campaignId : props.data?.campaign?.id,
                                campaign_id : props.data?.campaign?.id,
                                prevent_previous_followup: 0,
                                sent_immediate_after_resume: 1
                            }
                            data = {...data}
                            props.campaignStart(data, (response) => {
                                if(response.data.status === "error"){
                                    window.showNotification('ERROR',response.data.html);
                                }
                                else if(response.data.status === "success") {
                                    window.showNotification('SUCCESS','Campaign will start in a while')
                                }
                            })
                        }
                    },
                    {
                        label: 'cancel',
                        onClick: () => {
                            return;
                        }
                    }
                ]
            });
        } else {
            window.showNotification("ERROR",'You are not allow to do this as you are not owner of this campaign.')
        }
    }

    const toStart = () => {

        if (Utils.getAccountData('userId') === parseInt(props.data?.campaign?.user_id)) {
            confirmAlert({
                title: 'Confirm to run?',
                message: 'Do you want to run this campaign for the contact?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            runCampaign({
                                contact_id: props.contact_id,
                                campaign_id: props.data?.campaign?.id
                            }).then(res => {
                                if(res.data){
                                    window.showNotification('SUCCESS',"Contact will run in a while")
                                }else{
                                    window.showNotification('ERROR',"Something went wrong")

                                }
                            });
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            return;
                        }
                    }
                ]
            })
        } else {
            window.showNotification("ERROR",'You are not allow to do this as you are not owner of this campaign.' )
        }

    }

    const toPause = () => {

        if (Utils.getAccountData('userId') === parseInt(props.data?.campaign?.user_id)) {

            confirmAlert({
                title: 'Confirm to pause',
                message: 'Do you want to pause this campaign for the contact?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            pauseCampaign({
                                contact_id: props.contact_id,
                                campaign_id: props.data?.campaign?.id
                            }).then(res => {
                                if(res.data){
                                    window.showNotification('SUCCESS',"Contact will pause in a while")
                                }
                                else{
                                    window.showNotification('ERROR',"Something went wrong")

                                }
                            });
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            return;
                        }
                    }
                ]
            });

        } else {
            window.showNotification("ERROR",'You are not allow to do this as you are not owner of this campaign.')
        }
    }

    const toUnsub = () => {

        if (Utils.getAccountData('userId') === parseInt(props.data?.campaign?.user_id)) {

            confirmAlert({
                title: 'Confirm to unsubscribe',
                message: 'Do you want to unsubscribe this contact?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            unsubscribeCampaign({
                                contact_id: props.contact_id,
                                campaign_id: props.data?.campaign?.id
                            }).then(res => {
                                if(res.data){
                                    window.showNotification('SUCCESS',"Contact will unsubscribe in a while")

                                }else{
                                    window.showNotification('ERROR',"Something went wrong")
                                }
                            });
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                            return;
                        }
                    }
                ]
            })

        } else {
            window.showNotification("ERROR",'You are not allow to do this as you are not owner of this campaign.')
        }
    }

    const onSubmit = (action) => {
        if (action === 'start') {
            toStart()
        } else if (action === 'pause') {
            toPause()
        } else if (action === 'unsubscribe') {
            toUnsub()
        }
    }

    let isRunning = false;
    let unsubscribe = false;
    let isPaused = false;
    let msg = '';

    if ((props.data.subscription[0] && props.data?.subscription[0]?.is_unsubscribed === 1)) {
        isRunning = false;
        unsubscribe = true;
        msg = 'Unsubscribed';
    } else {
        if (props.data.campaign != null) {
            if (props.data?.campaign?.status === 3) {

                const pauseContactArr = [2, 4, 7, 8, 9, 10];

                if (props.data?.status === 1) {
                    isRunning = true;
                    msg = 'Running';
                } else if (pauseContactArr.includes(props.data?.status)) {
                    isRunning = false;
                    isPaused = true;
                    msg = 'Paused';
                } else if (props.data?.status === 6) {
                    isRunning = false;
                    isPaused = false;
                    unsubscribe= true;
                    msg = 'Complete';
                }
            } else {
                isRunning = false;
                msg = 'Campaign Paused';
            }
        } else {
            isRunning = false;
            msg = 'Campaign Paused';
        }

    }

    return (
        <div class="d-flex justify-content-between align-items-center v2_single__contact_campaign_item" >
            <Link to={`/campaign/details/${props.data?.campaign?.id}`} target="_blank">
                <h6 class="text-gray cursor-pointer">{Helper.reduceTextLength(props.data?.campaign?.title,20)}</h6>
            </Link>

            {isRunning && (

                <PopperHelperNormal
                    buttonText={"My Lists"}
                    buttonAction={false}
                    mapping={{ id: "id", title: "name" }}
                    icon={'pause_arrow'}
                    datalist={runningCampaignOptions}
                    onSubmit={onSubmit}
                    selectedList={[1]}
                    checkBoxNeeded={false}
                    showAll={false}
                    msg={msg}
                    popover={true}
                />
            )}
            {isPaused && (

                <PopperHelperNormal
                    buttonText={"My Lists"}
                    buttonAction={false}
                    mapping={{ id: "id", title: "name" }}
                    icon={'play_arrow'}
                    datalist={pauseCampaignOptions}
                    onSubmit={onSubmit}
                    selectedList={[1]}
                    checkBoxNeeded={false}
                    showAll={false}
                    msg={msg}
                    popover={true}
                />
            )}
            {!isRunning && !unsubscribe && !isPaused && (
                <div class="running_btn_campaigns d-flex align-items-center bg-green px-2" onClick={() => campaignIsPaused(props.data?.campaign_id)} >
                    <i class="material-icons white-text">
                        play_arrow
                    </i>
                    <span class="white-text mx-2">{msg}</span>
                    <i class="material-icons white-text">
                        expand_more
                    </i>
                </div>
            )}
            {!isRunning && unsubscribe && !isPaused && (
                <div class="running_btn_campaigns d-flex align-items-center bg-green px-2">
                    <span class="white-text mx-2">{msg}</span>
                </div>
            )}
        </div>
    );
}

const mapStateToProps = state => {
    return {

    };
};
const mapDispatchToProps = dispatch => {
    return {
        campaignStart: (params, callback) => dispatch(campaignStart(params, callback)),
        campaignPause: (params, callback) => dispatch(campaignPause(params, callback))
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(Campaign);

import { Drawer, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import Loading from '../../../common/Loading';
import GlobalModal from "../../../globals/Modal/GlobalModal";

const style = {
    zIndex: "10000000"
};


const Wrapper = styled.div`
    margin: 10px;
    max-width: 500px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E5E8EF;
`;

const FormEntryDetails = (props) => {
    const [show, setShow] = useState(true);

    const closeModal = () => {
        setShow(false);
        setTimeout(() => {
            props.closeModal();
        }, 800);
    }

    const populateStatus = (status) => {
        var text = 'Error';
        var badge = 'm-badge--danger';
        if (status == 1) {
            text = 'Inserted';
            badge = 'm-badge--success';
        } else if (status == 2){
            text = 'Duplicate';
            badge = 'm-badge--warning';
        }

        return <span className={`m-badge m-badge--wide ${badge}`}>{text}</span>;
    }

    const populateSubmission = (submissions) => {
        return submissions.map((submission, index) => (
            <React.Fragment>
                {
                    (submission[Object.getOwnPropertyNames(submission)] !== null) && (
                        <tr className="m-datatable__row" style={{left: '0px'}}>
                            <td className="m-datatable__cell" style={{width: '210px'}}><span><strong>{Object.getOwnPropertyNames(submission)}</strong></span></td>
                            <td className="m-datatable__cell"><span style={{width: '100%'}}>{submission[Object.getOwnPropertyNames(submission)]}</span></td>
                        </tr>
                    )
                }
            </React.Fragment>
        ));
    }

    return (
        <GlobalModal {...props}
                     title={"Entry Details"}
                     hideFooter={true}
                     open={props.open}
                     onClose={props.closeModal}
                     className={'manage-tag-modal create__deal__modal create-collaborate-modal activity__modal_overflow update global-medium-modal' }>
            <Grid container>
                <Grid item xs={12}>
                    {
                        ((props.entry && props.entry !== null)) ? (
                            <React.Fragment>
                                <div className="m-datatable m-datatable--default m-datatable--brand m-datatable--loaded py-2">
                                    <table className="m-datatable__table" id="html_table" width="100%">
                                        <thead className="m-datatable__head">
                                        <tr className="m-datatable__row" style={{left: '0px'}}>
                                            <th className="m-datatable__cell m-datatable__cell--sort"><span
                                                style={{width: '100%'}}>{props.entry.form_builder.title}</span></th>

                                        </tr>
                                        </thead>
                                        <tbody className="m-datatable__body">
                                        <tr className="m-datatable__row" style={{left: '0px'}}>
                                            <td data-field="OrderID" className="m-datatable__cell" style={{width: '210px'}}><span><strong>Submitted</strong></span></td>
                                            <td data-field="Owner" className="m-datatable__cell"><span style={{width: '100%'}}>{props.entry.created_at}</span></td>
                                        </tr>
                                        <tr className="m-datatable__row m-datatable__row--even" style={{left: '0px'}}>
                                            <td data-field="OrderID" className="m-datatable__cell" style={{width: '210px'}}><span><strong>Status</strong></span></td>
                                            <td data-field="Owner" className="m-datatable__cell"><span style={{width: '100%'}}>{populateStatus(props.entry.status)}</span></td>
                                        </tr>
                                        <tr className="m-datatable__row m-datatable__row--even" style={{left: '0px'}}>
                                            <td data-field="OrderID" className="m-datatable__cell" style={{width: '210px'}}><span><strong>Message</strong></span></td>
                                            <td data-field="Owner" className="m-datatable__cell"><span style={{width: '100%'}}>{props.entry.comment}</span></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="m-datatable m-datatable--default m-datatable--brand m-datatable--loaded pt-5">
                                    <table className="m-datatable__table" id="html_table" width="100%">
                                        <thead className="m-datatable__head">
                                        <tr className="m-datatable__row" style={{left: '0px'}}>
                                            <th className="m-datatable__cell m-datatable__cell--sort"><span
                                                style={{width: '100%', color: "violet"}}>Submission Details</span></th>

                                        </tr>
                                        </thead>
                                        <tbody className="m-datatable__body">
                                        {
                                            populateSubmission(JSON.parse(props.entry.entries))
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </React.Fragment>
                        ) : (
                            <div className="text-center" style={{width: '400px'}}>
                                <Loading/>
                            </div>
                        )
                    }
                </Grid>
            </Grid>
        </GlobalModal>
    )
}

export default FormEntryDetails;

import React, {useEffect, useState} from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {connect} from "react-redux";
import {setContactDetails} from "../redux/contactAction";
import NewConfirmAlert from "../../../common/new-alert/NewConfirmAlert";
import {updateNumberDncStatusApi} from "../../../../api/contactApi";
import "./dnc.css";
import DNCLogModal from "./DNCLogModal";

const DNC = (props) => {
    const [doNotCallStatus, setDoNotCallStatus] = useState(false);
    const [showDncLogModal, setShowDncModal] = useState(false);
    const [contactName, setContactName] = useState("");

    useEffect(()=>{
        let contactAdditionalInformation = props.contact.contact_additional_informations;
        if (contactAdditionalInformation && contactAdditionalInformation["dnc_status"] === "DO_NOT_CALL"){
            setDoNotCallStatus(true);
        }else {
            setDoNotCallStatus(false);
        }
    },[
        props.contact.id,
        props.contact.contact_additional_informations &&
        props.contact.contact_additional_informations["dnc_status"]
    ]);

    useEffect(()=>{
        let name = "";

        if (props.contact.first_name){
            name += props.contact.first_name;
        }

        if (props.contact.last_name){
            if (name){
                name += " ";
            }
            name += props.contact.last_name;
        }

        if (!name){
            name = props.contact.number;
        }

        setContactName(name);
    },[props.contact.first_name,props.contact.last_name,props.contact.number]);

    const updateNumberDncStatus = async (callback=null) => {
        NewConfirmAlert({
            onSubmit : async () => {
                try{
                    const res = await updateNumberDncStatusApi({
                        "contact-id": props.contact ? props.contact.id : null,
                        "phone": props.contact ? props.contact.number : null,
                        "dnc-status": !doNotCallStatus ? 'DO_NOT_CALL' : 'VERIFIED_OK'
                    });

                    if (res && res.success && res.data && res.data.dnc_status){
                        setDoNotCallStatus(!doNotCallStatus);
                        let tmpContact = { ...props.contact };
                        if(tmpContact.contact_additional_informations){
                            tmpContact.contact_additional_informations.dnc_status = res.data.dnc_status;
                            tmpContact.contact_additional_informations.dnc_validation_infos = null;
                        }else{
                            tmpContact.contact_additional_informations = {
                                dnc_status : res.data.dnc_status, dnc_validation_infos : null
                            }
                        }
                        props.setContactDetails(tmpContact);
                        window.showNotification("success", res.message);
                    }else {
                        window.showNotification("error", "Unable to update dnc status! Try again later")
                    }
                }catch (err){
                    window.showNotification("error", "Something went wrong! Contact with administrator");
                }
            },
            title: "Are you sure?",
            description: `You are about to ${!doNotCallStatus ? 'add' : 'remove'} this contact number ${!doNotCallStatus ? 'to' : 'from'} DNC list`,
            cancelText: "Cancel",
            submitText: "Ok, Confirm",
            width: "480px"
        });
    }

    return(
        <React.Fragment>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <FormControlLabel
                        aria-label="Acknowledge"
                        control={
                            <React.Fragment>
                                <div className="collaborator__list__parant">
                                    <div className="collaborator__list__left">
                                        <span className="collaborator__left__icon">
                                            <svg width="23" height="23" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88">
                                                <path d="M68.42,36l-.22,0c-3.47-.63-2.66-5.88.85-5.45a38.07,38.07,0,0,1,5.43,1.09,30.12,30.12,0,0,1,4.81,1.81A22.49,22.49,0,0,1,91.36,47.62a29,29,0,0,1,1,5,37.14,37.14,0,0,1,.2,5.55v.09c-.23,3.55-5.64,3.3-5.51-.28a32.89,32.89,0,0,0-.16-4.61,24.27,24.27,0,0,0-.79-4.12,17,17,0,0,0-9.32-11,25,25,0,0,0-3.9-1.44A31.29,31.29,0,0,0,68.42,36ZM47.26,57.58A47.57,47.57,0,0,0,53.65,67.9a40.36,40.36,0,0,0,10.62,9,1.14,1.14,0,0,0,1,.11,4.09,4.09,0,0,0,1.4-.89A13.92,13.92,0,0,0,68,74.66c2-2.24,4.36-5,7.4-3.35l.18.11,10.06,6.69.09.08a4.7,4.7,0,0,1,1.7,4.19,11.1,11.1,0,0,1-1.87,5.08,10.59,10.59,0,0,1-5.32,4.11,22.78,22.78,0,0,1-6.3,1.28,21.1,21.1,0,0,1-9.41-1.4,42.44,42.44,0,0,1-9.22-5.22L55,86.06c-1.48-1.07-3.08-2.21-4.64-3.54a60.91,60.91,0,0,1-14.9-19.31c-2.94-6.26-4.34-12.9-2.94-19.05a13.51,13.51,0,0,1,5.12-8.25,12.29,12.29,0,0,1,9.25-1.84,1.31,1.31,0,0,1,1,.74l6,11.76a3.55,3.55,0,0,1,.32,3.83,7.41,7.41,0,0,1-2.75,2.7c-.38.29-.83.57-1.3.87-1.57,1-3.36,2.13-2.87,3.65v0Zm17.43-9.35c-3.53-.61-2.72-5.9.81-5.46l.18,0a23.17,23.17,0,0,1,3.11.77,18.74,18.74,0,0,1,2.82,1.17l0,0A15.91,15.91,0,0,1,80,56.34v0a22.77,22.77,0,0,1,.34,3.18c.15,3.61-5.36,3.85-5.51.21v0c-.19-5.37-2.73-9.28-7.9-10.95a15.35,15.35,0,0,0-2.29-.55Z"/>
                                                <path fill="#d92d27" d="M61.44,0A61.31,61.31,0,1,1,38,4.66,61.29,61.29,0,0,1,61.44,0Zm40.24,32.93L32.93,101.68A49.44,49.44,0,0,0,80.31,107,49.53,49.53,0,0,0,107,80.3a49,49,0,0,0,3.73-18.86h0a48.93,48.93,0,0,0-9.08-28.51ZM24,93.5,93.5,24A49.32,49.32,0,0,0,24,93.5Z"/>
                                            </svg>
                                        </span>
                                        <span className="collaborator__list--title text-dark-blue m-0">DNC </span>
                                    </div>
                                    <div className="collaborator__list__right" onClick={(event) => event.stopPropagation()}>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                     label=""/>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <div className="d-flex justify-content-between py-3">
                            <span className="font-style-italic">
                                Add to Do Not Call list:
                            </span>
                            <span className="dnc-log-button" onClick={()=> {
                                setShowDncModal(true);
                            }}>
                                Log
                            </span>
                        </div>
                        <label>
                            <input type="checkbox" onChange={updateNumberDncStatus} checked={doNotCallStatus} className="filled-in"/>
                            <span>Phone Calls and Ringless Voicemails</span>
                        </label>
                    </div>
                </AccordionDetails>
            </Accordion>
            {
                showDncLogModal &&
                <DNCLogModal
                    contactName={contactName}
                    contactId={props.contact.id}
                    isOpen={showDncLogModal}
                    onClose={()=>{
                        setShowDncModal(false);
                    }}
                />
            }
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {
        contact: state.rightsideReducer.contact
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setContactDetails: (params) => dispatch(setContactDetails(params)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DNC);
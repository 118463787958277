import React, { Component } from "react";
import RightSideModal from "./RightSideModal";
import RightSideModalEdit from "./RightSideModalEdit";
import { confirmAlert } from "react-confirm-alert";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Avatar from "@material-ui/core/Avatar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import { changeDealStatus } from "../redux/contactApi";
import Utils from "../Helpers/Utils";
import StyledAccordion from "../../../common/StyledAccordion";
import {
  getCampaignStages,
  getPolicyData,
  getUserProductActiveList,
} from "../../../../api/contactApi";
import { DEAL_STATUS } from "../helper/coreConstant";
import {AGENCY_BUSINESS_TYPE} from "../../../../constants/CoreConstants";

const deal_currency = "$";

const styles = (theme) => ({
  formControl: {
    margin: "8px 0",
    minWidth: 150,
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    alignItems: "center",
  },
  selectEmpty: {
    //   marginTop: theme.spacing(2),
  },
  select: {
    minWidth: 90,
    border: "1px solid #000",
    borderRadius: "5px",
    height: "29px",
    "& .MuiSelect-select":{
        fontSize:'14px !important',
        padding:'4px 6px'
    },
    borderWidth: "1px !important"
  },
  flexContainer: {
    width: "100%",
    marginTop: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "20px",
  },
  won_btn: {
    cursor: "pointer",
    borderRadius: "5px",
    padding: "8px 16px",
    background: "#00FF91",
    color: "black",
    fontWeight: "500",
    textAlign: "center",
    border: "0px",
    "&:focus": {
      backgroundColor: "#00FF91",
    },
  },
  lost_btn: {
    cursor: "pointer",
    borderRadius: "5px",
    padding: "8px 16px",
    background: "#EB5757",
    color: "white",
    fontWeight: "500",
    textAlign: "center",
    border: "0px",
    "&:focus": {
      backgroundColor: "#EB5757",
    },
  },
  dealContainer: {
    display: "flex",
    alignItems: "start",
    gap: "4px",
  },
  dealTitle: {
    whiteSpace: "normal",
    overflow: "visible",
    maxHeight: "none",
    color: "#546376",
    fontSize: "14px",
    wordBreak:'break-word'
  },
  dealValueBox: {
    width: "100%",
    marginTop: "16px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  dealText: {
    color: "#546376",
    width: "100%",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textWrap: "wrap",
    fontSize: "14px",
  },
  mainContainer: {
    marginTop: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
  },
  btnContainer: {
    display: "flex",
    gap: "10px",
    alignItems: "start",
    justifyContent: "end",
    marginLeft: "10px",
  },
  productDeal: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  eachDeal: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },
  productTitle: {
    width: "70%",
    border: "1px solid #000",
    borderRadius: "5px",
    padding: "4px 8px",
  },
  productValue: {
    width: "30%",
    border: "1px solid #000",
    borderRadius: "5px",
    padding: "4px 8px",
  },
  dealTextStyle: {
    fontSize: "15px",
    fontWeight: "600",
    color: "#181f48 ",
  },
  dealValueText:{
    fontSize: "15px",
    fontWeight: "600 !important",
    fontColor: "#181f48 !important",
  }
});

const EditOutlinedIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="#000"
    class="bi bi-pencil-square"
    viewBox="0 0 16 16"
  >
    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path>
    <path
      fill-rule="evenodd"
      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
    ></path>
  </svg>
);

class AssignToStageV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showEditModal: false,
      contact_id: props.contact_id,
      assigned_stage: false,
      stage: [],
      is_replace: false,
      edit_deal: 0,
      activeTab: "open",
      filter: 1,
      productList: [],
    };
  }

  componentDidMount() {
    this.setState({
      contact_id: this.props.contact_id,
    });
    this.fetchProductList().then();
    this.fetchCampaignStages().then();
  }
  // /
  fetchProductList = async () => {
    try {
      const isAgencyTypeInsurance = Utils.getAccountData("agencyBusinessType") === AGENCY_BUSINESS_TYPE.INSURANCE;
      const productList =  await (isAgencyTypeInsurance ?  getPolicyData() :  getUserProductActiveList());
      this.setState({ productList: productList.data });
    } catch (error) {
      console.error("Failed to fetch product list", error);
    }
  };

  fetchCampaignStages = async () => {
    try {
      const stages = await getCampaignStages({
        contact_id: this.props.contact_id,
      });

      this.setState({ stage: stages.data });
    } catch (error) {
      console.error("Failed to fetch campaign stages", error);
    }
  };

  openRightSideModal = () => {
    if (this.props.people.user_id == this.props.loggedOnUser.id) {
      // this.setState({ showModal: true });

      window.globalAddDeal(true, {
        callback: (data) => {
          if (data) {
            this.props.reloadTopSection();
            this.props.refreshData();
            this.fetchCampaignStages().then();
          }
        },
        data: {
          contact_id: this.props.contact_id,
        },
        edit: false,
      });
    } else {
      window.showNotification(
        "ERROR",
        "This lead isn’t assigned to you. Only the lead owner can create or edit deals."
      );
    }
  };

  openRightSideEditModal = (deal) => {
    if (deal.status === 1) {
      if (this.props.people.user_id == this.props.loggedOnUser.id) {
        // this.setState({ edit_deal: deal, showEditModal: true });
        window.globalAddDeal(true, {
          callback: (data) => {
            if (data) {
              this.props.reloadTopSection();
              this.props.refreshData();
              this.fetchCampaignStages().then();
            }
          },
          data: {
            title: deal.title,
            contact_id: this.props.contact_id,
            pipelineId: deal.stage.pipeline_id,
            user_stage_id: deal.stage.id,
            contact_stages_id: deal.id,
            deal_value: deal.deal_value,
            estimate_closing_date: deal.estimate_closing_date,
          },
          edit: true,
        });
      } else {
        window.showNotification(
          "ERROR",
          "This lead isn’t assigned to you. Only the lead owner can create or edit deals."
        );
      }
    }
  };

  toggleRightSideModal = (status) => {
    this.setState({
      showModal: false,
    });
    if (status) {
      this.setState({
        assigned_stage: true,
      });
    }
    this.props.refreshData();
  };

  toggleRightSideModalEdit = (status) => {
    this.setState({
      showEditModal: false,
    });
    if (status) {
      this.setState({
        assigned_stage: true,
      });
    }
    this.props.refreshData();
  };

  onModalFormSubmit = (state) => {
    if (state.update) {
      this.props.reloadTopSection();
      this.props.refreshData();
    }
  };

  changeVisibility = () => {
    this.setState({
      show: !this.state.show,
    });
  };

  /**
   * @param {int} contactStageId
   * @param {int} status | 2 = win, 3 = Lost
   */
  dealMarkAsWinLose = (contactStageId, status) => {
    if (status === DEAL_STATUS.WON && Utils.getAccountData("agencyBusinessType") === AGENCY_BUSINESS_TYPE.INSURANCE){
      window.globalInsuranceDealStatus(true, {
        callback: (res) => {
            this.props.reloadTopSection();
            this.props.refreshData();
            this.fetchCampaignStages().then();
        },
        contact_stages_id: contactStageId,
        status: DEAL_STATUS.WON
      });
    }else{
      window.globalDealStatus(true, {
        callback: (data) => {
          if (data){
            this.props.reloadTopSection();
            this.props.refreshData();
            this.fetchCampaignStages().then();
            if (status === DEAL_STATUS.LOST && Utils.getAccountData("agencyBusinessType") === AGENCY_BUSINESS_TYPE.INSURANCE){
              window.globalInsuranceDealStatusLost(true, {
                callback: (res) => {
                },
                contact_stages_id: contactStageId,
                contact_id: this.props.contact_id
              });
            }
          }
        },
        contact_stages_id: contactStageId,
        status: status
      });
    }
    
    // confirmAlert({
    //   title: "Alert!",
    //   message: status === 2 ? "Close this deal as win?" : "Close this deal as lost?",
    //   buttons: [
    //     {
    //       label: "Yes",
    //       onClick: () => {
    //         changeDealStatus({
    //           stage_id: contactStageId,
    //           status: status,
    //         }).then((response) => {
    //           this.props.reloadTopSection();
    //           this.props.refreshData();
    //           this.fetchCampaignStages().then();
    //         });
    //       },
    //     },
    //     {
    //       label: "No",
    //       onClick: () => {
    //         return false;
    //       },
    //     },
    //   ],
    // });
  };

  // componentWillReceiveProps(nextProps) {
  //   this.setState({
  //     stage: nextProps.contactStages,
  //   });
  // }

  getProductTitle = (id) => {
    const products = this.state.productList?.find(
      (product) => product.id === id
    );
    return products ? products.title : "Unknown";
  };
  populateDeals = () => {
    let totalValue = 0;
    const filteredList = this.state.stage.filter(
      (contact) => contact.status === this.state.filter
    );

    if (filteredList.length > 0) {
      return filteredList.map((eachStage, index) => {
        return (
          <div style={{ width: "100%" }}>
            <div className={this.props.classes.mainContainer}>
              <div style={{width:'60%'}}>
                <span>
                  {eachStage.title ? (
                    <span className={this.props.classes.dealContainer}>
                      <span className={this.props.classes.dealTextStyle}>
                        Deal Name:{" "}
                        <span className={this.props.classes.dealTitle}>
                          {" "}
                          {eachStage.title}
                        </span>
                      </span>
                      {eachStage.status === 1 && (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.openRightSideEditModal(eachStage);
                          }}
                        >
                          {EditOutlinedIcon}
                        </span>
                      )}
                    </span>
                  ) : (
                    <> <span>no title</span>  {eachStage.status === 1 && (
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => this.openRightSideEditModal(eachStage)}
                      >
                        {EditOutlinedIcon}
                      </span>
                    )}</>
                  )}
                </span>
                <div style={{display:'flex',gap:'4px'}}>
                  {" "}
                  <span className={this.props.classes.dealTextStyle}>Pipeline:</span>{" "}
                 <Tooltip title={eachStage?.stage?.pipeline?.title}>
                 <span className={this.props.classes.dealText}>
                  {eachStage.stage &&
                  eachStage.stage.pipeline &&
                  eachStage.stage.pipeline.title
                    ? eachStage.stage.pipeline.title
                    : "N/A"}
                  </span>
                 </Tooltip>
                </div>
                <div style={{display:'flex',gap:'4px'}}>
                  {" "}
                  <span className={this.props.classes.dealTextStyle}>Stage:</span>
                  <Tooltip title={eachStage?.stage?.stage}>
                  <span className={this.props.classes.dealText}>
                  {eachStage.stage && eachStage.stage.stage
                    ? eachStage.stage.stage
                    : "N/A"}
                  </span>
                  </Tooltip>
                </div>
              </div>
              <div className={this.props.classes.btnContainer}>
                {eachStage.status !== 2 && (
                  <button
                    className={this.props.classes.won_btn}
                    onClick={() => {
                      this.dealMarkAsWinLose(eachStage.id, 2);
                    }}
                  >
                    Won
                  </button>
                )}
                {eachStage.status !== 3 && (
                  <button
                    className={this.props.classes.lost_btn}
                    onClick={() => {
                      this.dealMarkAsWinLose(eachStage.id, 3);
                    }}
                  >
                    Lost
                  </button>
                )}
              </div>
            </div>
            <div className={this.props.classes.dealValueBox}>
              <span style={{ color: "#3C7EF3",fontWeight:'600' }}>
                <span className={this.props.classes.dealValueText}>Deal Value: </span>
                {Utils.calculateCash(eachStage.deal_value)}
              </span>
              <Divider style={{ width: "56%" }} />
            </div>
            <div style={{ margin: "12px 0px" }}>
              <Typography
                className={this.props.classes.dealTextStyle}
                style={{ marginBottom: "12px" }}
              >
               { Utils.getAccountData("agencyBusinessType") === AGENCY_BUSINESS_TYPE.INSURANCE ? "Lines of business associated with the deal" : "Products associated with the deal"}
              </Typography>
              {eachStage.user_contact_stage_products &&
              eachStage.user_contact_stage_products.length > 0 ? (
                <div className={this.props.classes.productDeal}>
                  {eachStage.user_contact_stage_products.map((eachProduct) => {
                                        totalValue += parseFloat(eachProduct.value);
                    return (
                      <div
                        key={eachProduct.id}
                        className={this.props.classes.eachDeal}
                      >
                        <Typography className={this.props.classes.productTitle}>
                          {this.getProductTitle(eachProduct.user_product_id)}
                        </Typography>
                        <Typography className={this.props.classes.productValue}>
                          {Utils.calculateCash(parseFloat(eachProduct.value))}
                        </Typography>
                      </div>
                    );
                  })}
                  {totalValue < eachStage.deal_value && (
                    <div className={this.props.classes.eachDeal}>
                      <Typography className={this.props.classes.productTitle}>
                        Unknown
                      </Typography>
                      <Typography className={this.props.classes.productValue}>
                        {Utils.calculateCash(
                          eachStage.deal_value - totalValue
                        )}
                      </Typography>
                    </div>
                  )}
                </div>
              ) : (
                <div className={this.props.classes.eachDeal}>
                  <Typography className={this.props.classes.productTitle}>
                    Unknown
                  </Typography>
                  <Typography className={this.props.classes.productValue}>
                    {Utils.calculateCash(eachStage.deal_value)}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="no-deal-div">
          <span style={{display:"flex",justifyContent:"center",padding:"10px"}}>No deal</span>
        </div>
      );
    }
  };

  getState() {
    return this.state.stage.map((eachStage, index) => {
      return (
        <div className="m-portlet mb-2" key={index}>
          <div className="m-portlet__body p-0">
            <div className="m-list-search__result-item">
              <span className="m-list-search__result-item-icon">
                {eachStage.status == 1 && (
                  <i className="la la-codepen m--font-info"></i>
                )}
                {eachStage.status == 2 && (
                  <i className="fa fa-border	fa-trophy m--font-success"></i>
                )}
                {eachStage.status == 3 && (
                  <i className="fa fa-border 	fa-frown-o m--font-danger"></i>
                )}
              </span>
              <span
                className="m-list-search__result-item-text"
                style={{ fontSize: "12px", cursor: "pointer" }}
              >
                <a
                  href="javascript:void(0)"
                  onClick={() => {
                    this.openRightSideEditModal(eachStage);
                  }}
                >
                  {eachStage.title}
                </a>
              </span>
              {eachStage.status == 1 ? (
                <span
                  className="m-list-search__result-item-text"
                  style={{ fontSize: "12px", cursor: "pointer" }}
                >
                  <a
                    href="javascript:void(0)"
                    onClick={() => {
                      this.openRightSideEditModal(eachStage);
                    }}
                  >
                    {eachStage.stage.stage} {deal_currency}
                    {Utils.abbreviateNumber(eachStage.deal_value)}
                  </a>
                  <a
                    href="javascript:void(0)"
                    className="pl-1"
                    onClick={() => {
                      this.dealMarkAsWinLose(eachStage.id, 2);
                    }}
                  >
                    <i className="fa fa-border 	fa-trophy m--font-success"></i>
                  </a>
                  <a
                    href="javascript:void(0)"
                    className="pl-1"
                    onClick={() => {
                      this.dealMarkAsWinLose(eachStage.id, 3);
                    }}
                  >
                    <i className="fa fa-border 	fa-frown-o m--font-danger"></i>
                  </a>
                </span>
              ) : (
                <span
                  className="m-list-search__result-item-text"
                  style={{ fontSize: "12px", cursor: "pointer" }}
                >
                  <a href="javascript:void(0)">
                    {eachStage.stage.stage} {deal_currency}
                    {Utils.abbreviateNumber(eachStage.deal_value)}
                  </a>
                </span>
              )}
            </div>
          </div>
        </div>
      );
    });
  }

  render() {
    let show = this.state.show ? "accordion-wrapper show" : "accordion-wrapper";

    return (
      <React.Fragment>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <FormControlLabel
              aria-label="Acknowledge"
              // onClick={(event) => event.stopPropagation()}
              // onFocus={(event) => event.stopPropagation()}
              control={
                <React.Fragment>
                  <div className="collaborator__list__parant">
                    <div className="collaborator__list__left">
                      {" "}
                      <span className="collaborator__left__icon">
                        <svg
                          className="text-dark-blue"
                          width="23"
                          height="16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.829 11.196a.62.62 0 0 0 .057-.876l-.035-.039a.618.618 0 0 0-.426-.208h-.041a.618.618 0 0 0-.41.154l-.833.73-.759.666a.621.621 0 0 0-.056.875l.035.04c.227.257.62.282.877.056l.584-.51 1.007-.888z"
                            fill="#fff"
                          />
                          <path
                            d="M5.83 12.892c-.018 0-.035 0-.052-.002a.765.765 0 0 1-.529-.26l-.035-.04a.77.77 0 0 1 .07-1.084l1.592-1.396a.774.774 0 0 1 1.087.07l.05.056a.769.769 0 0 1-.085 1.068l-1.592 1.397a.763.763 0 0 1-.507.19zm1.554-2.674a.473.473 0 0 0-.311.118L5.48 11.733a.473.473 0 0 0-.043.666l.035.04a.474.474 0 0 0 .667.043l1.592-1.397a.472.472 0 0 0 .043-.666l-.035-.04a.468.468 0 0 0-.324-.159l-.032-.002zm2.676 2.528a.62.62 0 0 0-.41.153l-.821.72a.62.62 0 1 0 .82.934l.822-.72a.62.62 0 0 0-.41-1.087z"
                            fill="#fff"
                          />
                          <path
                            d="M9.24 14.858a.77.77 0 0 1-.509-1.347l.822-.72a.795.795 0 0 1 1.088.07.77.77 0 0 1-.071 1.086l-.822.72a.768.768 0 0 1-.508.191zm.821-1.96a.472.472 0 0 0-.313.115l-.821.72a.472.472 0 1 0 .624.71l.822-.719a.472.472 0 0 0-.312-.827zM2.733 1.363l-1.67 7.276 1.335.75 3.456-5.613-3.121-2.413z"
                            fill="#fff"
                          />
                          <path
                            d="M2.448 9.587.89 8.714l1.747-7.612 3.41 2.637-3.6 5.848zM1.23 8.563l1.114.624L5.655 3.81 2.824 1.622 1.23 8.563zm4.666 1.786a.62.62 0 0 0 .057-.877l-.01-.012a.617.617 0 0 0-.427-.21h-.042a.618.618 0 0 0-.41.155l-.997.872a.62.62 0 0 0-.057.876.626.626 0 0 0 .888.07l.076-.067.76-.665.162-.142z"
                            fill="#fff"
                          />
                          <path
                            d="M4.494 11.53c-.019 0-.038 0-.058-.003a.791.791 0 0 1-.537-.27.769.769 0 0 1 .07-1.086l.997-.874a.772.772 0 0 1 1.087.07.773.773 0 0 1-.06 1.1l-.996.872a.758.758 0 0 1-.503.19zm.98-2.127a.472.472 0 0 0-.312.12l-.997.872a.472.472 0 0 0-.043.667c.085.099.206.16.336.17a.463.463 0 0 0 .342-.114l.996-.873a.472.472 0 0 0 .044-.667.48.48 0 0 0-.336-.172l-.03-.003zM9.37 12.09a.616.616 0 0 0-.153-.45l-.01-.01a.617.617 0 0 0-.425-.208h-.041a.618.618 0 0 0-.41.154l-.097.086-1.008.883-.268.234a.63.63 0 0 0 .378 1.096.616.616 0 0 0 .451-.153l1.373-1.203a.619.619 0 0 0 .21-.429z"
                            fill="#fff"
                          />
                          <path
                            d="M7.378 14.02c-.017 0-.034 0-.05-.002a.777.777 0 0 1-.468-1.357l1.371-1.204a.778.778 0 0 1 .559-.19.764.764 0 0 1 .528.26.772.772 0 0 1-.06 1.096l-1.373 1.203a.766.766 0 0 1-.507.193zm.952-2.253-1.274 1.117a.473.473 0 0 0-.045.666c.084.1.204.161.334.17a.469.469 0 0 0 .344-.117L9.062 12.4a.472.472 0 0 0 .043-.666.476.476 0 0 0-.334-.17H8.74a.467.467 0 0 0-.312.119l-.098.084z"
                            fill="#fff"
                          />
                          <path
                            d="m17.591 10.104-4.842-3.94c-1.854.984-3.167.478-3.795.078a.706.706 0 0 1 .06-1.219l1.613-.855a2.443 2.443 0 0 0-.119-.016c-1.456.23-2.927.357-4.401.379L3.285 9.113l.63.478.739-.648c.513-.45 1.294-.4 1.745.113l.011.013c.148.17.246.376.285.597a1.23 1.23 0 0 1 1.613.21l.035.04c.217.246.326.568.304.894v.002c.057-.006.114-.008.17-.006.328.02.634.171.85.418l-.223.213.232-.203c.217.246.327.568.305.895l-.002.012a1.23 1.23 0 0 1 1.314 1.307 1.222 1.222 0 0 1-.205.595l1.327.764c.29.165.66.067.831-.22a.601.601 0 0 0-.18-.805l-2.113-1.461c-.002 0-.003-.003-.004-.005l-.142-.098a.307.307 0 0 1 .35-.505l2.98 2.06a.606.606 0 0 0 .842-.152.606.606 0 0 0-.148-.837l-2.965-2.1c-.002 0-.003-.003-.005-.004a.307.307 0 1 1 .357-.501l3.325 2.354a.643.643 0 0 0 .767-1.034l-3.577-2.573a.307.307 0 1 1 .357-.5l.245.174h.003l3.315 2.385.017.015.054.039.03.023.06.041a.642.642 0 0 0 .873-.12.63.63 0 0 0-.096-.879z"
                            fill="#fff"
                          />
                          <path
                            d="M12.722 15.038a.75.75 0 0 1-.374-.1l-1.465-.844.09-.13a1.081 1.081 0 0 0-.836-1.678.73.73 0 0 0-.11.003l-.2.013.011-.17c.019-.24-.043-.48-.175-.68l-.112.098-.201-.219.113-.108a1.081 1.081 0 0 0-.647-.267.99.99 0 0 0-.13.004l-.16.012-.02-.176v-.006a1.084 1.084 0 0 0-.268-.775l-.035-.04a1.079 1.079 0 0 0-1.418-.183l-.19.127-.04-.225a1.092 1.092 0 0 0-1.796-.637l-.83.728-.835-.634 2.934-4.766.08-.002a31.237 31.237 0 0 0 4.378-.376l.02-.004.022.002c.04.003.083.01.127.016l.447.067-2.013 1.067a.558.558 0 0 0-.048.963c.59.376 1.85.867 3.644-.083l.088-.047L17.69 9.99a.776.776 0 0 1 .118 1.087.792.792 0 0 1-1.078.148l-.167-.125-3.554-2.555a.158.158 0 0 0-.221.036v.001a.158.158 0 0 0 .036.221l3.578 2.576a.791.791 0 0 1-.94 1.276L12.136 10.3a.16.16 0 0 0-.184.259l2.97 2.105c.336.24.418.703.184 1.042a.755.755 0 0 1-1.05.191l-2.979-2.061a.159.159 0 1 0-.18.26l2.26 1.565a.752.752 0 0 1-.23 1.348.783.783 0 0 1-.206.028zM11.3 13.991l1.196.69a.46.46 0 0 0 .492-.773l-1.688-1.17a1.373 1.373 0 0 1 0 1.253zm-.31-2.479c.092 0 .182.028.258.08l2.98 2.062a.457.457 0 0 0 .635-.116.457.457 0 0 0-.112-.631l-2.986-2.117a.455.455 0 0 1 .545-.731l3.325 2.355a.496.496 0 0 0 .59-.796l-3.573-2.57a.455.455 0 1 1 .53-.742l.249.176 3.386 2.439c.015.01.03.023.043.036l.043.027a.493.493 0 0 0 .671-.09.482.482 0 0 0-.07-.673l-4.767-3.878c-1.88.96-3.22.431-3.857.025a.854.854 0 0 1 .071-1.476l.912-.484c-.928.126-2.194.22-3.665.268l-2.708 4.4.426.323.648-.568a1.387 1.387 0 0 1 1.956.127c.124.14.22.305.279.482a1.378 1.378 0 0 1 1.63.337l.035.04c.206.233.327.529.342.839h.03c.32.02.624.153.857.373l.004-.004.081.092.009.01.093.106-.004.005c.16.218.251.48.262.75h.019c.132.008.262.036.386.081a.455.455 0 0 1 .447-.558v.001zm9.228-10.259-3.12 2.413 2.886 4.688a.27.27 0 0 1 .053.035.3.3 0 0 1 .085.188l.432.702 1.334-.75-1.67-7.276z"
                            fill="#fff"
                          />
                          <path
                            d="m20.503 9.478-.525-.853-.003-.035a.154.154 0 0 0-.042-.097l-.057-.034-.017-.03-2.957-4.8L20.314.994l1.747 7.611-1.558.874zm-.238-.952.341.553 1.114-.624-1.593-6.943-2.831 2.19 2.797 4.542a.272.272 0 0 1 .05.039.443.443 0 0 1 .123.243zm-3.967-4.025a2.746 2.746 0 0 1-1.902.07l-1.638-.532a.894.894 0 0 0-.682.06l-.555.294-.018.01-2.2 1.166a.093.093 0 0 0-.053.081.084.084 0 0 0 .04.078c.647.413 1.755.713 3.342-.196a.309.309 0 0 1 .348.028l5.001 4.07c.07.058.133.123.188.194l1.236-1.236-2.635-4.28-.472.193z"
                            fill="#fff"
                          />
                          <path
                            d="m18.155 10.039-.104-.131a1.024 1.024 0 0 0-.163-.17L12.885 5.67a.16.16 0 0 0-.178-.014c-1.652.947-2.813.627-3.497.192a.232.232 0 0 1-.108-.21.24.24 0 0 1 .132-.206l2.772-1.47a1.05 1.05 0 0 1 .798-.07l1.638.53a2.6 2.6 0 0 0 1.798-.064l.59-.242 2.763 4.487-1.438 1.437zm-5.371-4.703c.105 0 .207.036.289.102l5.001 4.07c.035.028.069.059.1.091l1.042-1.04-2.508-4.073-.354.145a2.895 2.895 0 0 1-2.005.072l-1.636-.53a.745.745 0 0 0-.568.05L9.457 5.65c.623.362 1.647.582 3.101-.252a.456.456 0 0 1 .226-.06z"
                            fill="#fff"
                          />
                        </svg>
                      </span>{" "}
                      <span className="collaborator__list--title text-dark-blue m-0">
                        Deals
                      </span>{" "}
                      <Avatar>{this.state.stage.length}</Avatar>{" "}
                    </div>
                    <div
                      className="collaborator__list__right"
                      onClick={(event) => event.stopPropagation()}
                    >
                      {" "}
                      <Avatar
                        onClick={this.openRightSideModal}
                        style={{ marginLeft: 10 }}
                      >
                        <i className="material-icons rightsidebar__list__addicon">
                          add
                        </i>
                      </Avatar>
                    </div>
                  </div>
                </React.Fragment>
              }
            />
          </AccordionSummary>
          <AccordionDetails className="accordion__deal">
            <React.Fragment>
              {this.state.stage != undefined &&
                this.state.stage != null &&
                this.state.stage.length > 0 && (
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormControl className={this.props.classes.formControl}>
                        <Typography className={this.props.classes.dealTextStyle}>Filter By :</Typography>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.filter}
                          displayEmpty
                          onChange={(event) =>
                            this.setState({ filter: event.target.value })
                          }
                          className={this.props.classes.select}
                        >
                          <MenuItem
                            className="dropdownhelper-menuitem-class"
                            style={{ fontSize: "12px" }}
                            value={1}
                          >
                            Open
                          </MenuItem>
                          <MenuItem
                            className="dropdownhelper-menuitem-class"
                            style={{ fontSize: "12px" }}
                            value={2}
                          >
                            Won
                          </MenuItem>
                          <MenuItem
                            className="dropdownhelper-menuitem-class"
                            style={{ fontSize: "12px" }}
                            value={3}
                          >
                            Lost
                          </MenuItem>
                        </Select>
                      </FormControl>
                      <Divider />
                    </Grid>

                    <Grid item xs={12}>
                      {this.populateDeals()}
                    </Grid>
                  </Grid>
                )}
              {(this.state.stage == undefined ||
                this.state.stage == null ||
                this.state.stage.length == 0) && (
                <div className="task-list-wrapper">
                  <div className="m-widget2 custom-list-container"style={{display:"flex",justifyContent:"center"}}>
                    No Deal Available
                  </div>
                </div>
              )}
            </React.Fragment>
          </AccordionDetails>
        </StyledAccordion>
        {this.state.showModal && (
          <RightSideModal
            isOpen={this.state.showModal}
            open={this.state.showModal}
            onClose={() => this.setState({ showModal: false })}
            url={"/user/add-new-deal"}
            toggle={this.toggleRightSideModal}
            contact_id={this.state.contact_id}
            onModalFormSubmit={this.onModalFormSubmit}
          />
        )}

        {this.state.showEditModal && (
          <RightSideModalEdit
            isOpen={this.state.showEditModal}
            open={this.state.showEditModal}
            onClose={() => this.setState({ showEditModal: false })}
            url={"/user/update-contact-deal"}
            toggle={this.toggleRightSideModalEdit}
            contact_id={this.state.contact_id}
            onModalFormSubmit={this.onModalFormSubmit}
            edit_deal={this.state.edit_deal}
          />
        )}
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(AssignToStageV2);

import React, { useState } from "react";
import { Box, IconButton, Typography, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { BasicInput, RightSideV2Styles } from "../RightSideV2Styles";
import CustomTitleV2 from "../common/customTitleV2/CustomTitleV2";
import DraggableIcon from "../common/icons/DraggableIcon";
import EditIcon from "../common/icons/EditIcon";
import EditImportantDatesModal from "./EditImportantDatesModal";
import CustomTitle from "../common/customTitle/CustomTitle";

const useStyles = makeStyles({
  hoverDiv: {
    "&:hover": {
      "& $icon": {
        opacity: 1,
        transition: "width .35s",
        width: "42px",
        display: "flex",
      },
    },
  },
  icon: {
    opacity: 0,
    width: 0,
    display: "none",
  },
});

const ImportantDates = (props) => {
  const [open, setOpen] = useState(false);

  const { contact } = props || {};

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { titleIcon, flexCenter } = RightSideV2Styles();
  const icons = useStyles();

  const renderAdditionalDates = () => {
    if (contact.contact_additional_informations && contact.contact_additional_informations.important_dates){
      let importantDates = JSON.parse(contact.contact_additional_informations.important_dates);
      return importantDates.map(item => (
          <div className={icons.datewidth} style={{ flex: 1 }}>
            <Typography variant='body2'>{item.title}</Typography>
            <BasicInput fullWidth type='date' value={item.value} readOnly />
          </div>
      ));
    }
  }

  return (
    <div className={`${icons.hoverDiv} ${icons.hoverDate}`}>
      <div style={{ gap: 10, marginBottom: 16 }}>
        <CustomTitle
          handleOpen={handleOpen}
          title={"IMPORTANT DATES"}
          iconHide={icons.icon}
        />
      </div>

      <div className={flexCenter} style={{ flexWrap: "wrap", gap: 12 }}>
        {contact?.birth_date && (
          <div className={icons.datewidth} style={{ flex: 1 }}>
            <Typography variant="body2">Birth Date</Typography>
            <BasicInput
              fullWidth
              type="date"
              value={contact.birth_date}
              readOnly
            />
          </div>
        )}

        {contact?.anniversary_date && (
          <div className={icons.datewidth} style={{ flex: 1 }}>
            <Typography variant="body2">Anniversary Date</Typography>
            <BasicInput
              fullWidth
              type="date"
              value={contact.anniversary_date}
              readOnly
            />
          </div>
        )}

        {renderAdditionalDates()}
      </div>
      <EditImportantDatesModal open={open} onClose={handleClose} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.rightsideReducer.contact,
  };
};
export default connect(mapStateToProps)(ImportantDates);

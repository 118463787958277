import React from "react";
import {
  AccordionSummary,
  Box,
  FormControlLabel,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Address from "../address/Address";
import Email from "../email/Email";
import PhoneNumber from "../phoneNumbers/PhoneNumber";
import ImportantDates from "../importantDates/ImportantDates";
import ImportantLinks from "../importantLinks/ImportantLinks";
import AdditionalInfo from "../AdditionalInfo/AdditionalInfo";
import { RightSideV2Styles } from "../RightSideV2Styles";
import Tags from "../tags/Tags";
import StyledAccordion from "../../../common/StyledAccordion";

const ContactInfo = (props) => {
  const { flexColumn, contactInfoAccordion } = RightSideV2Styles();
  return (
    <StyledAccordion>
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <FormControlLabel
          aria-label="Acknowledge"
          control={
            <React.Fragment>
              <div className="collaborator__list__parant">
                <div className="collaborator__list__left">
                  <span className="collaborator__left__icon">
                    <AccountCircleIcon />
                  </span>
                  <span className="collaborator__list--title text-dark-blue m-0">
                    Contact Info
                  </span>
                </div>
                <div
                  className="collaborator__list__right"
                  onClick={(event) => event.stopPropagation()}
                ></div>
              </div>
            </React.Fragment>
          }
          label=""
        />
      </AccordionSummary>
      {/* <AccordionDetails className={contactInfoAccordion}> */}
        <Box className={flexColumn} sx={{ gap: 25, padding: 10 }}>
          <Address new={true} />
          <Email />
          <PhoneNumber />
          <ImportantDates />
          <ImportantLinks />
          <Tags contactId={props.contactId} />
          <AdditionalInfo />
        </Box>
      {/* </AccordionDetails> */}
    </StyledAccordion>
  );
};

export default ContactInfo;

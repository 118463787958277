import React, { useState } from 'react'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import CustomStyles from '../Styles/Style.module.css'

const useStyles = makeStyles({
  root: {
    background: '#316aff',
    height: '60px',
    textAlign: 'center',
    color: '#ffffff',
    fontWeight: 700,
  },
  dialog_box_header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
})

function CustomModal({ open, onClose, title, icon, value, children }) {
  const [selectedValue, setSelectedValue] = useState(20) // Set the default value

  const handleChange = (event) => {
    setSelectedValue(event.target.value)
  }
  // const handleClickOpen = () => {
  //     setOpen(true);
  // };
  const classes = useStyles()

  return (
    <Box>
      <DialogTitle id='alert-dialog-title' className={classes.root}>
        <Box className={classes.dialog_box_header}>
          <Box sx={{ width: '4%', marginTop: '3px' }}>{icon}</Box>
          <Box sx={{ width: '90%', fontWeight: 700 }}>{title}</Box>
          <Box sx={{ width: '4%', marginTop: '3px', cursor: 'pointer' }}>
            <CloseIcon onClick={onClose} />
          </Box>
        </Box>
      </DialogTitle>
      {children}
    </Box>
  )
}

export default CustomModal

// import React, { useState } from 'react'
// import {
//   Box,
//   Dialog,
//   DialogContent,
//   DialogTitle,
//   makeStyles,
// } from '@material-ui/core'
// import CloseIcon from '@material-ui/icons/Close'
// import CustomStyles from '../Styles/Style.module.css'

// const useStyles = makeStyles({
//   root: {
//     background: '#316aff',
//     height: '60px',
//     textAlign: 'center',
//     color: '#ffffff',
//     fontWeight: 700,
//     //width: (props) => props.dia_width,
//     width: (props) => props.DialogBoxWidth,
//   },
//   dialog_box_header: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     width: '100%',
//   },
// })

// function CustomModal({
//   open,
//   onClose,
//   title,
//   icon,
//   dia_width,
//   DialogBoxWidth,
//   value,
//   children,
// }) {
//   const [selectedValue, setSelectedValue] = useState(20) // Set the default value

//   const handleChange = (event) => {
//     setSelectedValue(event.target.value)
//   }
//   // const handleClickOpen = () => {
//   //     setOpen(true);
//   // };
//   //const classes = useStyles({ dia_width });
//   const classes = useStyles({ DialogBoxWidth })

//   return (
//     <div>
//       <Dialog
//         open={open}
//         onClose={onClose}
//         aria-labelledby='alert-dialog-title'
//         aria-describedby='alert-dialog-description'
//         maxWidth={1200}
//       >
//         <DialogTitle id='alert-dialog-title' className={classes.root}>
//           <div className={classes.dialog_box_header}>
//             <Box sx={{ width: '4%', marginTop: '3px' }}>{icon}</Box>
//             <Box sx={{ width: '90%' }}>{title}</Box>
//             <Box sx={{ width: '4%', marginTop: '3px', cursor: 'pointer' }}>
//               <CloseIcon onClick={onClose} />
//             </Box>
//           </div>
//         </DialogTitle>

//         <DialogContent className={CustomStyles.fantasticScrollbar}>
//           {children}
//         </DialogContent>
//       </Dialog>
//     </div>
//   )
// }

// export default CustomModal

import React from "react"
import { Box, Chip, Typography } from "@material-ui/core"
import { connect } from "react-redux"
import { getContactTagList, getUserTagList } from "../redux/contactAction"
import { RightSideV2Styles } from "../RightSideV2Styles"
import { deleteContactTag } from "../../../../api/contactApi"
import NewConfirmAlert from "../../../common/new-alert/NewConfirmAlert"

const TagsList = (props) => {
  const { flexCenter, primaryChip } = RightSideV2Styles()

  const deleteTag = (tag) => {
    NewConfirmAlert({
      title: "Confirm to remove",
      description: "Do you want to remove this tag?",
      cancelText: "Cancel",
      submitText: "Yes, Remove!",
      onSubmit: async () => {
        deleteContactTag({
          contact_id: props.contact_id,
          tag_id: tag,
        }).then((res) => {
          props.getTagList({
            contactId: props.contact_id,
          })
        })
      },
    })
  }

  let content = null
  if (Array.isArray(props?.tags) && props?.tags.length > 0) {
    content = props?.tags.map((tag) => (
      <Chip
        key={tag.id}
        variant="default"
        label={tag.tag.name}
        color="primary"
        className={primaryChip}
        size="small"
        onDelete={() => deleteTag(tag.tag.id)}
      />
    ))
  }
    

  return (
    <Box className={flexCenter} sx={{ flexWrap: "wrap", gap: 10 }}>
      {content}
    </Box>
  )
}


const mapStateToProps = (state) => {
  return {
    contact: state?.rightsideReducer?.contact,
    contactTags: state?.rightsideReducer?.tags,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // getContactTagList: (params) => dispatch(getContactTagList(params)),
    fetchUserTagList: (params, callback) =>
      dispatch(getUserTagList(params, callback)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TagsList)

import React, { Component } from 'react';
import {addTagToContact, getTagList} from '../redux/contactApi';
import axis from "axis.js";
import { Grid } from '@material-ui/core';
import {connect} from 'react-redux'
import GlobalModal from '../Helpers/Modal/GlobalModal';
import NewLoader from '../Helpers/NewLoader';
import SaveIcon from "@material-ui/icons/Save";
import './modal_add_tag_form.css';

const EachTag = (props) => {
    return (
        <li>
            <label>
                <input name="users" checked={props.isChecked} onChange={props.selectTag} type="checkbox" defaultValue={props.data.id} className="styled-checkbox filled-in custom__filled__in" />
                <span>{props.data.name} </span>
            </label>
        </li>
    )
};

class ModalAddTagForm extends Component {
    typingTimer = null;
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            error: false,
            errorMsg: '',
            submitEnable: false,
            loading: false,
            submittingContact : false,
            tags: [],
            tempTags: [],
            newSelectedTags : [],
            selectedTags : [],
            notFoundInSearch: false,
            searchKey: ''
        };
    }

    componentDidMount(){
        this.loadAllTags();
    }

    loadAllTags = (search = false, text="") => {
        if(!search){
            this.setState({loading: true});
        }
        //let existingTag = this.props.selectedTags.map(tag => tag.tag_id);
        let existingTag = (this.props.selectedTags || []).map(
          (tag) => tag.tag_id
        )

        getTagList({contact_id: this.props.contact_id, searchKey: text}).then(res => {
            if(text !== ""){
                if(res.data.length === 0){
                    this.setState({tags : res.data, tempTags : res.data, loading: false, selectedTags: existingTag, notFoundInSearch: true});
                }
                else{
                    this.setState({tags : res.data, tempTags : res.data, loading: false, selectedTags: existingTag, notFoundInSearch: false});
                }
            }
            else{
                this.setState({tags : res.data, tempTags : res.data, loading: false, selectedTags: existingTag, notFoundInSearch: false});
            }
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        let params = {};
        if (this.state.notFoundInSearch) {
            params = {
                assign: false,
                contact_id: [this.props.contact_id],
                new: true,
                tags: this.state.searchKey
            };
        } else {
            params = {
                assign: true,
                contact_id: [this.props.contact_id],
                new: false,
                tags: this.state.selectedTags
            };
        }

        this.setState({
            submittingContact : true
        });


        addTagToContact(params).then(res => {
            this.setState({
                submittingContact : false
            });


            if (res.data.status === 'error') {
                this.setState({
                    error: true,
                    errorMsg: res.data.html
                });

                setTimeout(() => {
                    this.setState({
                        error: false,
                        errorMsg: ""
                    });
                }, 5000);
            } else if (res.data.status === 'validation-error') {
            } else {
                window.showNotification('SUCCESS', res.data.html);
                this.props.callBack();
            }
        }).catch((error) => {
            window.showNotification('ERROR', "Something went wrong");
        });
    };

    toggle = () => {
        if(this.props.modalToggle){
            this.props.modalToggle();
        }
        this.setState({
            isOpen: !this.state.isOpen
        })
    };

    handleCheckBox = (e) => {
        let previousSelectedTags = this.state.selectedTags;

        if (e.target.checked) {
            if(!previousSelectedTags.includes(parseInt(e.target.value)))
                previousSelectedTags.push(parseInt(e.target.value));
        } else {
            if(previousSelectedTags.includes(parseInt(e.target.value))){
                previousSelectedTags.splice(previousSelectedTags.indexOf(parseInt(e.target.value)),1)
            }
        }

        this.setState({newSelectedTags : previousSelectedTags, submitEnable : true});
    };

    // changeSearch = (e) => {
    //     let tempTagData = this.state.tags;
    //     let searchKey = e.target.value.toUpperCase().trim();
    //     let tagData ;
    //     let isFound = false ;

    //     if (searchKey != '') {
    //         tagData = tempTagData.filter((data)=> {
    //             if (data.name === searchKey) {
    //                 isFound = true;
    //             }
    //             if (data.name.toUpperCase().indexOf(searchKey) > -1) {
    //                 return data;
    //             }
    //         });
    //     } else {
    //         tagData = tempTagData
    //     }

    //     this.setState({tempTags: tagData, searchKey: searchKey, notFoundInSearch: !isFound});
    // };
    
    changeSearch = (e) => {
        clearTimeout(this.typingTimer);
        if(e.target.value.trim() !== this.state.searchKey){
            this.typingTimer = setTimeout(() => { 
                this.setState({searchKey: e.target.value.toUpperCase().trim()}, () => {
                    this.loadAllTags(true, e.target.value.trim())
                });
            }, 500);
        }
    };

    isTagChecked  = (id) => {
        if (this.state.selectedTags.length) {
            return this.state.selectedTags.includes(parseInt(id));
        } else {
            return false;
        }
    };

    render() {
        let runningList = '';
        if(!axis.isUndefined(this.state.tempTags)) {
            if (this.state.tempTags.length) {
                runningList = this.state.tempTags.map((data, index) => {
                    return (<EachTag selectTag={this.handleCheckBox} isChecked={this.isTagChecked(data.id)} data={data} key={index}/>)
                });
            }
        }

        return (
            <GlobalModal
                open={this.props.isOpen}
                onClose={() => this.props.onClose()}
                title={"Add Tag"}
                className={'global-medium-modal imr__add_tag_modal_alt'}
                onSubmit={this.handleSubmit}
                buttonText={"Save Tags"}
                buttonIcon={<SaveIcon />}
            >
                <Grid container className="imr__atma__wrp awesome__scroll_bar">
                    {
                        this.state.loading ? (
                            <Grid item xs={12}>
                                <NewLoader loading={ this.state.loading} onlyLoader={true} size={30} message={""} />
                            </Grid>
                        ) : (
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <div className="search-bar-top-area2 search-bar-top-area">
                                        <form action="">
                                            <label htmlFor="" className="mb-2">Type to search/create tag</label>
                                            <div className="search_input_wrapper">
                                                <span className="search-icon">
                                                    <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                                        <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                                                    </svg>
                                                </span>
                                                <input type="search" className="ad__custom__input custom-popup-search" placeholder="Search or Create ..." onChange={this.changeSearch}/>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="campaigns-single-item-main44 upload-single-item-main2 upload-single-item-main">
                                        {(this.state.notFoundInSearch && this.state.searchKey !== "") &&
                                        <div className="not_found_tag">
                                            <button className="create_new_tag" onClick={(e) => this.handleSubmit(e)}>Create and assign with this <strong>{this.state.searchKey}</strong></button>
                                            <p>No tags created with <b>{this.state.searchKey}</b></p>
                                        </div>
                                        }
                                        <ul className="awesome__scroll_bar">
                                            {runningList}
                                        </ul>
                                    </div>
                                </Grid>
                            </React.Fragment>
                        )
                    }
                </Grid>
            </GlobalModal>
        );
    }
}

const mapStateToProps = state => {
    return {
        allContactList: state.rightsideReducer.allContactList,
        userInfo: state.rightsideReducer.userInfo,
        subUserList: state.rightsideReducer.subUserList
    };
};

// const mapDispatchToProps = dispatch => {
//     return {
//     };
// }
export default connect(mapStateToProps, null)(ModalAddTagForm);

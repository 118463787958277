import React, { useEffect, useState } from "react"
import { Box } from "@material-ui/core"
import LeadType from "./LeadType"
import { Skeleton } from "@material-ui/lab"
import Styles from "./leadstatus.module.css"
import { RightSideV2Styles } from "../../RightSideV2Styles"
import { connect } from "react-redux"
import { fetchTasks,setContactDetails } from "../../redux/contactAction"
import { toggleRightSidebar } from "../../../../../actions/inboxAction"
import LeadOwnerCopy from "./LeadOwnerCopy"
import {
  getContactSmartFormEntries,
  fetchContactDetails,
} from "../../../../../api/contactApi"
import Utils from "../../Helpers/Utils"

const LeadStatus = (props) => {
 const [formEntries, setFormEntries] = useState([])
 const [componentLoading, setComponentLoading] = useState(true)
 const [data, setData] = useState(null)

 useEffect(() => {
   setComponentLoading(true)
   if (props.data != null) {
     setComponentLoading(false)
     setData(props.data)
   } else {
     loadContactData().then()
   }
   getContactSmartFormEntries(props.contactId)
     .then((res) => {
       if (res && res.success) {
         setFormEntries(res.data)
       } else {
         setFormEntries([])
       }
     })
     .catch((err) => {
       setFormEntries([])
     })
   setTimeout(() => {
     props.fetchTasks({
       contact_id: props.contactId,
     })
   }, 1000)
 }, [props.contactId])

 const loadContactData = async () => {
   let param = { id: props.contactId }
   if (Utils.getAccountData("carrierLookup")) {
     param["carrierLookup"] = true
   }
   fetchContactDetails(param)
     .then((response) => {
       if (response.data.status === "success") {
         setData(response.data.data)
         props.setContactDetails(response.data.data["people"])
       } else {
         window.showNotification("ERROR", response.data.message)
       }
     })
     .finally(() => {
       setComponentLoading(false)
     })
 }

  return (
    <Box className={Styles.leadstatus} sx={{ gap: "10px", my: 2 }}>
      
      
      <LeadOwnerCopy
        data={data}
        //contact={props.contact}
        contactId={props.contact?.id}
        reloadTopSection={() => {}}
        />
      <LeadType
        contactDetails={props.contactDetails}
        contactId={props.contact?.id}
      />
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    contact: state.rightsideReducer.contact,
    allActivityList: state.rightsideReducer.allActivityList,
    selectedContact: state.inboxReducer.selectedContact,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTasks: (params, callBack) => dispatch(fetchTasks(params, callBack)),
    setContactDetails: (params) => dispatch(setContactDetails(params)),
    toggleRightSidebar: () => dispatch(toggleRightSidebar()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadStatus)



import React, { useState, useEffect } from 'react';
import { changeLeadOwner, getAllTeamUsers, getSubUserListForContactlist } from './redux/contactApi';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import NewLoader from './Helpers/NewLoader';
import Helper from './Helpers/Helper';
import Utils from './Helpers/Utils';
import { connect } from 'react-redux';
import { getSubUserList, setSubUserListFromAction } from './redux/contactAction';
import { DropdownHelper } from './task/DropdownHelper';

const hideStyle = {
    visibility: 'hidden'
}

const LeadOwner=(props)=> {
    // constructor() {
    //     super()

        const [show,setShow] = useState(false)
        const [count, setCount] = useState(0)
        const [isOpen, setIsOpen] = useState(false)
        const [users, setUsers] = useState([])
        const [userId, setUserId] = useState(null)
        const [loading, setLoading] = useState(false)
        const [ownerInfo,setownerInfo] =useState(null)
        const [data, setData] = useState(null)
        const [subUserList, setSubUserList] =useState(null)

        useEffect(() => {
            // loadContactUsers();
            let tempUserId = Utils.getAccountData('userId');
    
            setUserId(tempUserId)
            setData(props.data);
            if(props.data != null && props.data.people !== undefined && props.data.people != null && props.data.people.user !== undefined && props.data.people.user != null){
                setownerInfo(props.data.people.user)
                if(props.data.people.user.id === tempUserId || props.data.people.user.parent_id === tempUserId){
                    loadTeamUserList();
                }
            }
        }, [])

    const loadTeamUserList = () => {
        // this.setState({
        //     loading: true
        // });
        if(props.subUserList != null){

            setSubUserList(props.subUserList)
        }else{
            getSubUserListForContactlist()
            .then(response => {
                props.setSubUserList(response.data.data)
                setSubUserList(response.data.data)
                setShow(true)
                
            })
            .catch(error => {
                console.log('something is wrong' + error)
            });
        }
    };

    // const callBack = (params) => {
    //     setIsOpen(false)

    //     props.reloadTopSection()
    // }



    const handleSubmit = (user_id)  => {
        setLoading(true)

        changeLeadOwner({
            contact_id: props.contact.id,
            user_id : user_id
        }).then(res => {
            if(res.status === 200){
                window.showNotification("SUCCESS","Lead Owner Successfully Changed");
                setownerInfo({...ownerInfo, id:user_id})
            }else{
                window.showNotification("ERROR","Something was wrong, Please reload the page and try again");
            }
        }).finally(() => {
            setLoading(false)
        });
    };

    // const loadContactUsers = (event)  => {
    //     getAllTeamUsers({
            
    //     }).then(res => {
    //         let userArray = [];
    //         res.data.users.forEach(function(item,index){
    //             userArray[item.value] = item.label;
    //         });
    //         setUsers(userArray);
    //     });
    // };
    // const handleChange = (e) => {
    //     handleSubmit(e.target.value);
    //     setUserId(e.target.value);
    // };

    // render() {

        // let show = (this.state.show) ? "accordion-wrapper show" : "accordion-wrapper";

        return (
            <React.Fragment>
                <div className="lead-owner lead-owner-design-modify">
                    <div className="mb-0 row">
                        {
                            loading ? (
                                <NewLoader loading={loading} onlyLoader={true} size={30} message={""} />

                            ) : (
                                <React.Fragment>
                                    {
                                        (!show && subUserList==null) ? (
                                            <React.Fragment>

                                                <div className="col s12 lead-owner-header">
                                                    <div className="lead__owner__title">
                                                    <i className="material-icons">person</i>
                                                    <h5 className="text-dark-blue">Lead Owner</h5>
                                                    </div>
                                                    <span className="text-light-blue ml-4">{Helper.isEmpty(ownerInfo) ? '': ownerInfo.full_name}</span>
                                                </div>
                                                {/* <div className="col sm6">
                                                    <input type="text" onClick={this.showInputBox} value={this.state.users[this.state.user_id]}/>
                                                </div> */}
                                                </React.Fragment>
                                        ) : (
                                            <div className="col s12 lead-owner-header">
                                                    <div className="lead__owner__title">
                                                    <i className="material-icons">person</i>
                                                    <h5 className="text-dark-blue">Lead Owner</h5>
                                                    </div>
                                                    <DropdownHelper
                                                    datalist={subUserList}
                                                    noneFieldRequired={true}
                                                    noneFieldTitle="Update Owner"
                                                    className="second__step__area__select"
                                                    mapping={{ title: "full_name" }}
                                                    menuItemClassName=""
                                                    updateSelectedData={handleSubmit}
                                                    selectedValue={ownerInfo.id }
                                                    updateDatatable={()=>console.log('update')}
                                                    dropdownHeader={'ownerChange'}
                                                    parentDivClassName="lead-source-rightside"
                                                />
                                                </div>
                                        )
                                    }
                                </React.Fragment>
                            )
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    // }
}
const mapStateToProps = state => {
    return {
        allContactList: state.rightsideReducer.allContactList,
        userInfo: state.rightsideReducer.userInfo,
        subUserList: state.rightsideReducer.subUserList,
        contact: state.rightsideReducer.contact,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        setSubUserList: (params) => dispatch(setSubUserListFromAction(params)),
        getAllSubUsers: (params, callback) => dispatch(getSubUserList(params, callback))
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(LeadOwner);

import React, {useState,useEffect} from "react";
import {connect} from "react-redux";
import Styles from "./EnhancedData.module.css";
import {
    FormControlLabel,
    Modal,
    Paper,
    Button
} from "@material-ui/core";
import {ACTION, LOOKUP_TYPE, SEARCH_CATEGORY} from "../../../../../constants/CoreConstants";
import {
    carrierLookupCleanDataApi,
    getCarrierLookupCreditSettingsApi
} from "../../../../../api/contactApi";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import {makeStyles} from "@material-ui/core/styles";
import {updateMultipleData} from "../../../../../actions/inboxAction";
import {cleanNumber} from "../../helper/utils";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            "&.MuiButton-root": {
                backgroundColor: "#008945",
                padding: "10px 20px",
                borderRadius: "4px",
                width: "40%"
            },
            "&.MuiButton-text": {
                color: "grey"
            },
            "&.MuiButton-contained": {
                color: "white"
            },
            "&.MuiButton-outlined": {
                color: "brown"
            }
        }
    };
});

const App = ({ isOpen, onClose, carrierLookupPrices, updateMultipleData, contact }) => {
    const classes = useStyles();
    const [lookupType, setLookupType] = useState([LOOKUP_TYPE.SKIP_TRACING_LOOKUP]);
    const [submitting, setSubmitting] = useState(false);
    const carrierLookupCreditTypes = { "CARRIER_LOOKUP": 64, "EMAIL_LOOKUP": 65, "DNC_LOOKUP": 66, "SKIP_TRACING_LOOKUP": 67 };
    const [selectedAction, setSelectedAction] = useState(ACTION.ADD_NEW_CONTACT);
    const [selectedSearchCategory, setSelectedSearchCategory] = useState([SEARCH_CATEGORY.HOUSE_CURRENT_OWNER]);

    useEffect(() => {
        if (!carrierLookupPrices) {
            getCarrierLookupCreditSettingsApi()
                .then((response)=>{
                    if (response.data && response.data.status === "success") {
                        if (response.data && response.data.data) {
                            updateMultipleData({ carrierLookupPrices: response.data.data });
                        }
                    }
                }).catch((err)=>{
                console.log(err);
            });
        }
    }, []);

    const carrierLookupSingle = async () => {
        setSubmitting(true);
        try{
            let payload = {
                "lookupType": lookupType,
                "requestModule": "CONTACT",
                "contact":{
                    "id": contact.id
                }
            };

            let addressLine1 = "";
            let addressLine2 = "";

            if (contact.address){
                addressLine1 = contact.address;
            }

            if (contact.city){
                addressLine2 += contact.city;
            }

            if (contact.state){
                if (addressLine2){
                    addressLine2 += ", ";
                }
                addressLine2 += contact.state;
            }

            if (contact.zip){
                if (addressLine2){
                    addressLine2 += ", ";
                }
                addressLine2 += contact.zip;
            }

            if (addressLine1){
                payload.contact.addressLine1 = addressLine1;
            }

            if (addressLine2){
                payload.contact.addressLine2 = addressLine2;
            }

            if (selectedSearchCategory[0]){
                payload.searchCategory = selectedSearchCategory;
                payload.action = selectedAction;
            }

            const res = await carrierLookupCleanDataApi(payload);

            if (res.status === false && res.success === false){
                window.showNotification("error", res.message ? res.message : "Failed to complete skip trace. Contact with administrator");
            }else {
                if(res["skip-trace-lookup-status"] === "REQUEST_DONE"){

                    let tmpContact = { ...contact };
                    if (!tmpContact.contact_additional_informations){
                        tmpContact.contact_additional_informations = {};
                    }
                    tmpContact.contact_additional_informations.skip_trace_status = "TRACED";
                    tmpContact.contact_additional_informations.skip_trace_date = new Date().toISOString();

                    updateMultipleData({ contact: tmpContact });
                    window.showNotification("success", "Skip Traced successfully");
                    onClose();
                }else {
                    window.showNotification("error", "Unable to complete skip trace");
                }
                setSubmitting(false);
            }
        }catch (err){
            setSubmitting(false);
            console.log(err);
            window.showNotification("error", "Unable to complete skip trace");
        }
    };

    const getPrice = (lookupType) => {
        try{
            if (carrierLookupPrices){
                let price = carrierLookupPrices.find((carrierLookupPrice)=>(carrierLookupPrice.type === carrierLookupCreditTypes[lookupType]));
                if (price){
                    return cleanNumber(price.value);
                }
            }
            return "0.00";
        }catch (err){
            console.log(err)
            return "0.00";
        }
    };

    return (
        <Modal
            open={isOpen}
            onClose={() => {

            }}
            aria-labelledby="enhanced-modal-title"
            aria-describedby="enhanced-modal-description"
            className={`${Styles.modalWrapper} global-xs-modal add_list_modal`}
        >
            <Paper className={Styles.modalInner}>
                <Box component="div" className={Styles.paddingWrp}>
                    <Box component="div" className={Styles.modalBody} style={{ position: "relative" }}>
                        <Box className={Styles.mmTexts}>
                            <Typography className={Styles.bodyTitle}>{`Skiptrace will cost: $${getPrice("SKIP_TRACING_LOOKUP")}`}</Typography>
                            <Typography className={Styles.descriptionText}>These credits will be deducted from your balance.</Typography>
                        </Box>
                        <Box px={4} className={Styles.mmSearch}>
                            <FormControl onChange={(event)=>{
                                setSelectedSearchCategory([event.target.value]);
                            }} style={{ flexDirection: "row" }}>
                                <FormControlLabel
                                    name="search-category"
                                    value={SEARCH_CATEGORY.HOUSE_CURRENT_OWNER}
                                    checked={selectedSearchCategory.includes(SEARCH_CATEGORY.HOUSE_CURRENT_OWNER)}
                                    control={<Radio color="primary" />}
                                    label="Find current owner"
                                />
                                <FormControlLabel
                                    name="search-category"
                                    value={SEARCH_CATEGORY.HOUSE_CURRENT_RESIDENT}
                                    checked={selectedSearchCategory.includes(SEARCH_CATEGORY.HOUSE_CURRENT_RESIDENT)}
                                    control={<Radio color="primary" />}
                                    label="Find current resident"
                                />
                            </FormControl>
                        </Box>
                        <Box component="div" sx={{textAlign: "center" }}>
                            <Typography variant="h6" style={{ fontWeight: 600 }}>What do you want to do with your skip traced data?</Typography>
                            <Typography variant="caption">(Select one that apply)</Typography>
                        </Box>
                        <FormControl onChange={(event)=>{
                            setSelectedAction(event.target.value);
                        }} style={{ flexDirection: "row" }}>
                            <FormControlLabel
                                name="action"
                                value={ACTION.ADD_NEW_CONTACT}
                                checked={selectedAction.includes(ACTION.ADD_NEW_CONTACT)}
                                control={<Radio color="primary" />}
                                label="Add as new contact"
                            />
                            <FormControlLabel
                                name="action"
                                value={ACTION.ADD_UNDER_CONTACT}
                                checked={selectedAction.includes(ACTION.ADD_UNDER_CONTACT)}
                                control={<Radio color="primary" />}
                                label="Add as additional contact"
                            />
                            <FormControlLabel
                                name="action"
                                value={ACTION.NO_ACTION}
                                checked={selectedAction.includes(ACTION.NO_ACTION)}
                                control={<Radio color="primary" />}
                                label="Add only in enhanced data report"
                            />
                        </FormControl>
                    </Box>
                    <Box className={Styles.modalFooter}>
                        <Button size="large" onClick={carrierLookupSingle} className={`${classes.root} ${Styles.submitButton}`} variant="contained" disabled={submitting}>
                            { submitting ? "Tracing..." : "Skiptrace" }
                        </Button>
                        <Button size="large" onClick={onClose} className={`${classes.root} ${Styles.cancelButton}`} variant="contained">
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Modal>
    );
};

const mapStateToProps = state => {
    return {
        carrierLookupPrices: state.inboxReducer.carrierLookupPrices,
        contact: state.rightsideReducer.contact,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateMultipleData: (payload) => dispatch(updateMultipleData(payload)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
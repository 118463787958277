import React, {useEffect, useState} from "react";
import {
    Modal,
    Paper,
    Box,
    Typography
} from "@material-ui/core";
import {
    Close as CloseIcon
} from "@material-ui/icons";
import Styles from "./DNCLogModal.module.css";
import {getContactLogsApi} from "../../../../api/contactApi";


const DNCLogModal = ({ contactId, contactName, isOpen, onClose }) => {
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getContactLogsApi({
            "contact-id": contactId,
            "module": "DNC"
        })
            .then((res)=>{
                if (res && res.success){
                    setLogs(res.data);
                }
            }).finally(()=>{
            setLoading(false);
        });
    }, []);

    return(
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="dnc-log-modal-title"
            aria-describedby="dnc-log-modal-description"
            className={`${Styles.modalWrapper} global-xs-modal add_list_modal`}
        >
            <Paper className={Styles.modalInner}>
                <Box component="div" className={Styles.header}>
                    <Typography component="h5" className={Styles.headerTitle}>
                        DNC Log for {contactName}
                    </Typography>
                    <Box component="span" className={Styles.close} onClick={onClose}>
                        <CloseIcon/>
                    </Box>
                </Box>
                <Box component="div" className={Styles.body}>
                    {
                        loading ?
                            (<Typography component="p" className={Styles.noLogText}>
                                Loading....
                            </Typography>) :
                            (logs[0] ?
                            logs.map((log)=>(
                                <Box component="div" className={Styles.logItem}>
                                    <Typography component="p" className={Styles.logText}>
                                        {log.note}
                                    </Typography>
                                    <Typography component="p" className={Styles.logTextTime}>
                                        {window.globalTimezoneConversionToDifferentTimezone(log.created_at,"UTC","","MM/DD/YYYY [at] h:mm a")}
                                    </Typography>
                                </Box>
                            )) :
                            <Typography component="p" className={Styles.noLogText}>
                                No logs available
                            </Typography>)
                    }
                </Box>
            </Paper>
        </Modal>
    );
};

export default DNCLogModal;
import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import { IconList } from './IconList';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Popover } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function PopperHelperNormal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const submit=(event,id)=>{
    handleClose(event)
    props.onSubmit(id);

  }

  return (
    <div className="colums-filter">
      {/* <Paper className={classes.paper}>
        <MenuList>
          <MenuItem>Profile</MenuItem>
          <MenuItem>My account</MenuItem>
          <MenuItem>Logout</MenuItem>
        </MenuList>
      </Paper> */}
      {/* <div> */}
        {/* <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className={(props.buttonClassName !== undefined && props.buttonClassName != null) ? props.buttonClassName : ''}

        > */}
        {/* {(props.buttonIcon != undefined && props.IconList.myListIcon != null) ? IconList[props.buttonIcon]: ''}&nbsp; */}

          {/* {props.buttonText !== undefined ? props.buttonText: "Toggle menu Grow"}
        </Button> */}
        <div class="running_btn_campaigns d-flex align-items-center bg-green px-2 columns-filter"           ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}

            >
                    <i class="material-icons white-text" style={{width: '15px'}}>
                        {props.icon}
                    </i>
                    <span class="white-text mx-2">{props.msg !== undefined ? props.msg : "Action"}</span>
                    <i class="material-icons white-text">
                        expand_more
                    </i>
                    
                </div>
                {props.popover=== undefined && props.popover == null && !props.popover &&

        <Popper className="colums-filter" open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal
            placement='bottom'>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                      {(props.showAll === undefined || props.showAll == null || props.showAll) &&
                      <MenuItem onClick={(event)=>submit(event,'')}>{props.checkBoxNeeded ? props.selectedList === '' ? <CheckBoxIcon /> : '' : ''} Show All</MenuItem>
                        }

                      {(props.datalist !== undefined && props.datalist.length > 0) &&
                        props.datalist.map((data,index)=>(

                            <MenuItem key={`contact-list-${data.id}`} onClick={(event)=>submit(event,data.id)}>{props.checkBoxNeeded ? props.selectedList == data.id ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon /> : ''} {data[props.mapping.title]}{(data.count !== undefined && data.count != null) ? '('+data.count+')' : ''} </MenuItem>
                        ))
                        
                      }
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        }
        {props.popover!== undefined && props.popover != null && props.popover &&
                        <Popover
                        id={"menu-list-grow"}
                        open={open}
                        anchorEl={anchorRef.current}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                      >
                          {(props.datalist !== undefined && props.datalist.length > 0) &&
                                        props.datalist.map((data,index)=>(
                                            // <Typography sx={{ p: 2 }} key={`contact-list-${data.id}`} onClick={(event)=>submit(event,data.id)}>{props.checkBoxNeeded ? props.selectedList == data.id ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon /> : ''} {data[props.mapping.title]}{(data.count !== undefined && data.count != null) ? '('+data.count+')' : ''} </Typography>
                
                                            <MenuItem key={`contact-list-${data.id}`} onClick={(event)=>submit(event,data.id)}>{props.checkBoxNeeded ? props.selectedList == data.id ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon /> : ''} {data[props.mapping.title]}{(data.count !== undefined && data.count != null) ? '('+data.count+')' : ''} </MenuItem>
                                        ))
                                        
                                      }
                      </Popover>
        }
      {/* </div> */}
    </div>
  );
}
import { Box, IconButton, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomTitleV2 from "../common/customTitleV2/CustomTitleV2";
import { RightSideV2Styles } from "../RightSideV2Styles";
import DraggableIcon from "../common/icons/DraggableIcon";
import EditIcon from "../common/icons/EditIcon";
import AdditionalInfoList from "./AdditionalInfoList";
import EditAdditionalInfoModal from "./EditAdditionalInfoModal";
import { connect } from "react-redux";
import { setContactDetails } from "../../../../actions/contactAction";
import { updateContactCustomFieldV2 } from "../../../../api/contactApi";
import CustomStyles from "../common/Styles/Style.module.css";
import CustomTitle from "../common/customTitle/CustomTitle";
const useStyles = makeStyles({
  hoverDiv: {
    "&:hover": {
      "& $icon": {
        opacity: 1,
        transition: "width .35s",
        width: "42px",
        display: "flex",
      },
    },
  },
  icon: {
    opacity: 0,
    width: 0,
    display: "none",
  },
});

const AdditionalInfo = (props) => {
  const [open, setOpen] = useState(false);
  const [contactCustomFields, setContactCustomFields] = useState([]);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    setContactCustomFields(props.contact?.all_contact_custom_fields);
  }, [props.contact]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (customFieldValues) => {
    updateContactCustomFieldV2({
      contactId: props.contact.id,
      params: Object.values(customFieldValues),
    }).then((response) => {
      if (response.success) {
        let array1 = [...contactCustomFields];
        let array2 = Object.values(customFieldValues);
        let update = [...array1];

        if (array1[0]) {
          for (let i = 0; i < array1.length; i++) {
            for (let j = 0; j < array2.length; j++) {
              if (array1[i].user_custom_field_id === array2[j].id) {
                update[i].value = array2[j].value;
              } else {
                update.push({
                  user_custom_field_id: array2[j].id,
                  value: array2[j].value,
                });
              }
            }
          }
        } else {
          for (let i = 0; i < array2.length; i++) {
            update.push({
              user_custom_field_id: array2[i].id,
              value: array2[i].value,
            });
          }
        }
        setContactCustomFields(update);
        setOpen(false);
      } else if (!response.success && response.status === 403) {
        if (response.data && response.data.data) {
          setErrors(response.data.data);
        }
      }
    });
  };
  const icons = useStyles();
  return (
    <div className={icons.hoverDiv}>
      <div style={{ gap: 10, marginBottom: 16 }}>
        <CustomTitle
          handleOpen={handleOpen}
          title={"CUSTOM FIELDS"}
          iconHide={icons.icon}
        />
      </div>

      <AdditionalInfoList
        contact_id={props.contact?.id}
        contactCustomFields={contactCustomFields}
      />

      <EditAdditionalInfoModal
        open={open}
        errors={errors}
        setErrors={setErrors}
        contactCustomFields={contactCustomFields}
        onClose={handleClose}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.rightsideReducer.contact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setContactDetails: (params) => dispatch(setContactDetails(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalInfo);

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import App from './App';
import store from "./store/Store"
import * as serviceWorker from './serviceWorker';
import SignUp from "./components/SignUp";
import {BrowserRouter as Router} from 'react-router-dom';
import Utils from "./helpers/Utils";

window.renderInboxModule = (containerId, history) => {
    ReactDOM.render(
        <React.Fragment>
            <Provider store={store}>
                <App history={history} />
            </Provider>
        </React.Fragment>,
        document.getElementById(containerId)
    );
    serviceWorker.unregister();
};

if (process.env.REACT_APP_PRODUCTION_MODE !== true && document.getElementById('InboxModule-root')) {
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <App />
            </Provider>
        </React.StrictMode>,
        document.getElementById('InboxModule-root'));

    serviceWorker.unregister();
}
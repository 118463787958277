

const Helper={

    getIconTextFromContact:(contact)=>{
        
        let firstName = (contact.first_name != undefined && contact.first_name != null) ? contact.first_name.trim() : ''
        let lastName = (contact.last_name != undefined && contact.last_name != null) ? contact.last_name.trim() : ''
        
        if(firstName != '' && lastName != ''){
            return (firstName.substring(0,1)+lastName.substring(0,1)).toUpperCase()
        }else if(firstName != ''){
            return firstName.substring(0,2).toUpperCase()
        }else if(lastName != ''){
            return lastName.substring(0,2).toUpperCase()
        }else {
            return 'N/A'
        }
    },

    isEmpty:(text)=>{
        if(text !== undefined && text!= null && text!== ''){
            return false
        }else{
            return true
        }
    }
}

export default Helper;
import React from 'react';
import Loading from '../../../common/Loading';
import GlobalModal from "../../../globals/Modal/GlobalModal";
import { Grid } from '@material-ui/core';
import CoreConstants from "../Helpers/CoreConstants";
import Utils from "../Helpers/Utils";
import {formBuilderAWSPreSignedUrl} from "../../../../api/contactApi";

const FormEntryDetails = (props) => {
    const populateStatus = (status) => {
        var text = 'Error';
        var badge = 'm-badge--danger';
        if (status === 1) {
            text = 'Inserted';
            badge = 'm-badge--success';
        } else if (status === 2){
            text = 'Duplicate';
            badge = 'm-badge--warning';
        }

        return <span className={`m-badge m-badge--wide ${badge}`}>{text}</span>;
    }

    const submitPreSignedUrl = (payload) =>{
        formBuilderAWSPreSignedUrl(payload)
            .then((res)=>{
                if (res.status == 200 && res.data){
                    let data = res.data;
                    let bucketUrl = JSON.parse(data.bucketUrl);
                    if (bucketUrl[0]){
                        window.open(bucketUrl[0].bucketUrl, '_blank');
                    }
                }
            })
    }

    const populateSubmission = (response) => {
        let formResponse = JSON.parse(response);
        let messageBody = [];

        try{
            if(formResponse.submission){
                formResponse.submission.forEach((item, index)=>{
                    if (item.type !== CoreConstants.FORM_BUILDER_V3_COMPONENT_TYPE.HIDDEN_FIELD){
                        const innerMessageBody = [];
                        if(item.value){
                            innerMessageBody.push(<span dangerouslySetInnerHTML={{ __html: Utils.urlify(item.value) }}/>);
                        }else if(item.firstName){
                            if(item.middleName){
                                innerMessageBody.push(<span>{item.firstName + ' '+ item.middleName + ' ' + item.lastName}</span>);
                            }else{
                                innerMessageBody.push(<span>{item.firstName + ' ' + item.lastName}</span>);
                            }
                        }else if(item.city || item.zip || item.state){
                            if(item.streetAddress1){
                                innerMessageBody.push(<span>{item.streetAddress1}</span>);
                                if (item.streetAddress2 || item.city || item.state || item.zip){
                                    innerMessageBody.push(<span>, </span>);
                                }
                            }
                            if(item.streetAddress2){
                                innerMessageBody.push(<span>{item.streetAddress2}</span>);
                                if (item.city || item.state || item.zip){
                                    innerMessageBody.push(<span>, </span>);
                                }
                            }
                            if(item.city){
                                innerMessageBody.push(<span>{item.city}</span>);
                                if (item.state || item.zip){
                                    innerMessageBody.push(<span>, </span>);
                                }
                            }
                            if(item.state){
                                innerMessageBody.push(<span>{item.state}</span>);
                                if (item.zip){
                                    innerMessageBody.push(<span>, </span>);
                                }
                            }
                            if(item.zip){
                                innerMessageBody.push(<span>{item.zip}</span>);
                            }
                        }else if(item.files){
                            const fileMessageBody = [];
                            const files = item.files;
                            if (files && files.length > 0){
                                files.forEach((file, index)=>{
                                    let payload = {
                                        fileId: [file.id],
                                        action: 0, // 0 for form file view from s3 pre-signed
                                        formApiKey: formResponse.apiKey,
                                        agencyId: formResponse.agencyId
                                    };
                                    fileMessageBody.push(<div>
                                            <span onClick={()=>{ submitPreSignedUrl(payload) }} style={{ color: "#6631e7", cursor: "pointer" }}>
                                                {file.name}
                                            </span>
                                    </div>)
                                });
                            }
                            innerMessageBody.push(<span>{fileMessageBody}</span>);
                        }
                        messageBody.push(
                            <tr>
                                <td className="m-datatable__cell" style={{width: '210px'}}>
                                    <span style={{ fontWeight: "300", color: "indigo" }}>
                                        {item.label}
                                    </span>
                                </td>
                                <td className="m-datatable__cell">
                                    <span style={{width: '100%', fontWeight: "400", color: "indigo"}}>
                                        {innerMessageBody}
                                    </span>
                                </td>
                            </tr>
                        );
                    }
                })
            }
        }catch(e){
            messageBody = [];
        }

        return messageBody;
    }


    return (
        <GlobalModal {...props}
             title={"Entry Details"}
             hideFooter={true}
             open={props.open}
             onClose={props.closeModal}
             className={'manage-tag-modal create__deal__modal create-collaborate-modal activity__modal_overflow update global-medium-modal' }>
            <Grid container>
                <Grid item xs={12}>
                    {
                        props.entry ? (
                            <React.Fragment>
                                <div className="m-datatable m-datatable--default m-datatable--brand m-datatable--loaded py-2">
                                    <table className="m-datatable__table" id="html_table" width="100%">
                                        <thead className="m-datatable__head">
                                            <tr className="m-datatable__row" style={{left: '0px'}}>
                                                <th className="m-datatable__cell m-datatable__cell--sort">
                                                    <span style={{width: '100%', color: "#602160"}}>{props.entry.title}</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="m-datatable__body">
                                            <tr className="m-datatable__row" style={{left: '0px', color: "#602160"}}>
                                                <td data-field="OrderID" className="m-datatable__cell" style={{width: '210px', color: "#602160"}}><span><strong>Submitted</strong></span></td>
                                                <td data-field="Owner" className="m-datatable__cell"><span style={{width: '100%', color: "#602160"}}>{props.entry.createdAt  ? window.globalTimezoneConversionToDifferentTimezone(props.entry.createdAt ,'UTC','','ll h:mm A') : ""}</span></td>
                                            </tr>
                                            <tr className="m-datatable__row m-datatable__row--even" style={{left: '0px', color: "#602160"}}>
                                                <td data-field="OrderID" className="m-datatable__cell" style={{width: '210px', color: "#602160"}}><span><strong>Status</strong></span></td>
                                                <td data-field="Owner" className="m-datatable__cell"><span style={{width: '100%', color: "#602160"}}>{populateStatus(props.entry.status)}</span></td>
                                            </tr>
                                            <tr className="m-datatable__row m-datatable__row--even" style={{left: '0px', color: "#602160"}}>
                                                <td data-field="OrderID" className="m-datatable__cell" style={{width: '210px', color: "#602160"}}><span><strong>Message</strong></span></td>
                                                <td data-field="Owner" className="m-datatable__cell"><span style={{width: '100%', color: "#602160"}}>{props.entry.comment}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="m-datatable m-datatable--default m-datatable--brand m-datatable--loaded pt-5">
                                    <table className="m-datatable__table" id="html_table" width="100%">
                                        <thead className="m-datatable__head">
                                            <tr className="m-datatable__row" style={{left: '0px'}}>
                                                <th className="m-datatable__cell m-datatable__cell--sort">
                                                    <span style={{width: '100%', color: "#602160"}}>Submission Details</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="m-datatable__body">
                                            {
                                                populateSubmission(props.entry.entries)
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </React.Fragment>
                        ) : (
                            <div className="text-center" style={{width: '400px'}}>
                                <Loading/>
                            </div>
                        )
                    }
                </Grid>
            </Grid>
        </GlobalModal>
    )
}

export default FormEntryDetails

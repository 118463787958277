import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { Document, Page, PDFDownloadLink, StyleSheet, Text, View } from '@react-pdf/renderer';
import If, { Else } from 'if-else-react';
import React, {useContext, useEffect, useRef, useState} from "react";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {connect, Provider, useSelector} from "react-redux";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { addSelectedContact, archiveLead, deleteLead, markAsImportant, markAsUnread, moveToInboxFromArchive, toggleRightSidebar, updateInboxUnreadContactInfo, updateSelectedContact } from "../../../actions/inboxAction";
import App, { HistoryProvider } from "../../../App";
import CoreConstants from "../../../constants/CoreConstants";
import Utils, {formatNumber} from "../../../helpers/Utils";
import { TAB_ARCHIVE } from '../LeftSide';
import './export_conversation.css';
import ReactDOM from "react-dom";
import store from "../../../store/Store";
import Loading from "../../common/Loading";
import './top_right_action.css';
import Helper from '../rightSide/Helpers/Helper';
import CustomConfirmAlert from '../../custom-alert/CustomConfirmAlert';

const archiveIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
    <path d="M0 0h24v24H0V0z" fill="none"/>
    <path d="M20.54 5.23l-1.39-1.68C18.88 3.21 18.47 3 18 3H6c-.47 0-.88.21-1.16.55L3.46 5.23C3.17 5.57 3 6.02 3 6.5V19c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6.5c0-.48-.17-.93-.46-1.27zM6.24 5h11.52l.81.97H5.44l.8-.97zM5 19V8h14v11H5zm8.45-9h-2.9v3H8l4 4 4-4h-2.55z"/>
    </svg>

const MySwal = withReactContent(Swal)

const TopRightActions = (props) =>
{
    const downloadContainer = useRef();
    const history = useContext(HistoryProvider);
    const selectedTabLeftSide = useSelector(state => state.inboxReducer.tabOptionLeftSide)
    const [contact, setContact] = useState(props.selectedContact);
    const [conversations, setConversations] = useState([]);
    const [exportLoading, setExportLoading] = useState(false);

    const [showMarkAs, setShowMarkAs] = useState(true)

    const BORDER_COLOR = 'transparent';
    const BORDER_STYLE = 'none';
    const COL1_WIDTH = 20;
    const COLN_WIDTH = 20;

    const styles = StyleSheet.create({
        body: {
            padding: 10,
            fontFamily: 'Times-Roman',
            letterSpacing: 1
        },
        headerImage: {
          maxWidth: '250px',
        },
        tableTop: {
            display: "table",
            width: "500px",
            borderStyle: BORDER_STYLE,
            borderColor: BORDER_COLOR,
            borderWidth: 0,
            borderRightWidth: 0,
            borderBottomWidth: 0,
            float: 'left'
        },
        tableTopCellHeader: {
            margin: 5,
            fontSize: 12,
            fontWeight: 'bold'
        },
        tableColTop1: {
            width: COL1_WIDTH + '%',
            borderStyle: BORDER_STYLE,
            borderColor: BORDER_COLOR,
            borderWidth: 0,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            textAlign: 'left',
            wordWrap: 'break-word'
        },
        table: {
            display: "table",
            width: "auto",
            borderStyle: BORDER_STYLE,
            borderColor: BORDER_COLOR,
            borderWidth: 0,
            borderRightWidth: 0,
            borderBottomWidth: 0,
            borderTopWidth: 1,
            tableLayout: 'fixed'
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row"
        },
        tableCol1Header: {
            width: COL1_WIDTH + '%',
            borderStyle: BORDER_STYLE,
            borderColor: BORDER_COLOR,
            borderBottomColor: 'transparent',
            borderWidth: 0,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableColHeader: {
            width: COLN_WIDTH + "%",
            borderStyle: BORDER_STYLE,
            borderColor: BORDER_COLOR,
            borderBottomColor: 'transparent',
            borderWidth: 0,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol1: {
            width: COL1_WIDTH + '%',
            borderStyle: BORDER_STYLE,
            borderColor: BORDER_COLOR,
            borderWidth: 0,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol: {
            width: COLN_WIDTH + "%",
            borderStyle: BORDER_STYLE,
            borderColor: BORDER_COLOR,
            borderWidth: 0,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCellHeader: {
            margin: 5,
            fontSize: 11,
            fontWeight: 'bold'
        },
        tableCell: {
            margin: 5,
            fontSize: 10,
            wordWrap: 'break-word'
        }
    });

    useEffect(() => {
        setConversations(props.conversations);
    }, [props.conversations]);

    useEffect(() => {
        setContact(props.selectedContact);
    }, [props.selectedContact]);

    useEffect(() => {
        // if(props.contactDetails !== null){
        //     let contact_owner = props.contactDetails.user.email
        //     let loginUserEmail = Utils.getAccountData('email')
        //     setShowMarkAs(contact_owner === loginUserEmail)
        // }
    }, [props.contactDetails])

    const markImportant = (e, importantStatus) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append('contact_id', contact.id);
        formData.append('important_status', importantStatus);

        props.markAsImportant(formData, (response) => {
            // setTimeout(() => setContact({...contact, 'is_favourite': response.is_favourite}),5000);
            // setContact({...contact, 'is_favourite': response.is_favourite})
            response.action_type = 'update';
            response.update_field = 'is_favourite';
            responseAction(response);
        });
    }

    const closeConversation = (e) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append('contact_id', contact.id);

        // props.archiveLead(formData, responseAction);
        props.archiveLead({'contact_id': contact.id}, responseAction);
    }

    const moveToInbox = (e) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append('contact_id', contact.id);

        props.moveToInboxFromArchive(formData, responseAction);
    }

    const deleteContact = (e) => {
        e.preventDefault();

        CustomConfirmAlert({
            handleConfirm: () => {
                let formData = new FormData();
                formData.append('contact_id', contact.id);
                try {
                    props.deleteLead(formData, responseAction);
                } catch (error) {
                    console.log(error);
                } finally {
                    props.storeSelectedContact(null);
                    props.deleteContact(true, null, true, props.selectedContactIndex);
                    return;
                }
            },
            callConfirmLast: true,
            title: 'Are you sure?',
            description: 'You will not be able to recover this data. Do you want to remove this contact?',
            type_word: 'DELETE CONTACT',
            icon: 'question',
            closeButtonText: 'No',
            confirmButtonText: 'Yes, Delete!'
        });
    };

    const markUnread = (e) => {
        e.preventDefault();
        props.setUpdateUnreadContact(true)
        // props.updateInboxUnreadContact(props.selectedContact.id)
        // let formData = new FormData();
        // formData.append('contact_id', contact.id);

        // props.markAsUnread(formData, (response) => {
        //     if (response.status) {
        //         window.showNotification('SUCCESS', response.html);
        //         props.updateInboxUnreadContact(contact.id)

        //         if(window.updateNotificationCount !== undefined){
        //             window.updateNotificationCount()
        //         }
        //     } else {
        //         window.showNotification('ERROR', response.html);
        //     }
        // });
        // window.updateSelectedContactData()
    }

    const responseAction = response => {
        if (response.status) {
            if (typeof response.is_favourite !== 'undefined') {
                setContact({...contact, 'is_favourite': parseInt(response.is_favourite)});
                window.updateThreadItemImportantStatus(props.selectedContactIndex, response.is_favourite);
            } else if (typeof response.is_archived !== 'undefined') {
                setContact({...contact, 'is_archived': parseInt(response.is_archived)});
                console.log('props.selectedContactIndex', props.selectedContactIndex);
                window.updateThreadItemArchiveStatus(props.selectedContactIndex, response.is_archived);
            }

            window.showNotification('SUCCESS', response.html);
            // window.loadInboxThreads();
        } else {

            if(response.is_archived){
                window.showNotification('ERROR', response.message);

            }
            // window.showNotification('ERROR', response.html);

        }
    }

    const printConversation = () => {
        // return null
        
        return conversations.map((row, i) => {
            
            let from ='';
            let message ='';
            let contentType ='';
            let inOut =2;
            if(row){
                from = row.from ?? '';
                message = row.message ?? ''
                contentType = row.content_type ?? 1;
                inOut = row.in_out ?? inOut
            }
            return (
                <View style={styles.tableRow} key={i}>
                    <View style={styles.tableCol1}>
                        <Text style={styles.tableCell}>{Helper.limitMessage(from, 19)}</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{message.replace(/(<([^>]+)>)/gi, "").replace(/&nbsp;/g,' ')}</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{Utils.getMessageType(contentType)}</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{row.created_at ?? ''}</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{inOut === CoreConstants.inboxThread.INOUT_TYPE_OUT ? 'Outgoing' : 'Incoming'}</Text>
                    </View>
                </View>
            );
        });
    }

    const ConversationList = () => (
        <Document>
            <Page style={styles.body}>
                <View style={styles.tableTop}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColTop1}>
                            <Text style={styles.tableTopCellHeader}>Contact: </Text>
                        </View>
                        <View style={styles.tableColTop1}>
                            <Text style={styles.tableCell}>{Utils.getContactDisplayName(contact.first_name + ' ' + contact.last_name, contact.number, contact.email)}</Text>
                        </View>
                        <View style={styles.tableColTop1}>
                            <Text style={styles.tableCell}/>
                        </View>
                        <View style={styles.tableColTop1}>
                            <Text style={styles.tableCell}/>
                        </View>
                        <View style={styles.tableColTop1}>
                            <Text style={styles.tableCell}/>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColTop1}>
                            <Text style={styles.tableCellHeader}>Number: </Text>
                        </View>
                        <View style={styles.tableColTop1}>
                            <Text style={styles.tableCell}>{ Utils.formatNumber(contact.number) }</Text>
                        </View>
                        <View style={styles.tableColTop1}>
                            <Text style={styles.tableCell}/>
                        </View>
                        <View style={styles.tableColTop1}>
                            <Text style={styles.tableCell}/>
                        </View>
                        <View style={styles.tableColTop1}>
                            <Text style={styles.tableCell}/>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColTop1}>
                            <Text style={styles.tableCellHeader}>Email: </Text>
                        </View>
                        <View style={styles.tableColTop1}>
                            <Text style={styles.tableCell}>{contact.email}</Text>
                        </View>
                        <View style={styles.tableColTop1}>
                            <Text style={styles.tableCell} />
                        </View>
                        <View style={styles.tableColTop1}>
                            <Text style={styles.tableCell} />
                        </View>
                        <View style={styles.tableColTop1}>
                            <Text style={styles.tableCell} />
                        </View>
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol1Header}>
                            <Text style={styles.tableCellHeader}>From</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Message</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Message Type</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Send Time</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Incoming/Outgoing</Text>
                        </View>
                    </View>
                    { printConversation() }
                </View>
            </Page>
        </Document>
    );
    
    const activeRightSidebar = e => {
        props.toggleRightSidebar();
    }

    const triggerDownload = () => {
        setTimeout(() => {
            downloadContainer.current.firstChild.click();
            setExportLoading(false);
        }, 1000);
    }
    
    const downloadConversation = (e) => {
        e.preventDefault();
        setExportLoading(true);
        const container = document.getElementById('export__conversation_container');
        if (container) {
            if (downloadContainer.current.firstChild != null) {
                ReactDOM.unmountComponentAtNode(container);
            }
            let isDownloaded = false;
            ReactDOM.render(
                <PDFDownloadLink document={<ConversationList />} fileName={`conversation_with_${Utils.getContactDisplayName(contact.first_name+' '+contact.last_name,contact.number,contact.email)}`}>
                    {({ blob, url, loading, error }) => {
                        if (!loading && !isDownloaded) {
                            if (downloadContainer.current.firstChild != null) {
                                isDownloaded = true;
                                triggerDownload();
                            }
                        }
                    }
                    }
                </PDFDownloadLink>,
                container);
        }
    }

    return (
        <div className="action-icons d-flex justify-content-end">
            <ul className="p-0 m-0 right">
                {(contact.is_favourite == null || contact.is_favourite == 0) &&
                    <li className={showMarkAs ? '' : 'disable-action-option'}>
                        <a href="#" title={`${showMarkAs ? 'Mark as important' : 'Only lead owner can change'}`} onClick={(e) => showMarkAs ? markImportant(e, 1): null}>
                            <svg width="15" height="14" viewBox="0 0 15 14" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M14.0192 5.15443L8.9252 4.81953L7.0068 0L5.08839 4.81953L0 5.15443L3.90275 8.46827L2.62208 13.4952L7.0068 10.7236L11.3915 13.4952L10.1109 8.46827L14.0192 5.15443Z"
                                    fill="#133159"/>
                            </svg>
                        </a>
                    </li>
                }
                {(contact.is_favourite !== null && contact.is_favourite == 1) &&
                    <li className={showMarkAs ? '' : 'disable-action-option'}>
                        <a href="#" title={`${showMarkAs ? 'Remove from important' : 'Only lead owner can change'}`} onClick={(e) => showMarkAs ? markImportant(e, 0) : null}>
                            <svg width="15" height="14" viewBox="0 0 15 14" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M14.0192 5.15443L8.9252 4.81953L7.0068 0L5.08839 4.81953L0 5.15443L3.90275 8.46827L2.62208 13.4952L7.0068 10.7236L11.3915 13.4952L10.1109 8.46827L14.0192 5.15443Z"
                                    fill="#fbc916"/>
                            </svg>
                        </a>
                    </li>
                }

                <li className="conversation_export_loader" title="Export conversation as PDF">
                    {exportLoading ?
                        <div className="conversation_export_loader_wrapper">
                            <Loading/>
                        </div>
                        :
                        <a href="#" onClick={(e) => downloadConversation(e)}>
                            <svg width="10" height="15" viewBox="0 0 10 15" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M4.55805 12.3169C4.55816 12.3171 4.55828 12.3171 4.5584 12.3173C4.57284 12.3317 4.58805 12.3454 4.60384 12.3583C4.61107 12.3643 4.61872 12.3694 4.62616 12.375C4.63504 12.3817 4.64374 12.3886 4.65297 12.3947C4.66187 12.4007 4.6711 12.4058 4.68024 12.4113C4.68862 12.4163 4.6968 12.4216 4.70541 12.4262C4.71473 12.4311 4.72431 12.4354 4.73383 12.4398C4.74279 12.4441 4.75164 12.4486 4.76084 12.4524C4.77007 12.4562 4.7795 12.4593 4.78888 12.4626C4.79875 12.4662 4.80848 12.47 4.81855 12.473C4.82793 12.4758 4.83745 12.4779 4.84694 12.4803C4.85723 12.4829 4.86739 12.4858 4.87788 12.4879C4.88887 12.49 4.89994 12.4913 4.91098 12.4929C4.9201 12.4942 4.92909 12.4959 4.93832 12.4968C4.97933 12.5009 5.02067 12.5009 5.06169 12.4968C5.07092 12.4959 5.07991 12.4942 5.08902 12.4929C5.10007 12.4913 5.11114 12.49 5.12213 12.4879C5.13262 12.4858 5.14278 12.4829 5.15307 12.4803C5.16253 12.4779 5.17208 12.4758 5.18145 12.473C5.19153 12.47 5.20129 12.4662 5.21113 12.4626C5.22051 12.4593 5.22994 12.4562 5.23917 12.4524C5.24837 12.4486 5.25722 12.4441 5.26618 12.4398C5.2757 12.4354 5.28528 12.4311 5.2946 12.4262C5.30321 12.4216 5.31139 12.4163 5.31976 12.4113C5.32891 12.4058 5.33813 12.4007 5.34704 12.3947C5.35627 12.3886 5.36497 12.3817 5.37385 12.375C5.38129 12.3695 5.38893 12.3643 5.39617 12.3583C5.41196 12.3454 5.42717 12.3317 5.44161 12.3173C5.44173 12.3171 5.44184 12.3171 5.44196 12.3169L9.81695 7.94194C10.061 7.69787 10.061 7.30213 9.81695 7.05806C9.57288 6.81398 9.17714 6.81398 8.93307 7.05806L5.62498 10.3661V0.62499C5.62498 0.279814 5.34516 0 4.99999 0C4.65481 0 4.375 0.279814 4.375 0.62499V10.3661L1.06694 7.05806C0.822869 6.81398 0.427127 6.81398 0.183054 7.05806C-0.0610181 7.30213 -0.0610181 7.69787 0.183054 7.94194L4.55805 12.3169Z"
                                    fill="#133159"/>
                                <path
                                    d="M9.37497 13.751H0.62499C0.279815 13.751 0 14.0308 0 14.376C0 14.7211 0.279815 15.001 0.62499 15.001H9.375C9.72018 15.001 9.99999 14.7212 9.99999 14.376C9.99999 14.0308 9.72015 13.751 9.37497 13.751Z"
                                    fill="#133159"/>
                            </svg>
                        </a>
                    }
                    <div id='export__conversation_container' style={{display: 'none'}} ref={downloadContainer}/>
                </li>
                <li>
                    <a href={`/contacts/${contact.id}`} onClick={(e) => {e.preventDefault();history.push(`/contacts/${contact.id}`);}} title="Contact timeline">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M3.31579 3.31579C3.31579 5.14389 4.80347 6.63158 6.63158 6.63158C8.45968 6.63158 9.94737 5.14389 9.94737 3.31579C9.94737 1.48768 8.45968 0 6.63158 0C4.80347 0 3.31579 1.48768 3.31579 3.31579ZM12.5263 14H13.2632V13.2632C13.2632 10.4197 10.9487 8.10526 8.10526 8.10526H5.15789C2.31368 8.10526 0 10.4197 0 13.2632V14H12.5263Z"
                                fill="#133159"/>
                        </svg>
                    </a>
                </li>
                <li>
                    <a href="#" title="Mark as unread" onClick={(e) => markUnread(e)}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.2626 9.90237C14.5962 8.96059 15.4091 8.02636 15.4431 7.98704L15.7029 7.68631L15.4431 7.38558C15.4092 7.34626 14.5962 6.41203 13.2626 5.47025C11.4797 4.21124 9.60821 3.54574 7.8504 3.54574C6.90031 3.54574 5.91712 3.74056 4.93271 4.118L0.814684 0L0.164062 0.650622L14.8861 15.3727L15.5367 14.722L11.6718 10.8571C12.2063 10.5913 12.7382 10.2726 13.2626 9.90237ZM7.8504 5.38599C9.1188 5.38599 10.1507 6.41791 10.1507 7.68631C10.1507 8.15447 10.0098 8.59018 9.76862 8.95394L9.0957 8.28102C9.1821 8.10083 9.23059 7.89914 9.23059 7.68631C9.23059 6.92527 8.61144 6.30612 7.8504 6.30612C7.63758 6.30612 7.43592 6.35461 7.25572 6.44101L6.58281 5.76809C6.9465 5.52689 7.38224 5.38599 7.8504 5.38599Z"
                                fill="#133159"/>
                            <path
                                d="M7.85067 9.98649C6.58228 9.98649 5.55036 8.95457 5.55036 7.68618C5.55036 7.57582 5.55864 7.4674 5.57376 7.36112L3.19066 4.97803C2.93885 5.13049 2.68818 5.29434 2.43926 5.47014C1.10605 6.41195 0.29349 7.34622 0.259537 7.38554L0 7.68618L0.259537 7.98681C0.29349 8.02613 1.10605 8.9604 2.43926 9.90218C4.22157 11.1612 6.09283 11.8267 7.85067 11.8267C8.48338 11.8267 9.13085 11.7401 9.78358 11.571L8.17572 9.96312C8.06945 9.97821 7.96103 9.98649 7.85067 9.98649Z"
                                fill="#133159"/>
                        </svg>
                    </a>
                </li>
                <li>
                    <a href="#" title="Delete contact" onClick={(e) => deleteContact(e)}>
                        <svg width="11" height="13" viewBox="0 0 11 13" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9.26442 1.57692H7.09615V1.18269C7.09615 0.529501 6.56665 0 5.91346 0H4.33654C3.68335 0 3.15385 0.529501 3.15385 1.18269V1.57692H0.985577C0.441267 1.57692 0 2.01819 0 2.5625V3.35096C0 3.5687 0.176492 3.74519 0.394231 3.74519H9.85577C10.0735 3.74519 10.25 3.5687 10.25 3.35096V2.5625C10.25 2.01819 9.80873 1.57692 9.26442 1.57692ZM3.94231 1.18269C3.94231 0.965372 4.11922 0.788461 4.33654 0.788461H5.91346C6.13078 0.788461 6.30769 0.965372 6.30769 1.18269V1.57692H3.94231V1.18269Z"
                                fill="#FF5858"/>
                            <path
                                d="M0.748192 4.53418C0.677846 4.53418 0.621792 4.59297 0.625143 4.66324L0.950383 11.4894C0.980443 12.1211 1.49935 12.6159 2.1316 12.6159H8.11848C8.75073 12.6159 9.26964 12.1211 9.2997 11.4894L9.62494 4.66324C9.62829 4.59297 9.57223 4.53418 9.50189 4.53418H0.748192ZM6.70196 5.51976C6.70196 5.30194 6.87838 5.12553 7.09619 5.12553C7.31401 5.12553 7.49042 5.30194 7.49042 5.51976V10.6448C7.49042 10.8626 7.31401 11.039 7.09619 11.039C6.87838 11.039 6.70196 10.8626 6.70196 10.6448V5.51976ZM4.73081 5.51976C4.73081 5.30194 4.90723 5.12553 5.12504 5.12553C5.34285 5.12553 5.51927 5.30194 5.51927 5.51976V10.6448C5.51927 10.8626 5.34285 11.039 5.12504 11.039C4.90723 11.039 4.73081 10.8626 4.73081 10.6448V5.51976ZM2.75966 5.51976C2.75966 5.30194 2.93607 5.12553 3.15389 5.12553C3.3717 5.12553 3.54812 5.30194 3.54812 5.51976V10.6448C3.54812 10.8626 3.3717 11.039 3.15389 11.039C2.93607 11.039 2.75966 10.8626 2.75966 10.6448V5.51976Z"
                                fill="#FF5858"/>
                        </svg>
                    </a>
                </li>
                <li>
                    {/* <a href="#" title="Close conversation" onClick={(e) => moveToInbox(e)}>
                        <svg className="interface-icon o__standard" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <path
                                d="M15 8.482v4.679a.87.87 0 0 1-.875.839H1.875A.867.867 0 0 1 1 13.179V9.045c0-.048.024-.149.071-.304l2.554-6.205A.887.887 0 0 1 4.429 2h7.142a.84.84 0 0 1 .804.554l2.554 6.098c.016.05.03.183.04.347.022.376.031-.579.031-.517zm-1.84.517c.284-.002.493-.204.394-.49-.052-.15-.755-1.898-2.108-5.241A.474.474 0 0 0 11 3.002L5 3c-.289 0-.409.118-.498.268-.09.15-1.925 4.935-2.038 5.241-.113.307.123.49.34.49h1.625c.894-.005.988.41.97 1.01v.135c0 .455.141.836.587.872l3.968-.009c.425-.028.546-.507.546-1.007.028-.608.308-1.002 1.071-1h1.59zM5.5 5h5a.5.5 0 1 1 0 1h-5a.5.5 0 0 1 0-1zm0 2h5a.5.5 0 1 1 0 1h-5a.5.5 0 0 1 0-1z" fillRule="evenodd">
                            </path>
                        </svg>
                    </a> */}
                    {/* <If condition={contact.is_archived === 0}> */}
                    <If condition={selectedTabLeftSide === TAB_ARCHIVE}>
                        <a href='#' title="Close conversation" onClick={(e) => moveToInbox(e)}>
                            {/* <svg className="interface-icon o__standard" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                <path
                                    d="M15 8.482v4.679a.87.87 0 0 1-.875.839H1.875A.867.867 0 0 1 1 13.179V9.045c0-.048.024-.149.071-.304l2.554-6.205A.887.887 0 0 1 4.429 2h7.142a.84.84 0 0 1 .804.554l2.554 6.098c.016.05.03.183.04.347.022.376.031-.579.031-.517zm-1.84.517c.284-.002.493-.204.394-.49-.052-.15-.755-1.898-2.108-5.241A.474.474 0 0 0 11 3.002L5 3c-.289 0-.409.118-.498.268-.09.15-1.925 4.935-2.038 5.241-.113.307.123.49.34.49h1.625c.894-.005.988.41.97 1.01v.135c0 .455.141.836.587.872l3.968-.009c.425-.028.546-.507.546-1.007.028-.608.308-1.002 1.071-1h1.59zM5.5 5h5a.5.5 0 1 1 0 1h-5a.5.5 0 0 1 0-1zm0 2h5a.5.5 0 1 1 0 1h-5a.5.5 0 0 1 0-1z" fillRule="evenodd">
                                </path>
                            </svg> */}
                            <svg className="interface-icon o__standard" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                <path
                                    d="M15 8.482v4.679a.87.87 0 0 1-.875.839H1.875A.867.867 0 0 1 1 13.179V9.045c0-.048.024-.149.071-.304l2.554-6.205A.887.887 0 0 1 4.429 2h7.142a.84.84 0 0 1 .804.554l2.554 6.098c.016.05.03.183.04.347.022.376.031-.579.031-.517zm-1.84.517c.284-.002.493-.204.394-.49-.052-.15-.755-1.898-2.108-5.241A.474.474 0 0 0 11 3.002L5 3c-.289 0-.409.118-.498.268-.09.15-1.925 4.935-2.038 5.241-.113.307.123.49.34.49h1.625c.894-.005.988.41.97 1.01v.135c0 .455.141.836.587.872l3.968-.009c.425-.028.546-.507.546-1.007.028-.608.308-1.002 1.071-1h1.59zM5.5 5h5a.5.5 0 1 1 0 1h-5a.5.5 0 0 1 0-1zm0 2h5a.5.5 0 1 1 0 1h-5a.5.5 0 0 1 0-1z" fillRule="evenodd">
                                </path>
                            </svg>
                        </a>
                    <Else />
                        <a href="#" title="Close conversation" onClick={(e) => closeConversation(e)}>
                            <svg className="interface-icon o__standard" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                <path
                                    d="M15 8.482v4.679a.87.87 0 0 1-.875.839H1.875A.867.867 0 0 1 1 13.179V9.045c0-.048.024-.149.071-.304l2.554-6.205A.887.887 0 0 1 4.429 2h7.142a.84.84 0 0 1 .804.554l2.554 6.098c.016.05.03.183.04.347.022.376.031-.579.031-.517zm-1.84.517c.284-.002.493-.204.394-.49-.052-.15-.755-1.898-2.108-5.241A.474.474 0 0 0 11 3.002L5 3c-.289 0-.409.118-.498.268-.09.15-1.925 4.935-2.038 5.241-.113.307.123.49.34.49h1.625c.894-.005.988.41.97 1.01v.135c0 .455.141.836.587.872l3.968-.009c.425-.028.546-.507.546-1.007.028-.608.308-1.002 1.071-1h1.59zM5.5 5h5a.5.5 0 1 1 0 1h-5a.5.5 0 0 1 0-1zm0 2h5a.5.5 0 1 1 0 1h-5a.5.5 0 0 1 0-1z" fillRule="evenodd">
                                </path>
                            </svg>
                        </a>
                    </If>
                </li>
                <li className="showOnlyTab" onClick={(e) => activeRightSidebar(e)}>
                    <KeyboardArrowLeftIcon className="keyboard_left_arrow keyboard_arrow waves-effect" />
                    {/* <KeyboardArrowRightIcon className="keyboard_right_arrow keyboard_arrow" /> */}
                </li>
            </ul>
        </div>
    );
}


const mapStateToProps = state => {
    return {
        selectedContact: state.inboxReducer.selectedContact,
        selectedContactIndex: state.inboxReducer.selectedContactIndex,
        conversations: state.inboxReducer.conversations,
        contactDetails: state.rightsideReducer.contact,
        storedUnreadContactId: state.inboxReducer.updateUnreadContactId
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateSelectedContact: (params) => dispatch(updateSelectedContact(params)),
        markAsImportant: (params, callback) => dispatch(markAsImportant(params, callback)),
        markAsUnread: (params, callback) => dispatch(markAsUnread(params, callback)),
        deleteLead: (params, callback) => dispatch(deleteLead(params, callback)),
        archiveLead: (params, callback) => dispatch(archiveLead(params, callback)),
        moveToInboxFromArchive: (params, callback) => dispatch(moveToInboxFromArchive(params, callback)),
        storeSelectedContact: (params) => dispatch(addSelectedContact(params)),
        toggleRightSidebar: () => dispatch(toggleRightSidebar()),
        updateInboxUnreadContact: (params)=> dispatch(updateInboxUnreadContactInfo(params))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TopRightActions);
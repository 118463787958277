import React, { useState } from "react";
import { Box, Button, makeStyles } from "@material-ui/core";
import EachPhoneNumber from "./EachPhoneNumber";
import EditPhoneNumberModal from "./EditPhoneNumberModal";
import { setContactDetails } from "../../../../actions/contactAction";
import { connect } from "react-redux";
import Utils from "../../../../helpers/Utils";
import { showValidator, toLowerCase } from "../helper/utils";
import {
  LOOKUP_TYPE_CARRIER_LOOKUP,
  LOOKUP_TYPE_DNC_LOOKUP,
} from "../../../../constants/CoreConstants";
import CustomTitle from "../common/customTitle/CustomTitle";
import Styles from "../common/customTitle/customTitle.module.css";

const useStyles = makeStyles({
  hoverDiv: {
    "&:hover": {
      "& $icon": {
        opacity: 1,
        transition: "width .35s",
        width: "42px",
        display: "flex",
      },
    },
  },
  icon: {
    opacity: 0,
    width: 0,
    display: "none",
  },
});

const PhoneNumber = (props) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const icons = useStyles();

  return (
    <div>
      <div className={icons.hoverDiv}>
        <div style={{ marginBottom: 16 }}>
          <div
            style={{
              display: "flex",
              paddingRight: "7px",
              justifyContent: "end",
              gap: 8,
            }}
          >
            {props.contact &&
              props.contact.number &&
              Utils.getAccountData("dnc") &&
                <Button
                    className={`${Styles.buttonStyle} ${Styles.checkButton}`}
                    variant="contained"
                    color="primary"
                    size="small"
                    disableElevation
                    onClick={handleOpen}
                >
                  Check Dnc
                </Button>
              }
            {props.contact &&
              props.contact.number &&
              Utils.getAccountData("carrierLookup") &&
                <Button
                    className={`${Styles.buttonStyle} ${Styles.validButton}`}
                    variant="contained"
                    color="primary"
                    size="small"
                    disableElevation
                    onClick={handleOpen}
                >
                  Validate
                </Button>
              }
          </div>

          <CustomTitle
            handleOpen={handleOpen}
            title={"PHONE NUMBER"}
            iconHide={icons.icon}
          />
        </div>

        {props.contact && props.contact.number && (
          <Box sx={{ gap: 10, mb: 1 }}>
            <EachPhoneNumber
              value={props.contact.number}
              contact={props.contact}
              numberState={toLowerCase(
                props?.contact?.contact_additional_informations?.number_state
              )}
            />
          </Box>
        )}

        {props.contactAdditionalContacts.map(
          (contactAdditionalContact, index) =>
            contactAdditionalContact.number ? (
              <div style={{ gap: 10, marginBottom: 8 }}>
                <EachPhoneNumber
                  contact={props.contact}
                  id={contactAdditionalContact.id}
                  value={contactAdditionalContact.number}
                  numberState={toLowerCase(
                    contactAdditionalContact.number_state
                  )}
                  contactAdditionalContact={contactAdditionalContact}
                  index={index}
                />
              </div>
            ) : (
              <div />
            )
        )}

        {open && <EditPhoneNumberModal open={open} onClose={handleClose} />}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.rightsideReducer.contact,
    contactAdditionalContacts: state.inboxReducer.contactAdditionalContacts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setContactDetails: (params) => dispatch(setContactDetails(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumber);

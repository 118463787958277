import { InputBase, TextField, makeStyles, withStyles } from "@material-ui/core";

export const RightSideV2Styles = makeStyles((theme) => ({
  darkBlue: {
    color: "#181F48",

    "&:first-letter": {
      textTransform: "uppercase",
    },
  },

  textWhite: {
    color: "#ffffff",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },

  flexCenter: {
    display: "flex",
    alignItems: "center",
  },

  flexCenterBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  alignJustifyCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  alignJustifyCenterV2: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },

  primaryButton: {
    textTransform: "none",
    background: "var(--light_blue)",
    "&:hover, &:focus": {
      background: "var(--light_blue)",
    },
  },
  heighFit: {
    height: "fit-content !important",
  },

  primaryButtonRounded: {
    textTransform: "none",
    background: "var(--light_blue)",
    "&:hover, &:focus": {
      background: "var(--light_blue)",
    },
    borderRadius: 25,
  },

  secondaryButton: {
    color: "white",
    textTransform: "none",
    // background: "#00ff91",
    // "&:hover, &:focus": {
    //   background: "#00ff91",
    // },
    background: "#a352c2",
    "&:hover, &:focus": {
      background: "#a352c2",
    },
  },

  secondaryButtonRounded: {
    color: "white",
    textTransform: "none",
    // background: "#00ff91",
    // "&:hover, &:focus": {
    //   background: "#00ff91",
    // },
    background: "#a352c2",
    "&:hover, &:focus": {
      background: "#a352c2",
    },

    borderRadius: 25,
  },

  warningButtonRounded: {
    color: "white",
    textTransform: "none",
    // background: "#00ff91",
    // "&:hover, &:focus": {
    //   background: "#00ff91",
    // },
    background: "#708131",
    "&:hover, &:focus": {
      background: "#708131",
    },

    borderRadius: 25,
  },
  // secondaryButton: {
  //   color: 'white',
  //   textTransform: 'none',
  //   // background: "#00ff91",
  //   // "&:hover, &:focus": {
  //   //   background: "#00ff91",
  //   // },
  //   background: '#a352c2',
  //   '&:hover, &:focus': {
  //     background: '#a352c2',
  //   },
  // },
  skiptraceButton: {
    background: "#00ff91 !important",
    "&:hover, &:focus": {
      background: "#00ff91 !important",
    },
    color: "#0e0f3f",
    borderRadius: "40px",
    fontSize: "10px",
    padding: "0px 15px",
    fontWeight: "400",
    lineHeight: "0",
    height: "20px",
    marginLeft: "auto",
    textTransform: "uppercase",
  },

  // secondaryButtonRounded: {
  //   color: 'white',
  //   textTransform: 'none',
  //   // background: "#00ff91",
  //   // "&:hover, &:focus": {
  //   //   background: "#00ff91",
  //   // },
  //   background: '#a352c2',
  //   '&:hover, &:focus': {
  //     background: '#a352c2',
  //   },

  //   borderRadius: 25,
  // },

  closeButton: {
    textTransform: "none",
    color: "#ffffff",
    background: "var(--red)",
    "&:hover, &:focus": {
      background: "var(--red)",
    },
  },

  textDarkBlue: {
    color: "#181F48",
  },

  linkUnderLine: {
    color: "var(--light_blue)",
    textDecoration: "underline",
    fontWeight: 500,
  },

  customScrollBar: {
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "#2c3e50",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#706fd3",
      borderRadius: "10px",
      // outline: '1px solid #8e44ad'
    },
  },
  customScrollBarThin: {
    "&::-webkit-scrollbar": {
      width: "5px !important",
    },
  },
  primaryChip: {
    background: "#181F48",

    // Alternate Design
    // background: "#0e0f3f",

    "& .MuiChip-deleteIcon": {
      color: "var(--light_blue)",
    },
  },

  titleIcon: {
    cursor: "pointer",

    "& svg": {
      fontSize: 18,
    },

    "&:focus, &:hover ": {
      background: "transparent",
    },
  },

  draggableWrap: {
    position: "relative",
    "& .MuiIconButton-sizeSmall": {
      visibility: "hidden",
      position: "absolute",
      left: -25,
    },

    "&:hover .MuiIconButton-sizeSmall": {
      visibility: "visible",
    },
  },
  phoneButtonRounded: {
    textTransform: "none",
    color: "white",
    background: "#316aff",
    "&:hover, &:focus": {
      background: "#316aff",
    },
    borderRadius: 25,
  },
  // darkBlue: {
  //   color: '#181F48',

  //   '&:first-letter': {
  //     textTransform: 'uppercase',
  //   },
  // },

  contactInfoAccordion: {
    // display: 'flow !important',
    maxHeight: "700px !important",
    overflowY: "auto !important",
    // overflowX: "hidden !important",
  },

  titleIconHover: {
    cursor: "pointer",
    position: "absolute",
    right: "-24px",
    "& svg": {
      fontSize: 18,
    },

    "&:focus, &:hover ": {
      background: "transparent",
    },
  },
  flexCenterV2: {
    display: "flex",
    flexDirection: "row",
  },
  titleIconHoverV2: {
    cursor: "pointer",
    position: "absolute",
    right: "-24px",
    top: "34px",
    "& svg": {
      fontSize: 18,
    },

    "&:focus, &:hover ": {
      background: "transparent",
    },
  }
}));

export const BasicInput = withStyles((theme) => ({
  root: {
      "label + &": {
          marginTop: theme.spacing(3),
      },
      "&.MuiInputBase-multiline": {
          padding: "0 !important",
      },
      "& .MuiInputAdornment-positionEnd": {
          position: "absolute",
          right: "12px"
      },
  },
  input: {
      position: "relative",
      backgroundColor: "white !important",
      fontSize: "15px !important",
      margin: "0 !important",
      height: "2.5rem !important",
      padding: "10px 12px 10px  !important",
      borderRadius: "4px !important",
      border: "1px solid #ced4da !important",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
          borderRadius: 4,
          boxShadow: "unset !important",
      },

      "&.MuiSelect-select": {
          height: "1rem !important",
      },
      "&::placeholder": {
          color: "#082852 !important",
      },
  },
}))(InputBase);


export const BasicInputSelect = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    position: "relative",
    color: "var(--dark_blue)",
    fontSize: ".8vw",
    margin: "0 !important",
    padding: "10px 12px 10px  !important",
    borderRadius: "4px !important",
    border: "1px solid #ced4da !important",
    transition: theme.transitions.create(["border-color", "box-shadow"]),

    "&:focus": {
      borderRadius: 4,
      boxShadow: "unset !important",
    },
  },
}))(InputBase);

export const BasicTextField = withStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      padding: "0 !important",
      "& .MuiInputBase-input": {
        position: "relative",
        color: "var(--dark_blue)",
        fontSize: "15px !important",
        margin: "0 !important",
        height: "2.5rem !important",
        paddingLeft:'12px !important',
        borderRadius: "4px !important",
        // border: "1px solid #ced4da !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),

        "&:focus": {
          borderRadius: 4,
          boxShadow: "unset !important",
        },
        "&:hover": {
          border:'none'
        },
      },
    },
    '& input[type=text]:not(.browser-default)': {
       border:'none'
     }
  },
  
}))(TextField);
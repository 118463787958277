import React, { useState } from 'react';
import SmartFormEntryDetails from "./SmartFormEntryDetails";

const FormEntry = ({entry}) => {
    const [openModal, setOpenModal] = useState(false);
    const toggleOpenModal = (e) => {
        e.preventDefault();
        setOpenModal(!openModal);
    }

    return (
        <React.Fragment>
            <div className="m-widget2__item complete__form">
                <div className="m-widget2__desc">
                    <a onClick={toggleOpenModal} className="image-popup-container" href="#!">
                        {entry.title} <i className="fa fa-wpforms"/><br/>
                        <p className="text-dark">{entry.createdAt  ? window.globalTimezoneConversionToDifferentTimezone(entry.createdAt ,'UTC','','Y-MM-D hh:mm:ss') : ""}</p>
                    </a>
                </div>
            </div>
            {
                openModal && (
                    <SmartFormEntryDetails
                        entry={entry}
                        open={openModal}
                        closeModal={() => setOpenModal(false)}
                    />
                )
            }
        </React.Fragment>
    )
}

export default FormEntry;

import { sendRequest, sendRequestBackEnd } from './rootApi';
import Api from "../constants/Api";

export const fetchConversation = async (payload) => {
    return sendRequest("post", Api.conversations, payload);
}

export const fetchVirtualNumber = async (payload) => {
    return sendRequest("post", Api.virtualNumbers, payload);
}

export const fetchCustomField = async () => {
    return sendRequest("post", Api.customFields);
}

export const fetchEmails = async (payload) => {
    return sendRequest("post", Api.emails, payload);
}

export const sendMessage = async (payload) => {
    return sendRequest("post", Api.sendMessage, payload);
}

export const sendVideoEmail = async (payload) => {
    return sendRequest("post", Api.sendBulkMessage, payload);
}

export const addNoteSubmit = async (payload) => {
    return sendRequest("post", Api.addNote, payload);
}

export const markAsImportant = async (payload) => {
    return sendRequest("post", Api.markAsImportant, payload);
}

export const markAsUnread = async (payload) => {
    return sendRequest("post", Api.markAsUnread, payload);
}

export const deleteContact = async (payload) => {
    return sendRequest("post", Api.deleteContact, payload);
}

export const archiveContact = async (payload) => {
    return sendRequest("post", Api.archiveContact, payload);
}

export const moveFromArchive = async (payload) => {
    return sendRequest("post", Api.moveFromArchive, payload);
}

export const exportConversation = async (payload) => {
    return sendRequest("get", Api.exportConversation, payload);
}

export const getMessageTemplate = async (payload) => {
    return sendRequest("post", Api.getMessageTemplate, payload);
}

export const getUserEmailList = async (payload) => {
    return sendRequest("post", Api.userEmailList, payload);
}


export const producePDF = async (payload)=>{
    return sendRequest("post", Api.producePDF, payload);
}

export const createCardBroadcast = async (payload)=>{
    return sendRequest("post", Api.addCardBroadcastToContact, payload);
}

export const getThirdPartyService = async (payload)=>{
    return sendRequest("post", Api.getThirdPartyInfo, payload);
}
export const getMailCategory = async (payload)=>{
    return sendRequest("post", Api.getMailCategory, payload);
}

export const getMailTemplate = async (payload)=>{
    return sendRequest("post", Api.getMailTemplate, payload);
}

export const createMailTemplate = async (payload)=>{
    return sendRequest("post", Api.createMailTemplate, payload);
}

export const getCustomFields = async (payload) => {
    return sendRequest("post", Api.getCustomFields, payload);
}

export const addCardBroadcastToContact = async (payload) =>{
    return sendRequest("post", Api.addCardBroadcastToContact, payload);
}

export const searchElasticInboxThread = async (payload) =>{
    return sendRequestBackEnd("post", Api.searchElasticInboxThread, payload);
}

export const formBuilderAWSPreSignedUrl = async (payload) =>{
    return sendRequest("post", Api.formBuilderAWSPreSignedUrl, payload);
}

export const getAllAdditionalContactApi=(payload)=>{
    return sendRequest("POST", Api.getAllAdditionalContactApi, payload);
}
export const addTagsV2 = async (payload) => {
  return sendRequest("post", Api.addTagsV2, payload)
}
export const getUserTagListNew = async (payload) => {
    return sendRequest("post", Api.tagListNew, payload);
}
import React from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel, FormHelperText,
  MenuItem,
  Radio,
  Select,
  Typography,
} from "@material-ui/core";
import { BasicInput, BasicInputSelect } from "../RightSideV2Styles";

const DynamicInputField = (props) => {
  const handleCommonField = (e) => {
    props.callback({
      [`${props.title}-${props.data.id}`]: {
        id: props.data.id,
        value: e.target.value,
      },
    });
  };

  const handleCheckBox = (event, defaultValue) => {
    let check = event.target.checked;
    let data = "";
    let newValue = event.target.value;

    if (check) {
      if (defaultValue === "") data = newValue;
      else data = defaultValue + "," + newValue;
    } else {
      if (defaultValue.indexOf(newValue) === 0) {
        if (defaultValue.indexOf(newValue + ",") === 0)
          data = defaultValue.replace(newValue + ",", "");
        else data = defaultValue.replace(newValue, "");
      } else {
        data = defaultValue.replace("," + newValue, "");
      }
    }

    props.callback({
      [`${props.title}-${props.data.id}`]: {
        id: props.data.id,
        value: data,
      },
    });
  };

  const SelectBox = ({ value, onChange }) => {
    return (
      <FormControl fullWidth>
        <Select
          labelId="dropdown-label"
          id="dropdown"
          value={value}
          displayEmpty
          onChange={onChange}
          input={<BasicInputSelect />}
          inputProps={{ "aria-label": "Without label" }}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          <MenuItem className="dropdownhelper-menuitem-class" value={""}>
            {`Select ${props.title}`}
          </MenuItem>
          {props.data &&
            props.data.default_values &&
            props.data.default_values.map((item, index) => (
              <MenuItem
                key={index}
                className="dropdownhelper-menuitem-class"
                value={item}
              >
                {item}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  };

  const RadioBox = ({ value, onChange }) => {
    let content = null;

    if (props.data && props.data.default_values) {
      content = props.data.default_values.map((data, index) => {
        let checked = data === value ? "checked" : null;
        return (
          <FormControlLabel
            key={index}
            control={
              <Radio
                checked={Boolean(checked)}
                onChange={onChange}
                name="radio-move"
                value={data}
              />
            }
            label={data}
          />
        );
      });
    }

    return (
      <Box sx={{ display: "flex", flexDirection: "row", gap: "6px", flexWrap: "wrap" }}>
        {content}
      </Box>
    );
  };

  const CheckBox = ({ onChange, value }) => {
    let content = null;
    let userValues = [];
    if (value) {
      userValues = value.split(",");
    }
    if (props.data && props.data.default_values) {
      content = props.data.default_values.map((data, index) => {
        let checked = userValues.indexOf(data) > -1 ? "checked" : null;

        return (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={Boolean(checked)}
                onChange={onChange}
                name="radio-move"
                value={data}
              />
            }
            label={data}
          />
        );
      });
    }
    return (
      <Box sx={{ display: "flex", flexDirection: "row", gap: "6px", flexWrap: "wrap" }}>
        {content}
      </Box>
    );
  };

  const renderField = () => {
    let field = null;
    let defaultValue = props.values[`${props.title}-${props.data.id}`]
      ? props.values[`${props.title}-${props.data.id}`].value
      : "";

    if (props.fieldType === "text") {
      field = (
        <BasicInput
          fullWidth
          type="text"
          onChange={handleCommonField}
          value={defaultValue}
          placeholder={`Enter ${props.title}`}
        />
      );
    } else if (props.fieldType === "date") {
      field = (
        <BasicInput
          fullWidth
          type="date"
          onChange={handleCommonField}
          value={defaultValue}
          placeholder={`Enter ${props.title}`}
        />
      );
    } else if (props.fieldType === "datetime") {
      const formatInitialDate = (dateString) => {
        if (dateString) {
          const dateObject = new Date(dateString);
          const offset = dateObject.getTimezoneOffset();
          dateObject.setMinutes(dateObject.getMinutes() - offset);
          return dateObject.toISOString().slice(0, 16);
        }
      };

      field = (
        <BasicInput
          fullWidth
          type="datetime-local"
          onChange={handleCommonField}
          value={formatInitialDate(defaultValue)}
          placeholder={`Enter ${props.title}`}
        />
      );
    } else if (props.fieldType === "number") {
      field = (
        <BasicInput
          fullWidth
          type="number"
          onChange={handleCommonField}
          value={defaultValue}
          placeholder={`Enter ${props.title}`}
          maxLength={props.maxLength}
        />
      );
    } else if (props.fieldType === "select") {
      field = <SelectBox onChange={handleCommonField} value={defaultValue} />;
    } else if (props.fieldType === "radio") {
      field = <RadioBox onChange={handleCommonField} value={defaultValue} />;
    } else if (props.fieldType === "checkbox") {
      field = (
        <CheckBox
          onChange={(event) => {
            handleCheckBox(event, defaultValue);
          }}
          value={defaultValue}
        />
      );
    } else {
      field = (
        <BasicInput
          fullWidth
          type="text"
          multiline
          onChange={handleCommonField}
          value={defaultValue}
          placeholder={`Enter ${props.title}`}
        />
      );
    }

    return field;
  };

  return (
    <Box>
      <Typography variant="body1" color="textPrimary">
        {props.data.title}
      </Typography>
      {renderField()}
      {
          props.error &&
          <FormHelperText>
            {props.error}
          </FormHelperText>
      }
    </Box>
  );
};

export default DynamicInputField;

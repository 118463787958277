import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import VideoEmail from "../components/Video/VideoEmail";

const Video = props => {
    const [emailVideoId, setEmailVideoId] = useState(null);
    const [subject, setSubject] = useState('');
    const [emailBody, setEmailBody] = useState('');

    const useMessageTemplate = data => {
        console.log('useMessageTemplate');
        setSubject(data.subject);
        setEmailBody(data.message);
        setEmailVideoId(data.campaign_video_file_for_email_id);
    }

    return (
        <VideoEmail from="inbox" emailVideoId={emailVideoId} setEmailVideoId={setEmailVideoId} useMessageTemplate={useMessageTemplate}/>
    );
}

export default Video;

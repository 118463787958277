import inboxMiddleware from './inboxMiddleware';
import { all } from "redux-saga/effects";
import taskMiddleware from './taskMiddleware';
import rightsideMiddleware from '../components/inbox/rightSide/redux/rightsideMiddleware';
import videoEmailMiddleware from '../components/Video/redux/videoEmailMiddleware'
import cardBroadcastMiddleware from '../components/DirectCardSend/redux/cardBroadcastMiddleware';

export default function* rootMiddleware() {
    yield all(
        [
            inboxMiddleware(),
            cardBroadcastMiddleware(),
            rightsideMiddleware(),
            taskMiddleware(),
            videoEmailMiddleware()
        ]
    )
}

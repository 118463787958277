import React, { useState } from "react"
import { CircularProgress, InputAdornment } from "@material-ui/core"
import { connect } from "react-redux"
import GlobalModal from "../Helpers/Modal/GlobalModal"
import { Save } from "@material-ui/icons"
import "../Tag/modal_add_tag_form.css"
import { addTagsV2, getUserTagListNew } from "../../../../api/inboxApi"
import { getUserTagList, setUserTagList } from "../../../../actions/contactAction"
import { icons } from "./icon"
import useDelayCallback from "../../../../hooks/useDelayCallback"
import "./modal_add_tag_form_new.css"
import BasicInput from "../../../custom/BasicInput"
import SearchIcon from '@material-ui/icons/Search';
import TagSkeleton from "./TagSkeleton"

const EachTag = (props) => {
  const checkSelect = (id) => {
    return !!props.selectedTags.includes(id)
  }

  const renderSelectBox = (item) => {
    const flag = checkSelect(item.id)
    // let user = props.subUserList.find((user) => user.id === item.user_id);
    return (
      <div className="custom-checkbox-wrapper" 
      style={{background:"white",
              marginBottom:"10px",
              borderRadius:"5px"
            }}>
        <span
          className="custom-checkbox-icon"
          onClick={() => props.selectTag(item.id)}
          key={`id_${item.id};`}
        >
          {flag ? icons.check : icons.unCheck}
        </span>
        <span>
          {props.data.name}
          {/*&nbsp;- &nbsp;*/}
          {/*<small><code>{user ? user.full_name : 'Unknown User'}</code></small>*/}
        </span>
      </div>
    )
  }

  return <li>{renderSelectBox(props.data)}</li>
}

const ModalAddTagFormNew = (props) => {
  const [tagInfo, setTagInfo] = useState({
    error: false,
    success: false,
    errorMsg: "",
    successMsg: "",
    allTags: [],
    allTagsToShow: [],
    storedTagList: [],
    submitingFile: false,
    submitEnable: false,
    selectedTags: [],
    submittingContact: false,
    createNew: false,
    searchKey: null,
    new: false,
    contactTags: [],
    newTagName: "",
    saveButtonText: "Assign Tag",
  })

  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage, setPerPage] = useState(50)
  const [hasMore, setHasMore] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [search, setSearch] = useState("")
  const [list, setList] = useState([])

  useDelayCallback(() => {
    let perPageCountCal = 50
    if (perPage === 50) {
      perPageCountCal = Math.round((document.body.clientHeight - 300) / 40) + 5
      setPerPage(perPageCountCal)
    } else {
      perPageCountCal = perPage
    }
    getAllTagsForDropdown(perPageCountCal)
  }, [search])

  const getAllTagsForDropdown = (page = perPage) => {
    setIsLoading(true)
    getUserTagListNew({
      per_page: page,
      current_page: currentPage,
      search: search,
    })
      .then((response) => {
        let res = response.data
        if (currentPage === 1) {
          if (res.length == 0) {
            setTagInfo({
              ...tagInfo,
              createNew: true,
              newTagName: search,
            })
          }
          setList(res)
        } else {
          let all_data = [...list]
          all_data = [...all_data, ...res]
          setTagInfo({
            ...tagInfo,
            createNew: false,
            newTagName: "",
          })
          setList(all_data)
        }

        if (res.length === page) {
          setCurrentPage(parseInt(currentPage + 1))
          setHasMore(true)
        } else {
          setHasMore(false)
        }
        setLoadingMore(false)
        setIsLoading(false)
      })
      .catch((error) => {
        setLoadingMore(false)
        setIsLoading(false)
      })
  }

  const handleOnScrollLoad = (e) => {
    if (hasMore && !loadingMore) {
      if (
        Math.round(e.target.scrollTop + e.target.clientHeight, 10) >=
        Math.round(e.target.scrollHeight, 10)
      ) {
        setLoadingMore(true)
        getAllTagsForDropdown(perPage)
      }
    }
  }
  const submitTag = (tagName) => {
    addTagsV2({
      contact_id: props.contact_id,
      tags: tagName,
      new: 0,
      assign: true,
    })
      .then((res) => {
        setTagInfo({
          ...tagInfo,
          submittingContact: false,
        });
        if (res.data.status === "error") {
          setTagInfo({
            ...tagInfo,
            error: true,
            errorMsg: res.data.html,
          });
          window.showNotification("ERROR", "An error occurred: " + res.data.html);
          setTimeout(() => {
            setTagInfo({
              ...tagInfo,
              error: false,
              errorMsg: "",
            });
          }, 5000);
        } else if (res.data.status === "validation-error") {
          window.showNotification("WARNING", "Validation error: " + res.data.html);
        } else if (res.data.status === "success") {
          window.showNotification("SUCCESS", "Tag assigned successfully!");
          props.callBack(true, true, res.data.data);
        } else {
          window.showNotification("INFO", "Operation completed with unknown status.");
          toggle(true);
        }
      })
      .catch((error) => {
        window.showNotification("ERROR", "An unexpected error occurred!");
        console.log(error);
      });
  };
  

  const submitTagNew = (tagName) => {
    addTagsV2({
      contact_id: props.contact_id,
      tags: tagName,
      new: tagInfo.new,
      assign: false,
    }).then((res) => {
      setTagInfo({
        ...tagInfo,
        submittingContact: false, 
      });

      if (res.data.status === "error") {
        setTagInfo({
          ...tagInfo,
          error: true,
          errorMsg: res.data.html,
        });

        window.showNotification("ERROR", "An error occurred: " + res.data.html);
        setTimeout(() => {
          setTagInfo({
            ...tagInfo,
            error: false,
            errorMsg: "",
          });
        }, 5000);
      } else if (res.data.status === "validation-error") {
        window.showNotification("WARNING", "Validation error: " + res.data.html);

      } else if (res.data.status === "success") {
        window.showNotification("SUCCESS", "Tag added successfully!");
        props.callBack(true, true, res.data.data);

      } else {
        setTagInfo({
          ...tagInfo,
          createNew: false,
          searchKey: null,
        });
        window.showNotification("INFO", "Tag operation completed with an unknown status.");
      }
    }).catch(error => {
      window.showNotification("ERROR", "An unexpected error occurred!");
      console.error("Error:", error);
    });
};


  const handleSubmit = (e) => {
    e.preventDefault()

    if (tagInfo.submittingContact) {
      return false
    }

    if (!tagInfo.createNew && tagInfo.selectedTags.length === 0) {
      window.showNotification("WARNING", "Please select any tag", 5000)
      return false
    }

    setTagInfo({
      ...tagInfo,
      submittingContact: true,
    })

    if (props.contact_id !== undefined) {
      if (tagInfo.createNew) {
        submitTagNew(tagInfo.newTagName)
      } else {
        submitTag(tagInfo.selectedTags)
      }
    } else {
      if (tagInfo.createNew) {
        props.callBack("newTags", tagInfo.newTagName)
        if (props.hideModal !== undefined) {
          props.hideModal()
        }
      } else {
        if (props.from !== undefined && props.from === "contact-file-upload") {
          let allTagsFilter = list.filter(
            (item) => tagInfo.selectedTags.indexOf(item.id) >= 0
          )
          props.callBack("oldTags", allTagsFilter)
          if (props.hideModal !== undefined) {
            props.hideModal()
          }
        } else {
          let allTagsFilter = list.filter(function (e) {
            return this.indexOf(e.id.toString()) >= 0
          }, tagInfo.selectedTags)
          props.callBack("oldTags", allTagsFilter)
          if (props.hideModal !== undefined) {
            props.hideModal()
          }
        }
      }
    }
  }

  const toggle = (update = false) => {
    setTagInfo({
      ...tagInfo,
      isOpen: !tagInfo.isOpen,
    })

    if (update) {
      props.callBack(update)
    } else {
      props.callBack()
    }
  }

  const handleSelectCheckbox = (value) => {
    let allTags = tagInfo.selectedTags
    if (allTags.includes(value)) {
      allTags.splice(allTags.indexOf(value), 1)
    } else {
      allTags.push(value)
    }

    setTagInfo({
      ...tagInfo,
      selectedTags: allTags,
      submitEnable: allTags.length > 0,
    })
  }

  const changeSearch = (e) => {
    let searchKey = e.target.value.toUpperCase().trim()
    setCurrentPage(1)
    setSearch(searchKey)
    setTagInfo({
      ...tagInfo,
      createNew: false,
    })
  }

  const runningList = () => {
    let runList = []
    list.forEach((data, index) => {
      runList.push(
        <EachTag
          subUserList={props.subUserList}
          selectedTags={tagInfo.selectedTags}
          selectTag={handleSelectCheckbox}
          data={data}
          key={index}
        />
      )
    })

    return runList
  }

  return (
    <GlobalModal
      {...props}
      title={"Assign Tag"}
      buttonText={tagInfo.saveButtonText}
      buttonIcon={<Save />}
      onSubmit={handleSubmit}
      hideFooter={tagInfo.createNew ? false : false}
      className={"manage-tag-modal modal-assign-campaign global-small-modal"}
    >
      <div className="row">
        <div className="col-md-12">
          <div>
            <form action="">
              <label htmlFor="" className="mb-2">
                Type to search/create tag
              </label>
              <div style={{paddingBottom:"5px"}}>
                <BasicInput 
                   onChange={changeSearch} 
                   placeholder="Type and search" 
                   fullWidth   
                   endAdornment={
                    <InputAdornment position="end">
                    <SearchIcon style={{ color: "#656565" , marginRight:"13px"}} />
                    </InputAdornment>
                    }
                  />
              </div>
            </form>
          </div>
          {isLoading ? (
              <TagSkeleton/>
            ):(
          <div className="campaign-search-list">
            <div className="campaign-search-results">
              {tagInfo.createNew && (
                <div className="not_found_tag">
                  <button
                    className="create_new_tag"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Create and assign with this <strong>{search}</strong>
                  </button>
                  <p>
                    No tags created with <b>{search}</b>
                  </p>
                </div>
              )}
              <div
                className=""
                onScroll={handleOnScrollLoad}
              >
                <ul 
                // style={{paddingLeft:"",backgroundColor:"White",borderColor:"solid"}}
                >
                  {runningList()}
                </ul>
              </div>
            </div>
          </div>)}
        </div>
      </div>
    </GlobalModal>
  )
}


const mapStateToProps = (state) => {
  return {
    userTagList: state?.rightsideReducer?.userTagList,
    subUserList: state.rightsideReducer.subUserList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserTagList: (params, callback) =>
      dispatch(getUserTagList(params, callback)),
    storeUserTagList: (params, callback) => dispatch(setUserTagList(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddTagFormNew)

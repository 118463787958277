import {
  Avatar,
  Box,
  Button,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ShareIcon from "@material-ui/icons/Share";
import React, { useEffect, useState } from "react";

import BootstrapTooltip from "../../../globals/BootstrapTooltip";
import { fetchTasks, setContactDetails } from "../redux/contactAction";
import { toggleRightSidebar } from "../../../../actions/inboxAction";
import { connect } from "react-redux";
import ShareContactModal from "../ShareContactModal";
import Utils from "../Helpers/Utils";

import Conversation from "./Conversation";
import LeadOwner from "../LeadOwner";
import LeadOwnerCopy from "./LeadStatus/LeadOwnerCopy";
import { fetchContactDetails } from "../redux/contactApi";
import { getContactSmartFormEntries } from "../../../../api/contactApi";
import LeadStatus from "./LeadStatus/LeadStatus";

const useStyles = makeStyles({
  root: {
    width: 50,
    height: 50,
    border: "1px solid #a29bfe",
    borderRadius: 50,
    position: "absolute",
    top: "-31px",
    // bottom: 110,
    background: "rgba(48, 51, 107,1.0)",
  },
  shareIcon: {
    background: "#E5E5E5",
    color: "var(--light_blue)",
    cursor: "pointer",

    "&:focus": {
      background: "#E5E5E5",
    },
    "& .MuiSvgIcon-root": {
      fontSize: 14,
    },
  },
  titleText: {
    "&.MuiTypography-root": {
      height: "auto",
      textAlign: "center",
    },
    color: "#ffffff",
  },
  contactText: {
    "&.MuiTypography-root": {
      height: "auto !important",
      textAlign: "center !important",
    },
    color: "#ffffff",
    fontWeight: 600,
  },
  boxClass: {
    "&.MuiBox-root": {
      width: "100%",
    },
  },
  enhanceButton: {
    "&.MuiButton-root": {
      borderRadius: 25,
      paddingBlock: 2,
      fontSize: "11px",
      backgroundColor: "#00ff91",
      color: "#000000",
      marginTop: "12px",
      paddingInline:"16px"
    },
  },
})

const padZero = (value) => {
  return value < 10 ? `0${value}` : value;
};

const RightSideHeader = (props) => {
  const [state, setState] = useState({
    isOpen: false,
    firstNameInvalidError: false,
    firstNameLengthError: false,
    lastNameInvalidError: false,
    lastNameLengthError: false,
    showShareContactModal: false,
    dncStatus: "NOT_VERIFIED",
  });
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  
  const firstName = (props.contact?.first_name || "").trim();
  const lastName = (props.contact?.last_name || "").trim();

  const userName = `${firstName} ${lastName}`.trim() || "N/A";

   const updateDncStatus = () => {
     return (
       props.contact &&
       props.contact?.number &&
       props.contact?.number.trim() !== "" &&
       props.contact?.contact_additional_informations && props.contact
         ?.contact_additional_informations?.dnc_status === "DO_NOT_CALL"
     )
   }

 

  return (
    <Box className={classes.boxClass} sx={{ pt: 1.5 }}>
      <Box
        className={classes.boxClass}
        boxShadow={1}
        sx={{
          padding: 16,
          width: "100%",
          position: "relative",
          borderRadius: 4,
          backgroundColor: " #181F48",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 5,
                marginBottom: 8,
              }}
            >
              <Typography
                variant="h5"
                className={classes.contactText}
                sx={{ marginTop: "0px" }}
              >
                {userName}
              </Typography>

              {Utils.getAccountData("carrierLookup") && updateDncStatus() && (
                <Box
                  sx={{
                    backgroundColor: "#F30000",
                    color: "#fff",
                    fontSize: "10px",
                    padding: "2px 10px",
                    borderRadius: "10px",
                  }}
                >
                  DNC
                </Box>
              )}

              <Box
                className="shareIcon"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setOpen(true)
                }}
              >
                <ShareIcon
                  style={{ color: "white", fontSize: 16 }}
                  fill="white"
                />
              </Box>
            </Box>
            <Typography className={classes.titleText}>
              {props.contact?.company_name !== undefined &&
              props.contact?.company_name !== null
                ? props.contact?.company_name
                : ""}
            </Typography>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <BootstrapTooltip title="Click here for data enhencement">
                <Button
                  variant="contained"
                  size="small"
                  disableElevation
                  className={classes.enhanceButton}
                  onClick={() => {
                    window.globalEnhancedData(true, {
                      id: props.contact.id,
                      email: props.contact.email,
                      number: props.contact.number,
                      address: props.contact.address,
                      city: props.contact.city,
                      state: props.contact.state,
                      zip: props.contact.zip,
                      contact_additional_informations:
                        props.contact.contact_additional_informations,
                      requestModule: "CONTACT",
                      callback: (res) => {
                        let tempContact = { ...props.contact }

                        const [isValid, inValid, doNotCall, verifiedOk] = [
                          "VALID",
                          "INVALID",
                          "DO_NOT_CALL",
                          "VERIFIED_OK",
                        ]
                        const [requestDone, requestFailed] = [
                          "REQUEST_DONE",
                          "REQUEST_FAILED",
                        ]

                        let cleanDataInfos = null

                        if (res.data && Array.isArray(res.data)) {
                          cleanDataInfos = res.data.find(
                            (validationInfo) =>
                              validationInfo.source === "CONTACT"
                          )
                        }

                        const currentDate = new Date()
                        const formattedDate = `${currentDate.getFullYear()}-${padZero(
                          currentDate.getMonth() + 1
                        )}-${padZero(currentDate.getDate())} ${padZero(
                          currentDate.getHours()
                        )}:${padZero(currentDate.getMinutes())}:${padZero(
                          currentDate.getSeconds()
                        )}`

                        if (cleanDataInfos) {
                          if (!tempContact.contact_additional_informations) {
                            tempContact.contact_additional_informations = {}
                          }

                          if (
                            res["email-lookup-status"] === requestDone &&
                            cleanDataInfos["email-validation-status"]
                          ) {
                            tempContact.contact_additional_informations.email_validation_status =
                              cleanDataInfos["email-validation-status"]

                            if (res["email-validation-infos"]) {
                              tempContact.contact_additional_informations.email_validation_infos =
                                res["email-validation-infos"]
                            } else {
                              tempContact.contact_additional_informations.email_validation_infos =
                                {
                                  state: cleanDataInfos["email-state"],
                                }
                            }

                            tempContact.contact_additional_informations.email_validation_date =
                              formattedDate
                          }

                          if (
                            res["carrier-lookup-status"] === requestDone &&
                            cleanDataInfos["number-validation-status"]
                          ) {
                            tempContact.contact_additional_informations.number_validation_status =
                              cleanDataInfos["number-validation-status"]

                            if (res["number-validation-infos"]) {
                              tempContact.contact_additional_informations.number_validation_infos =
                                res["number-validation-infos"]
                            } else {
                              tempContact.contact_additional_informations.number_validation_infos =
                                {
                                  state: cleanDataInfos["number-state"],
                                }
                            }

                            tempContact.contact_additional_informations.number_state =
                              cleanDataInfos["number-state"]
                            tempContact.contact_additional_informations.number_validation_date =
                              formattedDate
                          }

                          if (
                            res["dnc-lookup-status"] === requestDone &&
                            cleanDataInfos["dnc-state"]
                          ) {
                            tempContact.contact_additional_informations.dnc_status =
                              cleanDataInfos["dnc-state"]
                            tempContact.contact_additional_informations.dnc_validation_date =
                              formattedDate
                          }

                          if (res["skip-trace-lookup-status"] === requestDone) {
                            tempContact.contact_additional_informations.skip_trace_date =
                              formattedDate
                            tempContact.contact_additional_informations.skip_trace_status =
                              "TRACED"
                          }
                        }

                        props.setContactDetails(tempContact)
                      },
                    })
                  }}
                >
                  Enhance Data
                </Button>
              </BootstrapTooltip>
            </div>
          </Box>
        </Box>
        <LeadStatus
          refreshData={props.refreshData}
          data={null}
          contactId={props.contactId}
          from="inbox"
          sourceList={null}
          refreshValue={props.refreshValue}
          contactDetails={props.contactDetails}
        />

        <Conversation />
      </Box>
      {open && (
        <ShareContactModal
          title={"Share contact information"}
          isOpen={open}
          open={open}
          contact_id={props.contact}
          closeModal={() => {
            setOpen(false)
          }}
          onClose={() => {
            setOpen(false)
          }}
        />
      )}
    </Box>
  )
};

const mapStateToProps = (state) => {
  return {
    contact: state.rightsideReducer.contact,
    allActivityList: state.rightsideReducer.allActivityList,
    selectedContact: state.inboxReducer.selectedContact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTasks: (params, callBack) => dispatch(fetchTasks(params, callBack)),
    setContactDetails: (params) => dispatch(setContactDetails(params)),
    toggleRightSidebar: () => dispatch(toggleRightSidebar()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RightSideHeader);

import { withStyles } from "@material-ui/core/styles"
import React, { useEffect, useState } from "react"
import {
  Box,
  FormControl,
  InputBase,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core"
import { connect } from "react-redux"
import { setSubUserListFromAction } from "../../redux/contactAction"
import {updateContactLeadType} from "../../../../../api/contactApi"
import { LEAD_TYPES } from "../../helper/coreConstant"
import Styles from "./leadstatus.module.css"


export const BasicInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "&.MuiInputBase-multiline": {
      padding: "0 !important",
    },
  },
  input: {
    position: "relative",
    fontSize: "14px !important",
    margin: "0 !important",
    height: "2.5rem !important",
    padding: "10px 24px 10px 10px !important",
    borderRadius: "4px !important",
    background: "white !important",
    border: "1px solid #ced4da !important",
    transition: theme.transitions.create(["border-color", "box-shadow"]),

    "&.MuiSelect-select:focus": {
      background: "white !important",
    },
  },
}))(InputBase);

const LeadType = (props) => {
   const initialLeadType = props.leadType || "default";
  const [leadType, setLeadType] = useState(initialLeadType);

  useEffect(() => {
    setLeadType(initialLeadType ? initialLeadType : "default")
  }, [props.contact?.id, initialLeadType])

  const handleSubmit = (event) => {
    updateContactLeadType({
      contactId: props.contact?.id,
      leadType: event.target.value,
    })
      .then((res) => {
        if (res.success) {
          window.showNotification("SUCCESS", res.message)
          setLeadType(event.target.value)
        }
      })
      .catch((error) => {
        window.showNotification("Error", error)
      })
  }

  return (
    <Box className={Styles.leadTypeBoxWidth}>
      <FormControl fullWidth sx={{ marginLeft: 2 }}>
        <Select
          labelId="vn-select-label"
          id="vn-select"
          name="virtual_number_id"
          value={leadType}
          displayEmpty
          onChange={handleSubmit}
          input={<BasicInput />}
          inputProps={{ "aria-label": "Without label" }}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          <MenuItem
            className="dropdownhelper-menuitem-class"
            value="default"
            disabled
          >
            <Typography
              variant="body1"
              color="textSecondary"
              style={{ lineHeight: 1 }}
            >
              Lead Type
            </Typography>
          </MenuItem>

          {LEAD_TYPES.map((leadType) => (
            <MenuItem key={leadType.value} value={leadType.value}>
              <Typography
                variant="body1"
                color="textSecondary"
                style={{ lineHeight: 1 }}
              >
                {leadType.label}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    allContactList: state.rightsideReducer.allContactList,
    userInfo: state.rightsideReducer.userInfo,
    subUserList: state.rightsideReducer.subUserList,
    contact: state.rightsideReducer.contact,
    leadType: state.rightsideReducer.contact?.contact_additional_informations?.lead_type || "default",
  }
  
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSubUserList: (params) => dispatch(setSubUserListFromAction(params)),
    // getAllSubUsers: (params, callback) => dispatch(getSubUserList(params, callback)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadType)

import React, {useState, useEffect } from "react";
import SecurityIcon from '@material-ui/icons/Security';
import EachInsurance from "./EachInsurance";
import StyledAccordion from "../../../common/StyledAccordion";
import { AccordionDetails, AccordionSummary, Avatar, FormControlLabel, makeStyles } from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles({
  policyAccordionDetails: {
    "&.MuiAccordionDetails-root": {
      padding: "8px 0px",
    },
  },
});

const Policy = () => {
  const [count, setCount] = useState(1);


  const classes=useStyles()
  return (
    <div className="" style={{ marginTop: "10px" }}>
      <StyledAccordion>
                <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <FormControlLabel
                        aria-label="Acknowledge"
                        control={
                            <React.Fragment>
                                <div className="collaborator__list__parant">
                                    <div className="collaborator__list__left">
                                        <span className="collaborator__left__icon">
                                          <SecurityIcon/>
                                        </span>
                                        <span className="collaborator__list--title text-dark-blue m-0">Policy </span>
                                        <Avatar>
                        <h6 className='white text-light-blue circle m-0 items_counter d-flex justify-content-center align-items-center'>
                          {count}
                        </h6>
                      </Avatar>{' '}
                                    </div>
                                    
                                </div>
                            </React.Fragment>
                        }
                     label=""/>
                </AccordionSummary>
                <AccordionDetails className={classes.policyAccordionDetails}>
                    <div>
                       <EachInsurance/>
                    </div>
                </AccordionDetails>
            </StyledAccordion>
    </div>
  );
};
export default Policy;

import React, {useState, useEffect, useRef} from "react";
import Modal from '@material-ui/core/Modal'
import {makeStyles} from "@material-ui/core/styles";
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import {connect} from "react-redux";
import CoreConstants from "../../constants/CoreConstants";
import {getMessageTemplates} from "./redux/videoEmailAction";
import Select from "@material-ui/core/Select";
import FormControl from '@material-ui/core/FormControl';
import './saved_reply.css'
import InputLabel from '@material-ui/core/InputLabel';
import If from 'if-else-react';
import GlobalModal from "../globals/Modal/GlobalModal";
import ListSkeleton from "../common/Skeletons/ListSkeleton";
import {LazyLoadImage} from "react-lazy-load-image-component";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}));

const SavedReply = props => {
    let searchTimeout = null;
    const classes = useStyles();
    const [messageTemplates, setMessageTemplates] = useState(props.messageTemplates);
    const [searchKey, setSearchKey] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props.messageTemplates == null) {
            props.getMessageTemplates({ search_key: '', type: props.templateType });
        } else {
            setMessageTemplates(props.messageTemplates);
            setLoading(false);
        }
    }, [props.messageTemplates]);

    useEffect(() => {
        props.getMessageTemplates({ search_key: searchKey, type: props.templateType });
    }, [props.templateType, searchKey]);

    const templateType = (type) => {
        switch (type) {
            case CoreConstants.MESSAGE_TEMPLATE.EMAIL:
                return 'Email';
            case CoreConstants.MESSAGE_TEMPLATE.SMS:
                return 'SMS';
            case CoreConstants.MESSAGE_TEMPLATE.VOICE_MAIL:
                return 'Voice Mail';
            case CoreConstants.MESSAGE_TEMPLATE.VIDEO_EMAIL:
                return 'Video Email';
            case CoreConstants.MESSAGE_TEMPLATE.MMS:
                return 'MMS';
            case CoreConstants.MESSAGE_TEMPLATE.POST_CARD:
                return 'Post Card';
            case CoreConstants.MESSAGE_TEMPLATE.GREETING_CARD:
                return 'Greeting Card';
            case CoreConstants.MESSAGE_TEMPLATE.GIFT_CARD:
                return 'Gift Card';
            case CoreConstants.MESSAGE_TEMPLATE.LETTER:
                return 'Letter';
            default:
                return 'Message Template';
        }
    }

    const selectThisMessageTemplate = (e, data) => {
        e.preventDefault();
        e.stopPropagation();

        props.useMessageTemplate(data);
    }

    const renderMessageTemplates = () => {
        let messageTemplateOptions = [];
        if (!loading) {
            if (messageTemplates != null && messageTemplates.length) {
                messageTemplates.map((data, index) => {
                    messageTemplateOptions.push(
                        <div className="col s6 m6 l4 xl4">
                            <div className="card manage_modal_card">
                                <div className="card-image">
                                    <If condition={props.templateType == CoreConstants.MESSAGE_TEMPLATE.EMAIL}>
                                        <p>Subject: { data.subject }</p>
                                        <p>Message: { data.message }</p>
                                    </If>
                                    <If condition={props.templateType == CoreConstants.MESSAGE_TEMPLATE.SMS}>
                                        <p>{ data.message }</p>
                                    </If>
                                    <If condition={props.templateType == CoreConstants.MESSAGE_TEMPLATE.VOICE_MAIL}>
                                        <audio controls="controls" src={data.video_file_url} />
                                    </If>
                                    <If condition={props.templateType == CoreConstants.MESSAGE_TEMPLATE.VIDEO_EMAIL}>
                                        <LazyLoadImage
                                            onError={(e) => e.target.src = `${process.env.REACT_APP_APP_URL}/assets/images/notFound.png`}
                                            alt={data.gif_file_url}
                                            effect="blur"
                                            src={data.gif_file_url} />
                                    </If>
                                    <If condition={props.templateType == CoreConstants.MESSAGE_TEMPLATE.MMS}>
                                        <p>{ data.message }</p>
                                        <img src={data.video_file_url} alt={data.video_file_url}/>
                                    </If>
                                    <If condition={(props.templateType == CoreConstants.MESSAGE_TEMPLATE.POST_CARD || props.templateType == CoreConstants.MESSAGE_TEMPLATE.GREETING_CARD || props.templateType == CoreConstants.MESSAGE_TEMPLATE.GIFT_CARD || props.templateType == CoreConstants.MESSAGE_TEMPLATE.LETTER)}>
                                        <p>{ data.message }</p>
                                        <img src={data.gif_file_url} alt={data.gif_file_url}/>
                                    </If>
                                </div>
                                <a href="#" onClick={(e) => selectThisMessageTemplate(e, data)}>Use this Template</a>
                                <p className="template-type">{ templateType(data.type) }</p>
                                <p className="template-title">
                                    <span className="truncate">{ data.title }</span>
                                </p>
                            </div>
                        </div>
                    );
                });
            } else {
                return <p>No saved reply found related to video email in this system!</p>
            }
        } else {
            for (let i = 0; i < 6; i++) {
                messageTemplateOptions.push(
                    <div className="col s6 m6 l4 xl4">
                        <div className="card manage_modal_card">
                            <div className="card-image">
                                <ListSkeleton width='100%'/>
                                <ListSkeleton width='100%' height={87}/>
                                <ListSkeleton width='100%' height={70}/>
                            </div>
                        </div>
                    </div>
                );
            }
        }

        return messageTemplateOptions;
    }

    const getMessageTemplates = (event) => {
        props.getMessageTemplates({ search_key: '', type: event.target.value });
    }

    const searchSavedReplyHandler = e => {
        if (searchTimeout) {
            window.clearTimeout(searchTimeout)
        }
        searchTimeout = window.setTimeout(() => {
            setSearchKey(e.target.value)
        }, 500);
        setSearchKey(e.target.value);
    }

    return (
        <GlobalModal modalClass="saved_reply_modal_wrapper" customWidth='950px' open={props.openSavedReplyModal} onClose={() => props.setOpenSavedReplyModal(false)} title={"Saved Reply"}>
            <div className="video_library_wrapper">
                <div className="manage_modal_input_fields d-flex justify-content-start align-items-center my-3">
                    <div className="search_input">
                        <input type="text" placeholder="Type and Search" className="search_form" onChange={(e) => searchSavedReplyHandler(e)}/>
                        <span className="search_icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0)">
                                <path
                                    d="M7.07036 14.6774C8.722 15.8878 10.7698 16.4299 12.8039 16.1953C14.8381 15.9607 16.7087 14.9667 18.0415 13.4121C19.3742 11.8575 20.0709 9.85709 19.992 7.81095C19.9132 5.7648 19.0647 3.82388 17.6162 2.37649C16.1678 0.929091 14.2263 0.0819647 12.1801 0.00458746C10.1339 -0.0727898 8.13391 0.625288 6.58031 1.95916C5.0267 3.29303 4.03403 5.16433 3.80088 7.19867C3.56774 9.23302 4.11131 11.2804 5.32286 12.9312V12.9299C5.27286 12.9674 5.22536 13.0074 5.17911 13.0524L0.366613 17.8649C0.132063 18.0993 0.000226974 18.4172 0.000110626 18.7488C-7.62939e-06 19.0804 0.131603 19.3985 0.365988 19.633C0.600372 19.8676 0.918333 19.9994 1.24992 19.9995C1.58151 19.9996 1.89956 19.868 2.13411 19.6336L6.94661 14.8211C6.99185 14.7765 7.03364 14.7284 7.07161 14.6774H7.07036ZM11.8754 14.9999C10.9725 14.9999 10.0785 14.8221 9.24441 14.4766C8.4103 14.1311 7.65241 13.6247 7.014 12.9863C6.3756 12.3479 5.86919 11.59 5.52369 10.7558C5.17819 9.92174 5.00036 9.02774 5.00036 8.1249C5.00036 7.22206 5.17819 6.32807 5.52369 5.49395C5.86919 4.65984 6.3756 3.90194 7.014 3.26354C7.65241 2.62514 8.4103 2.11873 9.24441 1.77323C10.0785 1.42773 10.9725 1.2499 11.8754 1.2499C13.6987 1.2499 15.4474 1.97423 16.7367 3.26354C18.026 4.55285 18.7504 6.30154 18.7504 8.1249C18.7504 9.94826 18.026 11.6969 16.7367 12.9863C15.4474 14.2756 13.6987 14.9999 11.8754 14.9999Z"
                                    fill="#133159" fillOpacity="0.35"/>
                                </g>
                                <defs>
                                <clipPath id="clip0">
                                <rect width="20" height="20" fill="white" transform="translate(20) rotate(90)"/>
                                </clipPath>
                                </defs>
                            </svg>
                        </span>
                    </div>
                </div>
                <div className="video_thumbnails modal_thumbnail">
                    <div className="row video_thumbnails_scroll awesome__scroll_bar">
                        { renderMessageTemplates() }
                    </div>
                </div>
            </div>
        </GlobalModal>
    );
}

const mapStateToProps = state => {
    return {
        messageTemplates: state.videoEmailReducer.messageTemplates,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getMessageTemplates: (params) => dispatch(getMessageTemplates(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SavedReply);
import React, { useEffect, useState } from 'react'
import {
  getZipCodeByPlace,
  updateContactDetailsV2,
} from '../../../../api/contactApi'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  Typography,
  makeStyles,
} from '@material-ui/core'
import {BasicInput, BasicTextField, RightSideV2Styles} from '../RightSideV2Styles'
import CustomModal from '../common/CustomModal/CustomModal'
import HomeIcon from '@material-ui/icons/Home'
import { US_STATE_LIST } from '../helper/coreConstant'
import { getStateObj } from '../helper/utils'
import { connect } from 'react-redux'
import { setContactDetails } from '../../../../actions/contactAction'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { Autocomplete } from "@material-ui/lab";

const GOOGLE_API_KEY = process.env.REACT_APP_MAP_KEY

const useStyles = makeStyles((theme) => ({
  dialogContentWidth: {
    width: '516px',
  },
  googlePlacesAutocomplete: {
    width: "100%",
    marginTop: theme.spacing(0),
    "& .my-google-places-autocomplete__value-container": {
      padding: "0px 8px",
    },
    "& .my-google-places-autocomplete__input": {
      height: "16px !important",
      textAlign: "left !important",
      margin: "0 !important",
      border: "none !important",
      outline: "none !important",
      boxShadow: "none !important",
    },
    "& .my-google-places-autocomplete__input:focus": {
      border: "none !important",
      outline: "none !important",
      boxShadow: "none !important",
      
    },
    "& .my-google-places-autocomplete__control": {
      fontSize: "15px !important",
    },
    "& .my-google-places-autocomplete__input input": {
      height: "unset !important"
    }
 
  },
  menuItem: {
    '&:hover': {
      backgroundColor: '#cbd4ff',
      color: '#133159',
    },
    '&.Mui-selected': {
      backgroundColor: '#cbd4ff',
      color: '#133159',
    },
  },
}))

const EditAddressModal = ({ open, onClose, contact, setContactDetails }) => {
  const { primaryButton, closeButton, flexColumn, alignJustifyCenter } =
    RightSideV2Styles()

  const [updatedContact, setUpdatedContact] = useState({
    address: '',
    state: '',
    city: '',
    zip: '',
    country: '',
  })
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({
    address: '',
    state: '',
    city: '',
    country: '',
    zip: '',
  })
  const [value, setValue] = useState(null)

  useEffect(() => {
    if (contact) {
      setUpdatedContact({
        address: contact?.address,
        state: contact?.state,
        city: contact?.city,
        zip: contact?.zip,
        country: contact?.country,
      })
    }
  }, [contact])

  const validateFields = () => {
    let isValid = true
    const newErrors = { ...errors }

    if (
      updatedContact.zip !== null &&
      typeof updatedContact.zip !== 'undefined' &&
      updatedContact.zip.trim() !== '' &&
      !/^\d+$/.test(updatedContact.zip.trim())
    ) {
      // Check if zip code is not a number
      newErrors.zip = 'Zip code must be a number'
      isValid = false
    } else {
      newErrors.zip = ''
    }

    setErrors(newErrors)
    return isValid
  }

  const handleSubmit = async () => {
    if (isLoading || !validateFields()) return

    setIsLoading(true)

    const updatedContactWithoutNull = Object.fromEntries(
      Object.entries(updatedContact).map(([key, value]) => [key, value || ''])
    )

    let formData = {
      contactId: contact?.id,
      params: updatedContactWithoutNull,
    }

    try {
      const res = await updateContactDetailsV2(formData)

      if (res.success) {
        if (window.showNotification !== undefined)
          window.showNotification(
            'SUCCESS',
            'Contact information updated successfully.'
          )

        let tmpContact = { ...contact }
        if (!tmpContact.contact_additional_informations) {
          tmpContact.contact_additional_informations = {}
        }
        tmpContact.contact_additional_informations.skip_trace_status =
          'NOT_VERIFIED'

        let updatedAddress = {
          ...tmpContact,
          ...updatedContact,
        }
        setContactDetails(updatedAddress)

        onClose()
      } else {
        if (window.showNotification !== undefined)
          window.showNotification('ERROR', res.message)
      }
    } catch (error) {
      if (window.showNotification !== undefined)
        window.showNotification('ERROR', 'Something went wrong!')
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeField = (value, fieldName) => {
    setUpdatedContact({ ...updatedContact, [fieldName]: value })
    setErrors({ ...errors, [fieldName]: '' })
  }

  const updateFromAddressComponent = (addressComponents, zip) => {
    let streetNumber = "", route = "", city = "", country = "", county = "", state = "";

    addressComponents.forEach((item)=> {
      switch (item.types[0]) {
        case "street_number":
          streetNumber = item.long_name;
          break;
        case "route":
          route = item.long_name;
          break;
        case "administrative_area_level_1":
          state = item.short_name;
          break;
        case "administrative_area_level_2":
          city = item.long_name;
          break;
        case "country":
          country = item.long_name;
          break;
        case "postal_code":
          zip = item.long_name;
          break;
        case "administrative_area_level_3":
          county = item.long_name;
          break;
        default:
          break;
      }
    });

    setUpdatedContact({
      address: route + " " + streetNumber,
      city: county !== "" ? county : city,
      state: state,
      country: country,
      zip: zip,
    });
  };

  const updateFromFormattedAddress = (formattedAddress, zip) => {
    let splitAddress = formattedAddress.split(",");
    let streetNumber = "";

    if (splitAddress.length >= 4 ){
      const [streetAddress, city, state, country] = splitAddress.slice(-4).map((component) => component.trim());
      const [street, ...rest] = splitAddress.slice(0, 2).map((component) => component.trim());

      if (rest.length > 1 && !isNaN(parseInt(rest[0]))) {
        streetNumber = rest[0];
      }

      let cleanState = state;

      if (cleanState){
        let splState = state.split(" ");
        cleanState = splState[0];
        if (splState[1]){
          zip = splState[1];
        }
      }

      setUpdatedContact({
        address: streetNumber ? `${street} ${streetNumber}` : street,
        city: city,
        state: cleanState,
        country: country,
        zip: zip,
      });
    }
  };

  const updateFromDescription = (description, zip) => {
    let splitDescription = description.split(",");
    let address = splitDescription[0] !== undefined ? splitDescription[0].trim() : "";
    let city = splitDescription[1] !== undefined ? splitDescription[1].trim() : "";
    let state = splitDescription[2] !== undefined ? splitDescription[2].trim() : "";
    let country = splitDescription[3] !== undefined ? splitDescription[3].trim() : "";

    setUpdatedContact({
      address: address,
      city: city,
      state: state,
      country: country,
      zip: zip,
    });
  };

  const handleGoogleAddressSearch = async (event) => {
    try{
      if (event && event.value && event.value.place_id){
        const resp = await getZipCodeByPlace({
          placeId: event.value.place_id,
          googleAPIKey: GOOGLE_API_KEY,
        });

        if (resp && resp.data && resp.data.data){
          let data = resp.data.data;

          if (data.result){
            let result = data.result;
            if (result.formatted_address){
              updateFromFormattedAddress(result.formatted_address, resp.data.zip);
            }else if (result.address_components){
              updateFromAddressComponent(result.address_components, resp.data.zip);
            }else {
              if (event.value.description){
                updateFromDescription(event.value.description, resp.data.zip);
              }
            }
          }
        }
      }
    }catch (err){
      console.log(err);
      window.showNotification("ERROR", "Unable to get address. Please try again!");
    }
  };

  const changeZipValue = (event) => {
    if (event.target.value.length <= 10) {
      onChangeField(event.target.value, 'zip')
    }
  }
  const classes = useStyles()
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth={1200}
    >
      <CustomModal
        icon={<HomeIcon />}
        title={'Update Address'}
        open={open}
        onClose={onClose}
      />
      <DialogContent className={classes.dialogContentWidth}>
        <DialogContentText id='alert-dialog-description'>
          <Box className={flexColumn} sx={{ gap: 16 }} py={3}>
            <Box className={flexColumn} sx={{ gap: 5 }}>
              <Typography variant='body1' color='textPrimary'>
                Search Address
              </Typography>
              <Box className={classes.googlePlacesAutocomplete} sx={{mb:2}}>
                <GooglePlacesAutocomplete
                  apiKey={GOOGLE_API_KEY}
                  autoFocus={false}
                  autocompletionRequest={{
                    componentRestrictions: {
                      country: ['us'],
                    },
                  }}
                  selectProps={{
                    value: "",
                    onChange:handleGoogleAddressSearch,
                    placeholder: <span style={{ color: "rgb(8 40 82 / 42%)",fontSize:"15px" }}>Search Address</span>,
                    classNamePrefix: 'my-google-places-autocomplete',
                  }}
                />
              </Box>

              <Typography variant='body1' color='textPrimary'>
                Address
              </Typography>
              <BasicInput
                fullWidth
                placeholder='Enter Address'
                value={updatedContact.address}
                onChange={(event) =>
                  onChangeField(event.target.value, 'address')
                }
              />
            </Box>
            <Box className={flexColumn} sx={{ gap: 5 }}>
              <Typography variant='body1' color='textPrimary'>
                State
              </Typography>

              <FormControl fullWidth sx={{ marginLeft: 2 }}>
                <Autocomplete
                    id="combo-box-demo"
                    options={US_STATE_LIST}
                    value={getStateObj(updatedContact.state) ? getStateObj(updatedContact.state) : ""}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        onChangeField(newValue.value, "state");
                      }
                    }}
                    getOptionLabel={(option) => (option.label)}
                    renderInput={(params) => (<BasicInput {...params} placeholder={"Select State"} variant={"outlined"}/>)}
                    renderOption={(option) => (<Typography noWrap>{option.label}</Typography>)}
                />
              </FormControl>
            </Box>
            <Box className={flexColumn} sx={{ gap: 5 }}>
              <Typography variant='body1' color='textPrimary'>
                City
              </Typography>
              <BasicInput
                fullWidth
                placeholder='Enter City'
                value={updatedContact.city}
                onChange={(event) => onChangeField(event.target.value, 'city')}
              />
            </Box>
            <Box className={flexColumn} sx={{ gap: 5 }}>
              <Typography variant='body1' color='textPrimary'>
                Country
              </Typography>
              <BasicInput
                fullWidth
                placeholder='Enter Country'
                value={updatedContact.country}
                onChange={(event) =>
                  onChangeField(event.target.value, 'country')
                }
              />
            </Box>
            <Box className={flexColumn} sx={{ gap: 5 }}>
              <Typography variant='body1' color='textPrimary'>
                Zip code
              </Typography>
              <BasicInput
                fullWidth
                placeholder='Enter Zip code'
                value={updatedContact.zip}
                onChange={changeZipValue}
              />
              {errors.zip && (
                <Typography variant='body2' color='error'>
                  {errors.zip}
                </Typography>
              )}
            </Box>
          </Box>

          <Divider light />

          <Box className={alignJustifyCenter} sx={{ gap: '10px' }} mt={2}>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              className={primaryButton}
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Update Address
            </Button>
            <Button
              className={closeButton}
              variant='contained'
              color='secondary'
              disableElevation
              onClick={onClose}
            >
              Close
            </Button>
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

const mapStateToProps = (state) => {
  return {
    contact: state.rightsideReducer.contact,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setContactDetails: (params) => dispatch(setContactDetails(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAddressModal)

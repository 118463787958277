import React, {useState} from "react";
import CoreConstants from "../../../constants/CoreConstants";
import Utils from "../rightSide/Helpers/Utils";


const POSTCARD = 6;
const GREETING_CARD = 7;
const GIFT = 8;
const LETTER = 9;
const THANKSIO_GREETING_CARD = 10;

const DirectMail = (props) => {
    const handleNewPostcard = (type = 'postcard') => {
        window.sendGlobalDirectMailV2({
            contactId: props.contactId,
            from: '/inbox',
            card_type: type,
            for: 'inbox-send'
        })
    }

    return (
        <div id="directMmailTab" className="col s12 active inbox__direct__mail__wr">
            
            <div className="chat__bottom_wrapper">
            <div className="direct-mail">
                <div className="mb-0 row">
                    <div className="col s12">
                        <div className="card direct-mail-box-inbox">
                            <p className="center-align">
                                Pick a Card Type
                            </p>
                            <div
                                className="d-flex align-items-center justify-content-center card-listing">
                                {/* {props.thanksIoActive &&   
                                <> 
                                <a href="#!" onClick={(e)=>{e.preventDefault(); props.updateActiveMailItemType(POSTCARD)}}>POST CARD</a>
                                <a href="#!" onClick={(e)=>{e.preventDefault();props.updateActiveMailItemType(THANKSIO_GREETING_CARD)}}>GREETING CARD</a>
                                
                                
                                <a href="#!" onClick={(e)=>{e.preventDefault();props.updateActiveMailItemType(LETTER)}}>LETTER</a>
                                </>
                                }
                                {props.zenDirectActive &&
                                <a href="#!" onClick={(e)=>{e.preventDefault();props.updateActiveMailItemType(GIFT)}}>GIFT</a>
                                } */}

                                {/* {Utils.getAccountData('userId') == 1 && */}
                                {props.thanksIoActive &&
                                <>
                                <a href="#!" onClick={(e)=>{e.preventDefault(); handleNewPostcard('postcard')}}>Postcard</a>
                                <a href="#!" onClick={(e)=>{e.preventDefault(); handleNewPostcard('letter')}}>Letter</a>
                                <a href="#!" onClick={(e)=>{e.preventDefault(); handleNewPostcard('notecard')}}>Notecard</a>
                                </>
                                }
                                {props.zenDirectActive &&
                                <a href="#!" onClick={(e)=>{e.preventDefault(); handleNewPostcard('zendirect_gift')}}>Gift</a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="send-button-area">
                <div className="mb-0 row">
                    <div className="col s8">
                        <div className="mb-0 row">
                            <div
                                className="my-0 input-field col s6 number-input-field personiliz-input-field">
                                <svg width="14" height="8" viewBox="0 0 14 8" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1L7 7L13 1" stroke="#133159"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"/>
                                </svg>
                                <select>
                                    <option value="1" selected>Personalize</option>
                                    <option value="2">Lorem</option>
                                    <option value="3">Ipsum</option>
                                </select>
                            </div>
                            <div className="col s6 quick-reply">
                                <a href="#">
                                    <svg width="10" height="15" viewBox="0 0 10 15" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 0H10V15L5 11.9118L0 15V0Z" fill="#133159"/>
                                    </svg>
                                    Use Quick Reply</a>
                            </div>
                        </div>
                    </div>
                    <div className="col s4">
                        <div className="send-message-btn right p-relative">
                            <a href="#"><i className="material-icons left">near_me</i>Send</a>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
        </div>
    );
}

export default DirectMail;